import { AuthorizationPromiseClient, AuthorizationClient } from '@/services/proto/authorization_grpc_web_pb';
import {
    RegistrationRequest,
    LoginRequest,
    ConfirmCodeRequest,
    InitResetPasswordRequest,
    ResendCodeRequest,
    VerifyLoginRequest,
    LogoutRequest,
    ResetPasswordRequest,
} from '@/services/proto/authorization_pb';

const authorizationInstancePromise = new AuthorizationPromiseClient(process.env.API_URL, null, null);
const authorizationInstance = new AuthorizationClient(process.env.API_URL, null, null);

const register = function (email, password) {
    const request = new RegistrationRequest();

    request.setEmail(email);
    request.setPassword(password);

    return authorizationInstancePromise.register(request, {});
}

const login = function (email, password) {
    const request = new LoginRequest();

    request.setEmail(email);
    request.setPassword(password);

    return Promise.allSettled([
        new Promise((resolve, reject) => {
            authorizationInstance.login(request, {}, (err, res) => {})
                .on('metadata', (metadata) => {
                    if (metadata) resolve(metadata);
                    reject(metadata);
                })
        }),
        authorizationInstancePromise.login(request, {})
    ]);
}

const confirmCode = function (userId, code) {
    const request = new ConfirmCodeRequest();

    request.setUserid(userId);
    request.setCode(code);

    return authorizationInstancePromise.confirmCode(request, {})
}

const sendConfirmCode = function (email) {
    const request = new InitResetPasswordRequest();

    request.setEmail(email);

    return authorizationInstancePromise.initResetPassword(request, {});
}

const resendConfirmCode = function (userId) {
    const request = new ResendCodeRequest();

    request.setUserid(userId);

    return authorizationInstancePromise.resendCode(request, {});
}

const changePassword = function (email, password, code) {
    const request = new ResetPasswordRequest();

    request.setEmail(email);
    request.setNewpassword(password);
    request.setCode(code);

    return authorizationInstancePromise.resetPassword(request, {});
}

const verifyLogin = function (session) {
    const request = new VerifyLoginRequest();

    request.setSession(session);
    return authorizationInstancePromise.verfyLogin(request, {});
}

const logout = function (session) {
    const request = new LogoutRequest();

    request.setSessionid(session);
    return authorizationInstancePromise.logout(request, {});
}

export {
    register,
    login,
    confirmCode,
    sendConfirmCode,
    changePassword,
    resendConfirmCode,
    verifyLogin,
    logout
};

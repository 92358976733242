/**
 * @fileoverview gRPC-Web generated client stub for payments
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')
const proto = {};
proto.payments = require('./payments_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.payments.PaymentsClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.payments.PaymentsPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.payments.GetPaymentStatusResponse>}
 */
const methodDescriptor_Payments_GetPaymentStatus = new grpc.web.MethodDescriptor(
  '/payments.Payments/GetPaymentStatus',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.payments.GetPaymentStatusResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.payments.GetPaymentStatusResponse.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.payments.GetPaymentStatusResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.payments.GetPaymentStatusResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.payments.PaymentsClient.prototype.getPaymentStatus =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/payments.Payments/GetPaymentStatus',
      request,
      metadata || {},
      methodDescriptor_Payments_GetPaymentStatus,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.payments.GetPaymentStatusResponse>}
 *     Promise that resolves to the response
 */
proto.payments.PaymentsPromiseClient.prototype.getPaymentStatus =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/payments.Payments/GetPaymentStatus',
      request,
      metadata || {},
      methodDescriptor_Payments_GetPaymentStatus);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.payments.GetAccountConnectionLinkRequest,
 *   !proto.payments.GetAccountConnectionLinkResponse>}
 */
const methodDescriptor_Payments_GetAccountConnectionLink = new grpc.web.MethodDescriptor(
  '/payments.Payments/GetAccountConnectionLink',
  grpc.web.MethodType.UNARY,
  proto.payments.GetAccountConnectionLinkRequest,
  proto.payments.GetAccountConnectionLinkResponse,
  /**
   * @param {!proto.payments.GetAccountConnectionLinkRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.payments.GetAccountConnectionLinkResponse.deserializeBinary
);


/**
 * @param {!proto.payments.GetAccountConnectionLinkRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.payments.GetAccountConnectionLinkResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.payments.GetAccountConnectionLinkResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.payments.PaymentsClient.prototype.getAccountConnectionLink =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/payments.Payments/GetAccountConnectionLink',
      request,
      metadata || {},
      methodDescriptor_Payments_GetAccountConnectionLink,
      callback);
};


/**
 * @param {!proto.payments.GetAccountConnectionLinkRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.payments.GetAccountConnectionLinkResponse>}
 *     Promise that resolves to the response
 */
proto.payments.PaymentsPromiseClient.prototype.getAccountConnectionLink =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/payments.Payments/GetAccountConnectionLink',
      request,
      metadata || {},
      methodDescriptor_Payments_GetAccountConnectionLink);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.payments.ConnectedAccountInfo>}
 */
const methodDescriptor_Payments_PayoutAvailableAmount = new grpc.web.MethodDescriptor(
  '/payments.Payments/PayoutAvailableAmount',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.payments.ConnectedAccountInfo,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.payments.ConnectedAccountInfo.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.payments.ConnectedAccountInfo)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.payments.ConnectedAccountInfo>|undefined}
 *     The XHR Node Readable Stream
 */
proto.payments.PaymentsClient.prototype.payoutAvailableAmount =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/payments.Payments/PayoutAvailableAmount',
      request,
      metadata || {},
      methodDescriptor_Payments_PayoutAvailableAmount,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.payments.ConnectedAccountInfo>}
 *     Promise that resolves to the response
 */
proto.payments.PaymentsPromiseClient.prototype.payoutAvailableAmount =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/payments.Payments/PayoutAvailableAmount',
      request,
      metadata || {},
      methodDescriptor_Payments_PayoutAvailableAmount);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.payments.GetDashboardLinkResponse>}
 */
const methodDescriptor_Payments_GetDashboardLink = new grpc.web.MethodDescriptor(
  '/payments.Payments/GetDashboardLink',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.payments.GetDashboardLinkResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.payments.GetDashboardLinkResponse.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.payments.GetDashboardLinkResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.payments.GetDashboardLinkResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.payments.PaymentsClient.prototype.getDashboardLink =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/payments.Payments/GetDashboardLink',
      request,
      metadata || {},
      methodDescriptor_Payments_GetDashboardLink,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.payments.GetDashboardLinkResponse>}
 *     Promise that resolves to the response
 */
proto.payments.PaymentsPromiseClient.prototype.getDashboardLink =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/payments.Payments/GetDashboardLink',
      request,
      metadata || {},
      methodDescriptor_Payments_GetDashboardLink);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.payments.PublicPaymentsClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.payments.PublicPaymentsPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.payments.PaymentCheckoutInfoRequest,
 *   !proto.payments.PaymentCheckoutInfoResponse>}
 */
const methodDescriptor_PublicPayments_PaymentCheckoutInfo = new grpc.web.MethodDescriptor(
  '/payments.PublicPayments/PaymentCheckoutInfo',
  grpc.web.MethodType.UNARY,
  proto.payments.PaymentCheckoutInfoRequest,
  proto.payments.PaymentCheckoutInfoResponse,
  /**
   * @param {!proto.payments.PaymentCheckoutInfoRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.payments.PaymentCheckoutInfoResponse.deserializeBinary
);


/**
 * @param {!proto.payments.PaymentCheckoutInfoRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.payments.PaymentCheckoutInfoResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.payments.PaymentCheckoutInfoResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.payments.PublicPaymentsClient.prototype.paymentCheckoutInfo =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/payments.PublicPayments/PaymentCheckoutInfo',
      request,
      metadata || {},
      methodDescriptor_PublicPayments_PaymentCheckoutInfo,
      callback);
};


/**
 * @param {!proto.payments.PaymentCheckoutInfoRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.payments.PaymentCheckoutInfoResponse>}
 *     Promise that resolves to the response
 */
proto.payments.PublicPaymentsPromiseClient.prototype.paymentCheckoutInfo =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/payments.PublicPayments/PaymentCheckoutInfo',
      request,
      metadata || {},
      methodDescriptor_PublicPayments_PaymentCheckoutInfo);
};


module.exports = proto.payments;


<template>
    <label class="input-base">
        <span class="input-base__name" v-if="!options.noName">{{ options.name }}</span>
        <span class="input-base__container">
            <span class="input-base__prefix" v-if="options.prefix"> {{ options.prefix }} </span>
            <input :type="options.type"
                   :placeholder="options.placeholder"
                   class="input-base__input"
                   :class="{
                       'error' : options.error,
                       'prefix' : options.prefix
                   }"
                   :disabled="disabled"
                   v-model="modelValue"
            >
            <button class="input-base__reset"
                    v-show="options.reset && modelValue.length > 0"
                    type="button"
                    @click="reset"
            >
                <svg-icon :name="options.formType === 'authorization' ? 'delete-auth' : 'delete'"></svg-icon>
            </button>
            <span v-if="options.error" class="input-base__error">{{ options.error }}</span>
        </span>
        <router-link v-if="options.forgotPassword" to="/send-code" class="input-base__forgot">Forgot password?</router-link>
        <button v-if="options.resend" type="button" :disabled="resendDisabled" class="input-base__forgot" @click="resend">Resend code</button>
    </label>
</template>

<script>
    import SvgIcon from "@/components/common/SvgIcon/SvgIcon";

    export default {
        name: "InputBase",
        components: {
            SvgIcon
        },
        data() {
            return {
                resendDisabled: false
            }
        },
        props: {
            options: {
                type: Object,
                required: true
            },
            disabled: {
                type: Boolean,
                default: false,
            }
        },
        computed: {
            modelValue: {
                get() {
                    return this.options.value
                },
                set(value) {
                    this.$emit('update:modelValue', {
                        name: this.options.name,
                        value: value
                    });
                }
            }
        },
        methods: {
            reset() {
                this.modelValue = '';
            },
            resend() {
                this.resendDisabled = true;
                setTimeout(() => {
                    this.resendDisabled = false;
                }, 30000);
                this.$emit('resend');
            }
        },
    }
</script>

import { getPaymentStatus, getAccountConnectionLink, getDashboardLink, payOutNow } from '../services/apiPayment';

export default ({
    namespaced: true,
    state: {
        paymentStatus: 0,
        connectionLink: '',
        dashboardLink: '',
        accountInfo: null,
        isLoading: false,
        payoutIsProcessing: false,
    },
    getters: {
        mainBalance(state) {
            return {
                pending: state.accountInfo?.defaultbalancepending.value / 100 || 0,
                availible: state.accountInfo?.defaultbalanceavailable.value / 100 || 0,
                currency: state.accountInfo?.defaultbalanceavailable.currency || 0,
            }
        },
        otherBalances(state) {
            if (state.accountInfo && state.accountInfo.otherbalancesavailableList.length && state.accountInfo.otherbalancespendingList.length) {
                return state.accountInfo.otherbalancesavailableList.map(availibleBalance => {
                    const pending = state.accountInfo.otherbalancespendingList.find(pendingBalance => {
                        return pendingBalance.currency === availibleBalance.currency;
                    });

                    return {
                        pending: pending ? pending.value / 100 : 0,
                        availible: availibleBalance.value / 100,
                        currency: availibleBalance.currency,
                    }
                });
            }

            return [];
        }
    },
    mutations: {
        DEFAULT_MUTATION(state, payload) {
            state[payload.property] = payload.value;
        },
        SET_ACCOUNT_INFO(state, response) {
            if (state.paymentStatus !== 0 || state.paymentStatus !== 1) {
                if (state.paymentStatus === 2) {
                    state.accountInfo = response.getNotfinished().toObject();
                }
                if (state.paymentStatus === 3) {
                    state.accountInfo = response.getConnected().toObject();
                }
            }
        },
        clearState(state) {
            state.paymentStatus = 0;
            state.connectionLink = '';
            state.dashboardLink = '';
            state.accountInfo = null;
        }
    },
    actions: {
        async getPaymentStatuses({ commit, state }) {
            commit('DEFAULT_MUTATION', {
                property: 'isLoading',
                value: true,
            });

            const response = await getPaymentStatus();
            commit('DEFAULT_MUTATION', {
                property: 'paymentStatus',
                value: response.getStatusCase()
            });
            commit('SET_ACCOUNT_INFO', response);

            if (state.paymentStatus !== 3) {
                const connectionLinkResponse = await getAccountConnectionLink();
                commit('DEFAULT_MUTATION', {
                    property: 'connectionLink',
                    value: connectionLinkResponse.getConnectionurl()
                });
            } else {
                const dashboardLinkResponse = await getDashboardLink();
                commit('DEFAULT_MUTATION', {
                    property: 'dashboardLink',
                    value: dashboardLinkResponse.getRedirecturl()
                });
            }

            commit('DEFAULT_MUTATION', {
                property: 'isLoading',
                value: false,
            });
        },
        async payOut({ commit }) {
            commit('DEFAULT_MUTATION', {
                property: 'payoutIsProcessing',
                value: true,
            });

            const payOutResponse = await payOutNow();
            commit('DEFAULT_MUTATION', {
                property: 'accountInfo',
                value: payOutResponse.toObject(),
            });

            this._vm.$gtag.event('pay_out', {
                'event_category': 'payment',
                'event_label': 'user get pay out now',
            });
            commit('DEFAULT_MUTATION', {
                property: 'payoutIsProcessing',
                value: false,
            });
        }
    },
})

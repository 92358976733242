/**
 * @fileoverview gRPC-Web generated client stub for authorization
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')
const proto = {};
proto.authorization = require('./authorization_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.authorization.AuthorizationClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.authorization.AuthorizationPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.authorization.InitializeDummyUserResponse>}
 */
const methodDescriptor_Authorization_InitializeDummyUser = new grpc.web.MethodDescriptor(
  '/authorization.Authorization/InitializeDummyUser',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.authorization.InitializeDummyUserResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.authorization.InitializeDummyUserResponse.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.authorization.InitializeDummyUserResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.authorization.InitializeDummyUserResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.authorization.AuthorizationClient.prototype.initializeDummyUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/authorization.Authorization/InitializeDummyUser',
      request,
      metadata || {},
      methodDescriptor_Authorization_InitializeDummyUser,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.authorization.InitializeDummyUserResponse>}
 *     Promise that resolves to the response
 */
proto.authorization.AuthorizationPromiseClient.prototype.initializeDummyUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/authorization.Authorization/InitializeDummyUser',
      request,
      metadata || {},
      methodDescriptor_Authorization_InitializeDummyUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.authorization.LoginRequest,
 *   !proto.authorization.LoginResponse>}
 */
const methodDescriptor_Authorization_Login = new grpc.web.MethodDescriptor(
  '/authorization.Authorization/Login',
  grpc.web.MethodType.UNARY,
  proto.authorization.LoginRequest,
  proto.authorization.LoginResponse,
  /**
   * @param {!proto.authorization.LoginRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.authorization.LoginResponse.deserializeBinary
);


/**
 * @param {!proto.authorization.LoginRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.authorization.LoginResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.authorization.LoginResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.authorization.AuthorizationClient.prototype.login =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/authorization.Authorization/Login',
      request,
      metadata || {},
      methodDescriptor_Authorization_Login,
      callback);
};


/**
 * @param {!proto.authorization.LoginRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.authorization.LoginResponse>}
 *     Promise that resolves to the response
 */
proto.authorization.AuthorizationPromiseClient.prototype.login =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/authorization.Authorization/Login',
      request,
      metadata || {},
      methodDescriptor_Authorization_Login);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.authorization.AppleOauthLoginRequest,
 *   !proto.authorization.LoginResponse>}
 */
const methodDescriptor_Authorization_AppleOauthLogin = new grpc.web.MethodDescriptor(
  '/authorization.Authorization/AppleOauthLogin',
  grpc.web.MethodType.UNARY,
  proto.authorization.AppleOauthLoginRequest,
  proto.authorization.LoginResponse,
  /**
   * @param {!proto.authorization.AppleOauthLoginRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.authorization.LoginResponse.deserializeBinary
);


/**
 * @param {!proto.authorization.AppleOauthLoginRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.authorization.LoginResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.authorization.LoginResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.authorization.AuthorizationClient.prototype.appleOauthLogin =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/authorization.Authorization/AppleOauthLogin',
      request,
      metadata || {},
      methodDescriptor_Authorization_AppleOauthLogin,
      callback);
};


/**
 * @param {!proto.authorization.AppleOauthLoginRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.authorization.LoginResponse>}
 *     Promise that resolves to the response
 */
proto.authorization.AuthorizationPromiseClient.prototype.appleOauthLogin =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/authorization.Authorization/AppleOauthLogin',
      request,
      metadata || {},
      methodDescriptor_Authorization_AppleOauthLogin);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.authorization.VerifyLoginRequest,
 *   !proto.authorization.VerifyLoginResponse>}
 */
const methodDescriptor_Authorization_VerfyLogin = new grpc.web.MethodDescriptor(
  '/authorization.Authorization/VerfyLogin',
  grpc.web.MethodType.UNARY,
  proto.authorization.VerifyLoginRequest,
  proto.authorization.VerifyLoginResponse,
  /**
   * @param {!proto.authorization.VerifyLoginRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.authorization.VerifyLoginResponse.deserializeBinary
);


/**
 * @param {!proto.authorization.VerifyLoginRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.authorization.VerifyLoginResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.authorization.VerifyLoginResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.authorization.AuthorizationClient.prototype.verfyLogin =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/authorization.Authorization/VerfyLogin',
      request,
      metadata || {},
      methodDescriptor_Authorization_VerfyLogin,
      callback);
};


/**
 * @param {!proto.authorization.VerifyLoginRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.authorization.VerifyLoginResponse>}
 *     Promise that resolves to the response
 */
proto.authorization.AuthorizationPromiseClient.prototype.verfyLogin =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/authorization.Authorization/VerfyLogin',
      request,
      metadata || {},
      methodDescriptor_Authorization_VerfyLogin);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.authorization.LogoutRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Authorization_Logout = new grpc.web.MethodDescriptor(
  '/authorization.Authorization/Logout',
  grpc.web.MethodType.UNARY,
  proto.authorization.LogoutRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.authorization.LogoutRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.authorization.LogoutRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.authorization.AuthorizationClient.prototype.logout =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/authorization.Authorization/Logout',
      request,
      metadata || {},
      methodDescriptor_Authorization_Logout,
      callback);
};


/**
 * @param {!proto.authorization.LogoutRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.authorization.AuthorizationPromiseClient.prototype.logout =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/authorization.Authorization/Logout',
      request,
      metadata || {},
      methodDescriptor_Authorization_Logout);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.authorization.RegistrationRequest,
 *   !proto.authorization.RegistrationResponse>}
 */
const methodDescriptor_Authorization_Register = new grpc.web.MethodDescriptor(
  '/authorization.Authorization/Register',
  grpc.web.MethodType.UNARY,
  proto.authorization.RegistrationRequest,
  proto.authorization.RegistrationResponse,
  /**
   * @param {!proto.authorization.RegistrationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.authorization.RegistrationResponse.deserializeBinary
);


/**
 * @param {!proto.authorization.RegistrationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.authorization.RegistrationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.authorization.RegistrationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.authorization.AuthorizationClient.prototype.register =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/authorization.Authorization/Register',
      request,
      metadata || {},
      methodDescriptor_Authorization_Register,
      callback);
};


/**
 * @param {!proto.authorization.RegistrationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.authorization.RegistrationResponse>}
 *     Promise that resolves to the response
 */
proto.authorization.AuthorizationPromiseClient.prototype.register =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/authorization.Authorization/Register',
      request,
      metadata || {},
      methodDescriptor_Authorization_Register);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.authorization.ResendCodeRequest,
 *   !proto.authorization.ResendCodeResponse>}
 */
const methodDescriptor_Authorization_ResendCode = new grpc.web.MethodDescriptor(
  '/authorization.Authorization/ResendCode',
  grpc.web.MethodType.UNARY,
  proto.authorization.ResendCodeRequest,
  proto.authorization.ResendCodeResponse,
  /**
   * @param {!proto.authorization.ResendCodeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.authorization.ResendCodeResponse.deserializeBinary
);


/**
 * @param {!proto.authorization.ResendCodeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.authorization.ResendCodeResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.authorization.ResendCodeResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.authorization.AuthorizationClient.prototype.resendCode =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/authorization.Authorization/ResendCode',
      request,
      metadata || {},
      methodDescriptor_Authorization_ResendCode,
      callback);
};


/**
 * @param {!proto.authorization.ResendCodeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.authorization.ResendCodeResponse>}
 *     Promise that resolves to the response
 */
proto.authorization.AuthorizationPromiseClient.prototype.resendCode =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/authorization.Authorization/ResendCode',
      request,
      metadata || {},
      methodDescriptor_Authorization_ResendCode);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.authorization.ConfirmCodeRequest,
 *   !proto.authorization.ConfirmCodeResponse>}
 */
const methodDescriptor_Authorization_ConfirmCode = new grpc.web.MethodDescriptor(
  '/authorization.Authorization/ConfirmCode',
  grpc.web.MethodType.UNARY,
  proto.authorization.ConfirmCodeRequest,
  proto.authorization.ConfirmCodeResponse,
  /**
   * @param {!proto.authorization.ConfirmCodeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.authorization.ConfirmCodeResponse.deserializeBinary
);


/**
 * @param {!proto.authorization.ConfirmCodeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.authorization.ConfirmCodeResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.authorization.ConfirmCodeResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.authorization.AuthorizationClient.prototype.confirmCode =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/authorization.Authorization/ConfirmCode',
      request,
      metadata || {},
      methodDescriptor_Authorization_ConfirmCode,
      callback);
};


/**
 * @param {!proto.authorization.ConfirmCodeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.authorization.ConfirmCodeResponse>}
 *     Promise that resolves to the response
 */
proto.authorization.AuthorizationPromiseClient.prototype.confirmCode =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/authorization.Authorization/ConfirmCode',
      request,
      metadata || {},
      methodDescriptor_Authorization_ConfirmCode);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.authorization.InitResetPasswordRequest,
 *   !proto.authorization.InitResetPasswordResponse>}
 */
const methodDescriptor_Authorization_InitResetPassword = new grpc.web.MethodDescriptor(
  '/authorization.Authorization/InitResetPassword',
  grpc.web.MethodType.UNARY,
  proto.authorization.InitResetPasswordRequest,
  proto.authorization.InitResetPasswordResponse,
  /**
   * @param {!proto.authorization.InitResetPasswordRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.authorization.InitResetPasswordResponse.deserializeBinary
);


/**
 * @param {!proto.authorization.InitResetPasswordRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.authorization.InitResetPasswordResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.authorization.InitResetPasswordResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.authorization.AuthorizationClient.prototype.initResetPassword =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/authorization.Authorization/InitResetPassword',
      request,
      metadata || {},
      methodDescriptor_Authorization_InitResetPassword,
      callback);
};


/**
 * @param {!proto.authorization.InitResetPasswordRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.authorization.InitResetPasswordResponse>}
 *     Promise that resolves to the response
 */
proto.authorization.AuthorizationPromiseClient.prototype.initResetPassword =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/authorization.Authorization/InitResetPassword',
      request,
      metadata || {},
      methodDescriptor_Authorization_InitResetPassword);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.authorization.ResetPasswordRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Authorization_ResetPassword = new grpc.web.MethodDescriptor(
  '/authorization.Authorization/ResetPassword',
  grpc.web.MethodType.UNARY,
  proto.authorization.ResetPasswordRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.authorization.ResetPasswordRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.authorization.ResetPasswordRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.authorization.AuthorizationClient.prototype.resetPassword =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/authorization.Authorization/ResetPassword',
      request,
      metadata || {},
      methodDescriptor_Authorization_ResetPassword,
      callback);
};


/**
 * @param {!proto.authorization.ResetPasswordRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.authorization.AuthorizationPromiseClient.prototype.resetPassword =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/authorization.Authorization/ResetPassword',
      request,
      metadata || {},
      methodDescriptor_Authorization_ResetPassword);
};


module.exports = proto.authorization;


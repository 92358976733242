/**
 * @fileoverview gRPC-Web generated client stub for students
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')
const proto = {};
proto.students = require('./students_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.students.StudentsClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.students.StudentsPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.students.AddStudentRequest,
 *   !proto.students.AddStudentResponse>}
 */
const methodDescriptor_Students_AddStudent = new grpc.web.MethodDescriptor(
  '/students.Students/AddStudent',
  grpc.web.MethodType.UNARY,
  proto.students.AddStudentRequest,
  proto.students.AddStudentResponse,
  /**
   * @param {!proto.students.AddStudentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.students.AddStudentResponse.deserializeBinary
);


/**
 * @param {!proto.students.AddStudentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.students.AddStudentResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.students.AddStudentResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.students.StudentsClient.prototype.addStudent =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/students.Students/AddStudent',
      request,
      metadata || {},
      methodDescriptor_Students_AddStudent,
      callback);
};


/**
 * @param {!proto.students.AddStudentRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.students.AddStudentResponse>}
 *     Promise that resolves to the response
 */
proto.students.StudentsPromiseClient.prototype.addStudent =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/students.Students/AddStudent',
      request,
      metadata || {},
      methodDescriptor_Students_AddStudent);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.students.BulkAddStudentRequest,
 *   !proto.students.BulkAddStudentResponse>}
 */
const methodDescriptor_Students_BulkAddStudent = new grpc.web.MethodDescriptor(
  '/students.Students/BulkAddStudent',
  grpc.web.MethodType.UNARY,
  proto.students.BulkAddStudentRequest,
  proto.students.BulkAddStudentResponse,
  /**
   * @param {!proto.students.BulkAddStudentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.students.BulkAddStudentResponse.deserializeBinary
);


/**
 * @param {!proto.students.BulkAddStudentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.students.BulkAddStudentResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.students.BulkAddStudentResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.students.StudentsClient.prototype.bulkAddStudent =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/students.Students/BulkAddStudent',
      request,
      metadata || {},
      methodDescriptor_Students_BulkAddStudent,
      callback);
};


/**
 * @param {!proto.students.BulkAddStudentRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.students.BulkAddStudentResponse>}
 *     Promise that resolves to the response
 */
proto.students.StudentsPromiseClient.prototype.bulkAddStudent =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/students.Students/BulkAddStudent',
      request,
      metadata || {},
      methodDescriptor_Students_BulkAddStudent);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.students.EditStudentRequest,
 *   !proto.students.Profile>}
 */
const methodDescriptor_Students_EditStudent = new grpc.web.MethodDescriptor(
  '/students.Students/EditStudent',
  grpc.web.MethodType.UNARY,
  proto.students.EditStudentRequest,
  proto.students.Profile,
  /**
   * @param {!proto.students.EditStudentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.students.Profile.deserializeBinary
);


/**
 * @param {!proto.students.EditStudentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.students.Profile)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.students.Profile>|undefined}
 *     The XHR Node Readable Stream
 */
proto.students.StudentsClient.prototype.editStudent =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/students.Students/EditStudent',
      request,
      metadata || {},
      methodDescriptor_Students_EditStudent,
      callback);
};


/**
 * @param {!proto.students.EditStudentRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.students.Profile>}
 *     Promise that resolves to the response
 */
proto.students.StudentsPromiseClient.prototype.editStudent =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/students.Students/EditStudent',
      request,
      metadata || {},
      methodDescriptor_Students_EditStudent);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.students.BulkEditStudentRequest,
 *   !proto.students.BulkEditStudentResponse>}
 */
const methodDescriptor_Students_BulkEditStudent = new grpc.web.MethodDescriptor(
  '/students.Students/BulkEditStudent',
  grpc.web.MethodType.UNARY,
  proto.students.BulkEditStudentRequest,
  proto.students.BulkEditStudentResponse,
  /**
   * @param {!proto.students.BulkEditStudentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.students.BulkEditStudentResponse.deserializeBinary
);


/**
 * @param {!proto.students.BulkEditStudentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.students.BulkEditStudentResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.students.BulkEditStudentResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.students.StudentsClient.prototype.bulkEditStudent =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/students.Students/BulkEditStudent',
      request,
      metadata || {},
      methodDescriptor_Students_BulkEditStudent,
      callback);
};


/**
 * @param {!proto.students.BulkEditStudentRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.students.BulkEditStudentResponse>}
 *     Promise that resolves to the response
 */
proto.students.StudentsPromiseClient.prototype.bulkEditStudent =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/students.Students/BulkEditStudent',
      request,
      metadata || {},
      methodDescriptor_Students_BulkEditStudent);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.students.RemoveStudentRequest,
 *   !proto.students.ProfileShort>}
 */
const methodDescriptor_Students_RemoveStudent = new grpc.web.MethodDescriptor(
  '/students.Students/RemoveStudent',
  grpc.web.MethodType.UNARY,
  proto.students.RemoveStudentRequest,
  proto.students.ProfileShort,
  /**
   * @param {!proto.students.RemoveStudentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.students.ProfileShort.deserializeBinary
);


/**
 * @param {!proto.students.RemoveStudentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.students.ProfileShort)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.students.ProfileShort>|undefined}
 *     The XHR Node Readable Stream
 */
proto.students.StudentsClient.prototype.removeStudent =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/students.Students/RemoveStudent',
      request,
      metadata || {},
      methodDescriptor_Students_RemoveStudent,
      callback);
};


/**
 * @param {!proto.students.RemoveStudentRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.students.ProfileShort>}
 *     Promise that resolves to the response
 */
proto.students.StudentsPromiseClient.prototype.removeStudent =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/students.Students/RemoveStudent',
      request,
      metadata || {},
      methodDescriptor_Students_RemoveStudent);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.students.BulkRemoveStudentRequest,
 *   !proto.students.BulkRemoveStudentResponse>}
 */
const methodDescriptor_Students_BulkRemoveStudent = new grpc.web.MethodDescriptor(
  '/students.Students/BulkRemoveStudent',
  grpc.web.MethodType.UNARY,
  proto.students.BulkRemoveStudentRequest,
  proto.students.BulkRemoveStudentResponse,
  /**
   * @param {!proto.students.BulkRemoveStudentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.students.BulkRemoveStudentResponse.deserializeBinary
);


/**
 * @param {!proto.students.BulkRemoveStudentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.students.BulkRemoveStudentResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.students.BulkRemoveStudentResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.students.StudentsClient.prototype.bulkRemoveStudent =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/students.Students/BulkRemoveStudent',
      request,
      metadata || {},
      methodDescriptor_Students_BulkRemoveStudent,
      callback);
};


/**
 * @param {!proto.students.BulkRemoveStudentRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.students.BulkRemoveStudentResponse>}
 *     Promise that resolves to the response
 */
proto.students.StudentsPromiseClient.prototype.bulkRemoveStudent =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/students.Students/BulkRemoveStudent',
      request,
      metadata || {},
      methodDescriptor_Students_BulkRemoveStudent);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.students.GetStudentListResponse>}
 */
const methodDescriptor_Students_GetStudentList = new grpc.web.MethodDescriptor(
  '/students.Students/GetStudentList',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.students.GetStudentListResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.students.GetStudentListResponse.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.students.GetStudentListResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.students.GetStudentListResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.students.StudentsClient.prototype.getStudentList =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/students.Students/GetStudentList',
      request,
      metadata || {},
      methodDescriptor_Students_GetStudentList,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.students.GetStudentListResponse>}
 *     Promise that resolves to the response
 */
proto.students.StudentsPromiseClient.prototype.getStudentList =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/students.Students/GetStudentList',
      request,
      metadata || {},
      methodDescriptor_Students_GetStudentList);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.students.UpdateStudentListRequest,
 *   !proto.students.UpdateStudentListResponse>}
 */
const methodDescriptor_Students_UpdateStudentList = new grpc.web.MethodDescriptor(
  '/students.Students/UpdateStudentList',
  grpc.web.MethodType.UNARY,
  proto.students.UpdateStudentListRequest,
  proto.students.UpdateStudentListResponse,
  /**
   * @param {!proto.students.UpdateStudentListRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.students.UpdateStudentListResponse.deserializeBinary
);


/**
 * @param {!proto.students.UpdateStudentListRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.students.UpdateStudentListResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.students.UpdateStudentListResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.students.StudentsClient.prototype.updateStudentList =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/students.Students/UpdateStudentList',
      request,
      metadata || {},
      methodDescriptor_Students_UpdateStudentList,
      callback);
};


/**
 * @param {!proto.students.UpdateStudentListRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.students.UpdateStudentListResponse>}
 *     Promise that resolves to the response
 */
proto.students.StudentsPromiseClient.prototype.updateStudentList =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/students.Students/UpdateStudentList',
      request,
      metadata || {},
      methodDescriptor_Students_UpdateStudentList);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.students.GetProfileListRequest,
 *   !proto.students.GetProfileListByIdsResponse>}
 */
const methodDescriptor_Students_GetProfileListByIds = new grpc.web.MethodDescriptor(
  '/students.Students/GetProfileListByIds',
  grpc.web.MethodType.UNARY,
  proto.students.GetProfileListRequest,
  proto.students.GetProfileListByIdsResponse,
  /**
   * @param {!proto.students.GetProfileListRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.students.GetProfileListByIdsResponse.deserializeBinary
);


/**
 * @param {!proto.students.GetProfileListRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.students.GetProfileListByIdsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.students.GetProfileListByIdsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.students.StudentsClient.prototype.getProfileListByIds =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/students.Students/GetProfileListByIds',
      request,
      metadata || {},
      methodDescriptor_Students_GetProfileListByIds,
      callback);
};


/**
 * @param {!proto.students.GetProfileListRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.students.GetProfileListByIdsResponse>}
 *     Promise that resolves to the response
 */
proto.students.StudentsPromiseClient.prototype.getProfileListByIds =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/students.Students/GetProfileListByIds',
      request,
      metadata || {},
      methodDescriptor_Students_GetProfileListByIds);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.students.GetFullProfileRequest,
 *   !proto.students.Profile>}
 */
const methodDescriptor_Students_GetFullProfile = new grpc.web.MethodDescriptor(
  '/students.Students/GetFullProfile',
  grpc.web.MethodType.UNARY,
  proto.students.GetFullProfileRequest,
  proto.students.Profile,
  /**
   * @param {!proto.students.GetFullProfileRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.students.Profile.deserializeBinary
);


/**
 * @param {!proto.students.GetFullProfileRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.students.Profile)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.students.Profile>|undefined}
 *     The XHR Node Readable Stream
 */
proto.students.StudentsClient.prototype.getFullProfile =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/students.Students/GetFullProfile',
      request,
      metadata || {},
      methodDescriptor_Students_GetFullProfile,
      callback);
};


/**
 * @param {!proto.students.GetFullProfileRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.students.Profile>}
 *     Promise that resolves to the response
 */
proto.students.StudentsPromiseClient.prototype.getFullProfile =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/students.Students/GetFullProfile',
      request,
      metadata || {},
      methodDescriptor_Students_GetFullProfile);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.students.ProfileInfoByCourseRequest,
 *   !proto.students.ProfileShort>}
 */
const methodDescriptor_Students_ProfileInfoByCourseID = new grpc.web.MethodDescriptor(
  '/students.Students/ProfileInfoByCourseID',
  grpc.web.MethodType.UNARY,
  proto.students.ProfileInfoByCourseRequest,
  proto.students.ProfileShort,
  /**
   * @param {!proto.students.ProfileInfoByCourseRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.students.ProfileShort.deserializeBinary
);


/**
 * @param {!proto.students.ProfileInfoByCourseRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.students.ProfileShort)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.students.ProfileShort>|undefined}
 *     The XHR Node Readable Stream
 */
proto.students.StudentsClient.prototype.profileInfoByCourseID =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/students.Students/ProfileInfoByCourseID',
      request,
      metadata || {},
      methodDescriptor_Students_ProfileInfoByCourseID,
      callback);
};


/**
 * @param {!proto.students.ProfileInfoByCourseRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.students.ProfileShort>}
 *     Promise that resolves to the response
 */
proto.students.StudentsPromiseClient.prototype.profileInfoByCourseID =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/students.Students/ProfileInfoByCourseID',
      request,
      metadata || {},
      methodDescriptor_Students_ProfileInfoByCourseID);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.students.ScheduleClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.students.SchedulePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.students.AddLessonRequest,
 *   !proto.students.LessonWithPayment>}
 */
const methodDescriptor_Schedule_AddLesson = new grpc.web.MethodDescriptor(
  '/students.Schedule/AddLesson',
  grpc.web.MethodType.UNARY,
  proto.students.AddLessonRequest,
  proto.students.LessonWithPayment,
  /**
   * @param {!proto.students.AddLessonRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.students.LessonWithPayment.deserializeBinary
);


/**
 * @param {!proto.students.AddLessonRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.students.LessonWithPayment)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.students.LessonWithPayment>|undefined}
 *     The XHR Node Readable Stream
 */
proto.students.ScheduleClient.prototype.addLesson =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/students.Schedule/AddLesson',
      request,
      metadata || {},
      methodDescriptor_Schedule_AddLesson,
      callback);
};


/**
 * @param {!proto.students.AddLessonRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.students.LessonWithPayment>}
 *     Promise that resolves to the response
 */
proto.students.SchedulePromiseClient.prototype.addLesson =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/students.Schedule/AddLesson',
      request,
      metadata || {},
      methodDescriptor_Schedule_AddLesson);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.students.BulkAddLessonRequest,
 *   !proto.students.BulkAddLessonResponse>}
 */
const methodDescriptor_Schedule_BulkAddLesson = new grpc.web.MethodDescriptor(
  '/students.Schedule/BulkAddLesson',
  grpc.web.MethodType.UNARY,
  proto.students.BulkAddLessonRequest,
  proto.students.BulkAddLessonResponse,
  /**
   * @param {!proto.students.BulkAddLessonRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.students.BulkAddLessonResponse.deserializeBinary
);


/**
 * @param {!proto.students.BulkAddLessonRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.students.BulkAddLessonResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.students.BulkAddLessonResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.students.ScheduleClient.prototype.bulkAddLesson =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/students.Schedule/BulkAddLesson',
      request,
      metadata || {},
      methodDescriptor_Schedule_BulkAddLesson,
      callback);
};


/**
 * @param {!proto.students.BulkAddLessonRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.students.BulkAddLessonResponse>}
 *     Promise that resolves to the response
 */
proto.students.SchedulePromiseClient.prototype.bulkAddLesson =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/students.Schedule/BulkAddLesson',
      request,
      metadata || {},
      methodDescriptor_Schedule_BulkAddLesson);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.students.EditLessonRequest,
 *   !proto.students.Lesson>}
 */
const methodDescriptor_Schedule_EditLesson = new grpc.web.MethodDescriptor(
  '/students.Schedule/EditLesson',
  grpc.web.MethodType.UNARY,
  proto.students.EditLessonRequest,
  proto.students.Lesson,
  /**
   * @param {!proto.students.EditLessonRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.students.Lesson.deserializeBinary
);


/**
 * @param {!proto.students.EditLessonRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.students.Lesson)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.students.Lesson>|undefined}
 *     The XHR Node Readable Stream
 */
proto.students.ScheduleClient.prototype.editLesson =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/students.Schedule/EditLesson',
      request,
      metadata || {},
      methodDescriptor_Schedule_EditLesson,
      callback);
};


/**
 * @param {!proto.students.EditLessonRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.students.Lesson>}
 *     Promise that resolves to the response
 */
proto.students.SchedulePromiseClient.prototype.editLesson =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/students.Schedule/EditLesson',
      request,
      metadata || {},
      methodDescriptor_Schedule_EditLesson);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.students.BulkEditLessonRequest,
 *   !proto.students.LessonList>}
 */
const methodDescriptor_Schedule_BulkEditLesson = new grpc.web.MethodDescriptor(
  '/students.Schedule/BulkEditLesson',
  grpc.web.MethodType.UNARY,
  proto.students.BulkEditLessonRequest,
  proto.students.LessonList,
  /**
   * @param {!proto.students.BulkEditLessonRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.students.LessonList.deserializeBinary
);


/**
 * @param {!proto.students.BulkEditLessonRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.students.LessonList)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.students.LessonList>|undefined}
 *     The XHR Node Readable Stream
 */
proto.students.ScheduleClient.prototype.bulkEditLesson =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/students.Schedule/BulkEditLesson',
      request,
      metadata || {},
      methodDescriptor_Schedule_BulkEditLesson,
      callback);
};


/**
 * @param {!proto.students.BulkEditLessonRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.students.LessonList>}
 *     Promise that resolves to the response
 */
proto.students.SchedulePromiseClient.prototype.bulkEditLesson =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/students.Schedule/BulkEditLesson',
      request,
      metadata || {},
      methodDescriptor_Schedule_BulkEditLesson);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.students.RemoveLessonRequest,
 *   !proto.students.Lesson>}
 */
const methodDescriptor_Schedule_RemoveLesson = new grpc.web.MethodDescriptor(
  '/students.Schedule/RemoveLesson',
  grpc.web.MethodType.UNARY,
  proto.students.RemoveLessonRequest,
  proto.students.Lesson,
  /**
   * @param {!proto.students.RemoveLessonRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.students.Lesson.deserializeBinary
);


/**
 * @param {!proto.students.RemoveLessonRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.students.Lesson)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.students.Lesson>|undefined}
 *     The XHR Node Readable Stream
 */
proto.students.ScheduleClient.prototype.removeLesson =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/students.Schedule/RemoveLesson',
      request,
      metadata || {},
      methodDescriptor_Schedule_RemoveLesson,
      callback);
};


/**
 * @param {!proto.students.RemoveLessonRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.students.Lesson>}
 *     Promise that resolves to the response
 */
proto.students.SchedulePromiseClient.prototype.removeLesson =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/students.Schedule/RemoveLesson',
      request,
      metadata || {},
      methodDescriptor_Schedule_RemoveLesson);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.students.BulkRemoveLessonRequest,
 *   !proto.students.LessonList>}
 */
const methodDescriptor_Schedule_BulkRemoveLesson = new grpc.web.MethodDescriptor(
  '/students.Schedule/BulkRemoveLesson',
  grpc.web.MethodType.UNARY,
  proto.students.BulkRemoveLessonRequest,
  proto.students.LessonList,
  /**
   * @param {!proto.students.BulkRemoveLessonRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.students.LessonList.deserializeBinary
);


/**
 * @param {!proto.students.BulkRemoveLessonRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.students.LessonList)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.students.LessonList>|undefined}
 *     The XHR Node Readable Stream
 */
proto.students.ScheduleClient.prototype.bulkRemoveLesson =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/students.Schedule/BulkRemoveLesson',
      request,
      metadata || {},
      methodDescriptor_Schedule_BulkRemoveLesson,
      callback);
};


/**
 * @param {!proto.students.BulkRemoveLessonRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.students.LessonList>}
 *     Promise that resolves to the response
 */
proto.students.SchedulePromiseClient.prototype.bulkRemoveLesson =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/students.Schedule/BulkRemoveLesson',
      request,
      metadata || {},
      methodDescriptor_Schedule_BulkRemoveLesson);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.students.GetLessonListRequest,
 *   !proto.students.GetLessonListResponse>}
 */
const methodDescriptor_Schedule_GetLessonList = new grpc.web.MethodDescriptor(
  '/students.Schedule/GetLessonList',
  grpc.web.MethodType.UNARY,
  proto.students.GetLessonListRequest,
  proto.students.GetLessonListResponse,
  /**
   * @param {!proto.students.GetLessonListRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.students.GetLessonListResponse.deserializeBinary
);


/**
 * @param {!proto.students.GetLessonListRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.students.GetLessonListResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.students.GetLessonListResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.students.ScheduleClient.prototype.getLessonList =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/students.Schedule/GetLessonList',
      request,
      metadata || {},
      methodDescriptor_Schedule_GetLessonList,
      callback);
};


/**
 * @param {!proto.students.GetLessonListRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.students.GetLessonListResponse>}
 *     Promise that resolves to the response
 */
proto.students.SchedulePromiseClient.prototype.getLessonList =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/students.Schedule/GetLessonList',
      request,
      metadata || {},
      methodDescriptor_Schedule_GetLessonList);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.students.UpdateLessonListRequest,
 *   !proto.students.UpdateLessonListResponse>}
 */
const methodDescriptor_Schedule_UpdateLessonList = new grpc.web.MethodDescriptor(
  '/students.Schedule/UpdateLessonList',
  grpc.web.MethodType.UNARY,
  proto.students.UpdateLessonListRequest,
  proto.students.UpdateLessonListResponse,
  /**
   * @param {!proto.students.UpdateLessonListRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.students.UpdateLessonListResponse.deserializeBinary
);


/**
 * @param {!proto.students.UpdateLessonListRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.students.UpdateLessonListResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.students.UpdateLessonListResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.students.ScheduleClient.prototype.updateLessonList =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/students.Schedule/UpdateLessonList',
      request,
      metadata || {},
      methodDescriptor_Schedule_UpdateLessonList,
      callback);
};


/**
 * @param {!proto.students.UpdateLessonListRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.students.UpdateLessonListResponse>}
 *     Promise that resolves to the response
 */
proto.students.SchedulePromiseClient.prototype.updateLessonList =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/students.Schedule/UpdateLessonList',
      request,
      metadata || {},
      methodDescriptor_Schedule_UpdateLessonList);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.students.GetScheduleRequest,
 *   !proto.students.GetScheduleResponse>}
 */
const methodDescriptor_Schedule_GetSchedule = new grpc.web.MethodDescriptor(
  '/students.Schedule/GetSchedule',
  grpc.web.MethodType.UNARY,
  proto.students.GetScheduleRequest,
  proto.students.GetScheduleResponse,
  /**
   * @param {!proto.students.GetScheduleRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.students.GetScheduleResponse.deserializeBinary
);


/**
 * @param {!proto.students.GetScheduleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.students.GetScheduleResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.students.GetScheduleResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.students.ScheduleClient.prototype.getSchedule =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/students.Schedule/GetSchedule',
      request,
      metadata || {},
      methodDescriptor_Schedule_GetSchedule,
      callback);
};


/**
 * @param {!proto.students.GetScheduleRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.students.GetScheduleResponse>}
 *     Promise that resolves to the response
 */
proto.students.SchedulePromiseClient.prototype.getSchedule =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/students.Schedule/GetSchedule',
      request,
      metadata || {},
      methodDescriptor_Schedule_GetSchedule);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.students.UpdateScheduleRequest,
 *   !proto.students.UpdateScheduleResponse>}
 */
const methodDescriptor_Schedule_UpdateSchedule = new grpc.web.MethodDescriptor(
  '/students.Schedule/UpdateSchedule',
  grpc.web.MethodType.UNARY,
  proto.students.UpdateScheduleRequest,
  proto.students.UpdateScheduleResponse,
  /**
   * @param {!proto.students.UpdateScheduleRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.students.UpdateScheduleResponse.deserializeBinary
);


/**
 * @param {!proto.students.UpdateScheduleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.students.UpdateScheduleResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.students.UpdateScheduleResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.students.ScheduleClient.prototype.updateSchedule =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/students.Schedule/UpdateSchedule',
      request,
      metadata || {},
      methodDescriptor_Schedule_UpdateSchedule,
      callback);
};


/**
 * @param {!proto.students.UpdateScheduleRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.students.UpdateScheduleResponse>}
 *     Promise that resolves to the response
 */
proto.students.SchedulePromiseClient.prototype.updateSchedule =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/students.Schedule/UpdateSchedule',
      request,
      metadata || {},
      methodDescriptor_Schedule_UpdateSchedule);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.students.GetLessonListByIdsRequest,
 *   !proto.students.LessonListResponse>}
 */
const methodDescriptor_Schedule_GetLessonListByIds = new grpc.web.MethodDescriptor(
  '/students.Schedule/GetLessonListByIds',
  grpc.web.MethodType.UNARY,
  proto.students.GetLessonListByIdsRequest,
  proto.students.LessonListResponse,
  /**
   * @param {!proto.students.GetLessonListByIdsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.students.LessonListResponse.deserializeBinary
);


/**
 * @param {!proto.students.GetLessonListByIdsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.students.LessonListResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.students.LessonListResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.students.ScheduleClient.prototype.getLessonListByIds =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/students.Schedule/GetLessonListByIds',
      request,
      metadata || {},
      methodDescriptor_Schedule_GetLessonListByIds,
      callback);
};


/**
 * @param {!proto.students.GetLessonListByIdsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.students.LessonListResponse>}
 *     Promise that resolves to the response
 */
proto.students.SchedulePromiseClient.prototype.getLessonListByIds =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/students.Schedule/GetLessonListByIds',
      request,
      metadata || {},
      methodDescriptor_Schedule_GetLessonListByIds);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.students.PaymentsClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.students.PaymentsPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.students.InitiateLessonPaymentRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Payments_InitiateLessonPayment = new grpc.web.MethodDescriptor(
  '/students.Payments/InitiateLessonPayment',
  grpc.web.MethodType.UNARY,
  proto.students.InitiateLessonPaymentRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.students.InitiateLessonPaymentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.students.InitiateLessonPaymentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.students.PaymentsClient.prototype.initiateLessonPayment =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/students.Payments/InitiateLessonPayment',
      request,
      metadata || {},
      methodDescriptor_Payments_InitiateLessonPayment,
      callback);
};


/**
 * @param {!proto.students.InitiateLessonPaymentRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.students.PaymentsPromiseClient.prototype.initiateLessonPayment =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/students.Payments/InitiateLessonPayment',
      request,
      metadata || {},
      methodDescriptor_Payments_InitiateLessonPayment);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.students.EditPaymentRequest,
 *   !proto.students.Payment>}
 */
const methodDescriptor_Payments_EditPayment = new grpc.web.MethodDescriptor(
  '/students.Payments/EditPayment',
  grpc.web.MethodType.UNARY,
  proto.students.EditPaymentRequest,
  proto.students.Payment,
  /**
   * @param {!proto.students.EditPaymentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.students.Payment.deserializeBinary
);


/**
 * @param {!proto.students.EditPaymentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.students.Payment)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.students.Payment>|undefined}
 *     The XHR Node Readable Stream
 */
proto.students.PaymentsClient.prototype.editPayment =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/students.Payments/EditPayment',
      request,
      metadata || {},
      methodDescriptor_Payments_EditPayment,
      callback);
};


/**
 * @param {!proto.students.EditPaymentRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.students.Payment>}
 *     Promise that resolves to the response
 */
proto.students.PaymentsPromiseClient.prototype.editPayment =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/students.Payments/EditPayment',
      request,
      metadata || {},
      methodDescriptor_Payments_EditPayment);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.students.RemovePaymentRequest,
 *   !proto.students.Payment>}
 */
const methodDescriptor_Payments_RemovePayment = new grpc.web.MethodDescriptor(
  '/students.Payments/RemovePayment',
  grpc.web.MethodType.UNARY,
  proto.students.RemovePaymentRequest,
  proto.students.Payment,
  /**
   * @param {!proto.students.RemovePaymentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.students.Payment.deserializeBinary
);


/**
 * @param {!proto.students.RemovePaymentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.students.Payment)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.students.Payment>|undefined}
 *     The XHR Node Readable Stream
 */
proto.students.PaymentsClient.prototype.removePayment =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/students.Payments/RemovePayment',
      request,
      metadata || {},
      methodDescriptor_Payments_RemovePayment,
      callback);
};


/**
 * @param {!proto.students.RemovePaymentRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.students.Payment>}
 *     Promise that resolves to the response
 */
proto.students.PaymentsPromiseClient.prototype.removePayment =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/students.Payments/RemovePayment',
      request,
      metadata || {},
      methodDescriptor_Payments_RemovePayment);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.students.BulkEditPaymentRequest,
 *   !proto.students.PaymentList>}
 */
const methodDescriptor_Payments_BulkEditPayment = new grpc.web.MethodDescriptor(
  '/students.Payments/BulkEditPayment',
  grpc.web.MethodType.UNARY,
  proto.students.BulkEditPaymentRequest,
  proto.students.PaymentList,
  /**
   * @param {!proto.students.BulkEditPaymentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.students.PaymentList.deserializeBinary
);


/**
 * @param {!proto.students.BulkEditPaymentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.students.PaymentList)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.students.PaymentList>|undefined}
 *     The XHR Node Readable Stream
 */
proto.students.PaymentsClient.prototype.bulkEditPayment =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/students.Payments/BulkEditPayment',
      request,
      metadata || {},
      methodDescriptor_Payments_BulkEditPayment,
      callback);
};


/**
 * @param {!proto.students.BulkEditPaymentRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.students.PaymentList>}
 *     Promise that resolves to the response
 */
proto.students.PaymentsPromiseClient.prototype.bulkEditPayment =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/students.Payments/BulkEditPayment',
      request,
      metadata || {},
      methodDescriptor_Payments_BulkEditPayment);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.students.GetPaymentListRequest,
 *   !proto.students.GetPaymentListResponse>}
 */
const methodDescriptor_Payments_GetPaymentList = new grpc.web.MethodDescriptor(
  '/students.Payments/GetPaymentList',
  grpc.web.MethodType.UNARY,
  proto.students.GetPaymentListRequest,
  proto.students.GetPaymentListResponse,
  /**
   * @param {!proto.students.GetPaymentListRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.students.GetPaymentListResponse.deserializeBinary
);


/**
 * @param {!proto.students.GetPaymentListRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.students.GetPaymentListResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.students.GetPaymentListResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.students.PaymentsClient.prototype.getPaymentList =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/students.Payments/GetPaymentList',
      request,
      metadata || {},
      methodDescriptor_Payments_GetPaymentList,
      callback);
};


/**
 * @param {!proto.students.GetPaymentListRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.students.GetPaymentListResponse>}
 *     Promise that resolves to the response
 */
proto.students.PaymentsPromiseClient.prototype.getPaymentList =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/students.Payments/GetPaymentList',
      request,
      metadata || {},
      methodDescriptor_Payments_GetPaymentList);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.students.GetPaymentsByIdListRequest,
 *   !proto.students.GetPaymentsByIdListResponse>}
 */
const methodDescriptor_Payments_GetPaymentsByIdList = new grpc.web.MethodDescriptor(
  '/students.Payments/GetPaymentsByIdList',
  grpc.web.MethodType.UNARY,
  proto.students.GetPaymentsByIdListRequest,
  proto.students.GetPaymentsByIdListResponse,
  /**
   * @param {!proto.students.GetPaymentsByIdListRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.students.GetPaymentsByIdListResponse.deserializeBinary
);


/**
 * @param {!proto.students.GetPaymentsByIdListRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.students.GetPaymentsByIdListResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.students.GetPaymentsByIdListResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.students.PaymentsClient.prototype.getPaymentsByIdList =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/students.Payments/GetPaymentsByIdList',
      request,
      metadata || {},
      methodDescriptor_Payments_GetPaymentsByIdList,
      callback);
};


/**
 * @param {!proto.students.GetPaymentsByIdListRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.students.GetPaymentsByIdListResponse>}
 *     Promise that resolves to the response
 */
proto.students.PaymentsPromiseClient.prototype.getPaymentsByIdList =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/students.Payments/GetPaymentsByIdList',
      request,
      metadata || {},
      methodDescriptor_Payments_GetPaymentsByIdList);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.students.GetFullPaymentInfoRequest,
 *   !proto.students.GetFullPaymentInfoResponse>}
 */
const methodDescriptor_Payments_GetFullPaymentInfo = new grpc.web.MethodDescriptor(
  '/students.Payments/GetFullPaymentInfo',
  grpc.web.MethodType.UNARY,
  proto.students.GetFullPaymentInfoRequest,
  proto.students.GetFullPaymentInfoResponse,
  /**
   * @param {!proto.students.GetFullPaymentInfoRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.students.GetFullPaymentInfoResponse.deserializeBinary
);


/**
 * @param {!proto.students.GetFullPaymentInfoRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.students.GetFullPaymentInfoResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.students.GetFullPaymentInfoResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.students.PaymentsClient.prototype.getFullPaymentInfo =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/students.Payments/GetFullPaymentInfo',
      request,
      metadata || {},
      methodDescriptor_Payments_GetFullPaymentInfo,
      callback);
};


/**
 * @param {!proto.students.GetFullPaymentInfoRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.students.GetFullPaymentInfoResponse>}
 *     Promise that resolves to the response
 */
proto.students.PaymentsPromiseClient.prototype.getFullPaymentInfo =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/students.Payments/GetFullPaymentInfo',
      request,
      metadata || {},
      methodDescriptor_Payments_GetFullPaymentInfo);
};


module.exports = proto.students;


<template>
    <transition>
        <div
            class="cookie-banner"
            role="dialog"
            aria-haspopup="dialog"
            :aria-hidden="isShow"
            v-if="isShow"
        >
            <div class="cookie-banner__container default-container">
                <p class="cookie-banner__text">
                    We use cookies to improve  your experience and for analytics. You can go and
                    <router-link to="/cookie-settings">manage cookies</router-link>.
                </p>
                <div class="cookie-banner__actions">
                    <button-base
                        class="button-base--white"
                        text="Accept all"
                        @click="setAllSettings(true)"
                    ></button-base>
                    <button-base
                        class="button-base--white"
                        text="Reject all"
                        @click="setAllSettings(false)"
                    ></button-base>
                </div>
            </div>
        </div>
        <div class="cookie-button" v-else>
            <router-link class="cookie-button__link" :to="isCookieSettings ? '/' : '/cookie-settings'">
                <svg-icon class="cookie-button__icon" :name="isCookieSettings ? 'arrow-back' : 'settings'" :width="2.5" :height="2.5"></svg-icon>
            </router-link>
        </div>
    </transition>
</template>

<script setup>
    import { computed } from '@vue/reactivity';
    import { useRoute } from 'vue-router';
    import { useStore } from 'vuex';
    import ButtonBase from '../ButtonBase/ButtonBase.vue';
    import SvgIcon from '../SvgIcon/SvgIcon.vue';

    const emits = defineEmits(['setCookie']);
    const store = useStore();
    const route = useRoute();
    const isShow = computed(() => store.getters['CookieSettings/isShowCookieBanner']);
    const isCookieSettings = computed(() => route.name === 'CookieSettings');

    const setAllSettings = (isAgree) => {
        store.commit('CookieSettings/SET_IS_SHOW');
        store.commit('CookieSettings/SET_COOKIE_SETTINGS', {
            necessary: true,
            preferences: isAgree,
            statistics: isAgree,
        });
        emits('setCookie');
    }

    store.dispatch('CookieSettings/initCookieSettings');
</script>

<style lang="scss" scoped>
    .cookie-banner {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 1.6rem;
        background-color: $cookie-banner-background;

        &__container {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        &__text {
            @include body;
            color: $white;
            margin: 0 2rem 0 0;

            a {
                font: inherit;
                color: $primary-accent-light;
                text-decoration: none;
                transition: opacity 0.2s ease-in-out;

                &:hover {
                    opacity: 0.66;
                }
            }
        }

        &__actions {
            display: flex;
            gap: 1rem;
        }
    }

    .cookie-button {
        position: fixed;
        left: 2rem;
        bottom: 2rem;
        border-radius: 50%;
        border: .1rem solid $shape-highlight-light;
        background-color: $white;
        overflow: hidden;
        box-shadow: $box-shadow-controls-white;
        transition: box-shadow 0.2s ease-in-out;

        &:hover {
            box-shadow: $box-shadow-controls-white-hover;
        }

        &__link {
            display: block;
            padding: 1.2rem;
            line-height: 0;
        }

        &__icon {
            fill: $primary-accent-light;
        }
    }

    .v-leave-active,
    .v-enter-active {
        transition: opacity 0.4s ease-out;
        transition: transform 0.4s ease-out;
    }

    .v-enter-from,
    .v-leave-to {
        opacity: 0;
        transform: translateY(100%);
    }
</style>

<template>
    <Transition>
        <div v-if="showModal" class="modal-wrapper" @close="close">
            <div class="modal-scroll-container">
                <div class="modal-background" @click="close"></div>
                <div class="modal-inner">
                    <slot :close="close"></slot>
                </div>
            </div>
        </div>
    </Transition>
</template>

<script>
    export default {
        name: "Modal",
        mounted() {
            window.addEventListener('keyup', this.handlePressEsc);
        },
        unmounted() {
            window.removeEventListener('keyup', this.handlePressEsc);
        },
        computed: {
            showModal() {
                return this.$store.state.showModal;
            }
        },
        methods: {
            close() {
                this.$store.commit('DEFAULT_MUT', {
                    property: 'showModal',
                    value: false
                });
            },
            handlePressEsc(event) {
                if (event.key === 'Escape') {
                    this.close();
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .v-leave-active,
    .v-enter-active {
        transition: opacity 0.2s ease-out;

        .modal-inner {
            transition: transform 0.2s ease-out;
        }
    }

    .v-enter-from,
    .v-leave-to {
        opacity: 0;

        .modal-inner {
            transform: translateY(5rem);
        }
    }
</style>

import { notify } from "@kyvg/vue3-notification";
import { store } from '../store';
import { router } from "@/router";
import { deleteCookie } from "./common";

export default function (error) {
    const errors = {
        'errInternal': () => {
            notify({
                type: 'error',
                title: 'Error',
                text: error.message,
            });
        },
        'errIncorrectRequest': () => {
            notify({
                type: 'error',
                title: 'Error',
                text: error.message
            });
        },
        'errNoAccess': async () => {
            notify({
                type: 'error',
                title: 'Error',
                text: error.message
            });
            deleteCookie('session');
            await router.push({ name: 'Login' });
        },
        'errNotFound': async () => {
            notify({
                type: 'error',
                title: 'Error',
                text: error.message
            });
        },
        'errIncorrectMeta': () => {
            notify({
                type: 'error',
                title: 'Error',
                text: error.message
            });
        },
        'errInvalidCredentials': () => {
            notify({
                type: 'error',
                title: 'Error',
                text: error.message
            });
        },
        'errVerificationNotConfirmedRegID': async () => {
            store.commit('DEFAULT_MUT', {
                property: 'isConfirmPassword',
                value: 'true',
            });
            await router.push({ name: 'Confirm' });
            notify({
                type: 'error',
                title: 'Error',
                text: error.message,
            });
        },
        'errInvalidPassword': () => {
            notify({
                type: 'error',
                title: 'Error',
                text: error.message
            });
        },
        'errInvalidEmail': () => {
            notify({
                type: 'error',
                title: 'Error',
                text: error.message
            });
        },
        'errUserAlreadyExists': () => {
            notify({
                type: 'error',
                title: 'Error',
                text: error.message
            });
        },
        'errInvalidFormat': () => {
            notify({
                type: 'error',
                title: 'Error',
                text: error.message
            });
        },
        'errIncorrectConfirmationCode': () => {
            notify({
                type: 'error',
                title: 'Error',
                text: error.message
            });
        },
        'errOauthFailed': () => {
            notify({
                type: 'error',
                title: 'Error',
                text: error.message
            });
        },
        'errIncorrectResetPasswordCode': () => {
            notify({
                type: 'error',
                title: 'Error',
                text: error.message
            });
        },
        'errIdempotencyKeyFatalCollision': () => {
            notify({
                type: 'error',
                title: 'Error',
                text: error.message
            });
        },
    }

    return errors[error.message];
}

/**
 * @fileoverview gRPC-Web generated client stub for calendar
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')
const proto = {};
proto.calendar = require('./calendar_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.calendar.CalendarClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.calendar.CalendarPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.calendar.CurrentCalendarInfo>}
 */
const methodDescriptor_Calendar_GetCalendarInfo = new grpc.web.MethodDescriptor(
  '/calendar.Calendar/GetCalendarInfo',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.calendar.CurrentCalendarInfo,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.calendar.CurrentCalendarInfo.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.calendar.CurrentCalendarInfo)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.calendar.CurrentCalendarInfo>|undefined}
 *     The XHR Node Readable Stream
 */
proto.calendar.CalendarClient.prototype.getCalendarInfo =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/calendar.Calendar/GetCalendarInfo',
      request,
      metadata || {},
      methodDescriptor_Calendar_GetCalendarInfo,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.calendar.CurrentCalendarInfo>}
 *     Promise that resolves to the response
 */
proto.calendar.CalendarPromiseClient.prototype.getCalendarInfo =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/calendar.Calendar/GetCalendarInfo',
      request,
      metadata || {},
      methodDescriptor_Calendar_GetCalendarInfo);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.calendar.CalendarInfo>}
 */
const methodDescriptor_Calendar_EnableCalendar = new grpc.web.MethodDescriptor(
  '/calendar.Calendar/EnableCalendar',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.calendar.CalendarInfo,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.calendar.CalendarInfo.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.calendar.CalendarInfo)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.calendar.CalendarInfo>|undefined}
 *     The XHR Node Readable Stream
 */
proto.calendar.CalendarClient.prototype.enableCalendar =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/calendar.Calendar/EnableCalendar',
      request,
      metadata || {},
      methodDescriptor_Calendar_EnableCalendar,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.calendar.CalendarInfo>}
 *     Promise that resolves to the response
 */
proto.calendar.CalendarPromiseClient.prototype.enableCalendar =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/calendar.Calendar/EnableCalendar',
      request,
      metadata || {},
      methodDescriptor_Calendar_EnableCalendar);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Calendar_DisableCalendar = new grpc.web.MethodDescriptor(
  '/calendar.Calendar/DisableCalendar',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.calendar.CalendarClient.prototype.disableCalendar =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/calendar.Calendar/DisableCalendar',
      request,
      metadata || {},
      methodDescriptor_Calendar_DisableCalendar,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.calendar.CalendarPromiseClient.prototype.disableCalendar =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/calendar.Calendar/DisableCalendar',
      request,
      metadata || {},
      methodDescriptor_Calendar_DisableCalendar);
};


module.exports = proto.calendar;


<template>
    <div class="page-authorization-inner">
        <main :class="[
                'page-authorization-main',
                'page-authorization-main--authorization',
                { 'page-authorization-main--settings': $route.name === 'CookieSettings' }
            ]"
        >
            <router-view />
        </main>
    </div>
</template>

<script>
    import SvgIcon from "@/components/common/SvgIcon/SvgIcon";
    import { mapState } from "vuex";

    export default {
        name: "AuthorizationLayout",
        components: {
            SvgIcon
        },
        computed: {
            ...mapState(['isAuth']),
        },
    }
</script>

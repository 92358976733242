import { PaymentsPromiseClient, PublicPaymentsPromiseClient } from "./proto/payments_grpc_web_pb";
import { GetAccountConnectionLinkRequest, PaymentCheckoutInfoRequest } from './proto/payments_pb';
import { getCookie } from "./common";
import GoogleEmpty from 'google-protobuf/google/protobuf/empty_pb.js';

const emptyProtobuf = new GoogleEmpty.Empty;
const paymentInstancePromise = new PaymentsPromiseClient(process.env.API_URL, null, null);
const publicPaymentInstancePromise = new PublicPaymentsPromiseClient(process.env.API_URL, null, null);

const getPaymentStatus = function () {
    const credentials = { session: getCookie('session') };
    return paymentInstancePromise.getPaymentStatus(emptyProtobuf, credentials);
}

const getAccountConnectionLink = function () {
    const credentials = { session: getCookie('session') };
    const request = new GetAccountConnectionLinkRequest();

    request.setPlatform(2);

    return paymentInstancePromise.getAccountConnectionLink(request, credentials);
}

const getPaymentCheckoutInfo = function (paymentId) {
    const checkoutId = getCookie(paymentId);
    const request = new PaymentCheckoutInfoRequest();

    if (checkoutId) request.setCheckoutid(checkoutId);
    request.setPaymentid(paymentId);

    return publicPaymentInstancePromise.paymentCheckoutInfo(request, {});
}

const payOutNow = function () {
    const credentials = { session: getCookie('session') };
    return paymentInstancePromise.payoutAvailableAmount(emptyProtobuf, credentials);
}

const getDashboardLink = function () {
    const credentials = { session: getCookie('session') };
    return paymentInstancePromise.getDashboardLink(emptyProtobuf, credentials);
}

export { getPaymentStatus, getAccountConnectionLink, getPaymentCheckoutInfo, payOutNow, getDashboardLink };

<template>
    <div class="select-base" v-click-outside="close">
        <div class="select-base__name" v-if="name">{{ name }}</div>
        <div class="select-base__wrapper">
            <label
                @click="setOpenedState"
                class="select-base__label"
                :class="{ 'select-base__label--error': error }"
            >
                <span class="select-base__prefix" v-if="prefix">{{ prefix }}</span>
                <span class="select-base__option" v-if="modelValue && modelValue[label]">{{ modelValue[label] }}</span>
                <span class="select-base__placeholder" v-else-if="placeholder">{{ placeholder }}</span>
                <svg-icon
                    class="select-base__icon"
                    name="picker"
                    :width="1.6"
                    :height="1.6"
                />
            </label>
            <span class="select-base__error" v-if="error">{{ error }}</span>
        </div>
        <div class="select-base__popup" v-show="isOpened">
            <input v-if="searchable" ref="searchInput" class="select-base__popup-search" type="text" v-model="searchValue">
            <ul class="select-base__popup-options" v-if="options && options.length">
                <li
                    class="select-base__popup-option"
                    :class="{ 'selected': option.value === modelValue.value }"
                    v-for="option in filteredOptions"
                    :key="option.value"
                    @click="setCurrentOption(option)"
                >
                    {{ option[label][0].toUpperCase() + option[label].substring(1) }}
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    import SvgIcon from "@/components/common/SvgIcon/SvgIcon";

    export default {
        name: "SelectBase",
        components: {
            SvgIcon,
        },
        emits: ['update:modelValue'],
        props: {
            options: {
                type: Array,
                default: () => []
            },
            name: {
                type: String,
                default: ''
            },
            error: {
                type: String,
                default: '',
            },
            searchable: {
                type: Boolean,
                default: false,
            },
            value: {
                type: Object,
                default: () => {}
            },
            label: {
                type: String,
                default: 'label'
            },
            prefix: {
                type: String,
                default: ''
            },
            placeholder: {
                type: String,
                default: ''
            },
        },
        data() {
            return {
                isOpened: false,
                searchValue: '',
            }
        },
        computed: {
            filteredOptions() {
                if (this.searchValue.trim() && this.searchable) {
                    return this.options.filter(option => {
                        return option.label.toLowerCase().includes(this.searchValue.toLowerCase().trim())
                    });
                }

                return this.options;
            },
            modelValue: {
                get() {
                    return this.value;
                },
                set(value) {
                    this.$emit('update:modelValue', {
                        ...value,
                        name: this.name
                    });
                }
            }
        },
        methods: {
            close() {
                this.isOpened = false;
            },
            setOpenedState() {
                this.isOpened = !this.isOpened;
                if (this.isOpened && this.searchable) {
                    this.$nextTick(() => {
                        this.$refs.searchInput.focus();
                    });
                }
            },
            setCurrentOption(option) {
                this.modelValue = option;
                this.close();
            }
        },
    }
</script>

<style lang="scss" scoped>
    .select-base {
        min-width: 11.5rem;
        position: relative;

        &__name {
            @include body;
            color: $primary-content-light;
            margin-bottom: 1rem;
        }

        &__wrapper {
            position: relative;
        }

        &__label {
            display: flex;
            align-items: center;
            @include body;
            padding: .8rem;
            color: $primary-content-light;
            border: .1rem solid $shape-highlight-light;
            box-shadow: $box-shadow-controls-white;
            border-radius: $border-radius;
            cursor: pointer;
            transition: box-shadow 0.2s ease-in-out;

            &--error {
                border-color: $destructive-light;
                box-shadow: none;
            }

            &:hover {
                box-shadow: $box-shadow-controls-white-hover;
            }
        }

        &__prefix {
            margin-right: .5rem;
            color: $secondary-content;
        }

        &__option {
            margin-right: 1rem;
        }

        &__icon {
            margin-left: auto;
        }

        &__popup {
            width: 100%;
            position: absolute;
            top: 100%;
            margin-top: .8rem;
            border-radius: $border-radius;
            background-color: $white;
            box-shadow: $box-shadow-dropdown;
            z-index: 2;
            overflow: hidden;

            &-search {
                width: 100%;
                padding: 1rem;
                border: none;
                border-bottom: .1rem solid $shape-highlight-light;
                @include body;
                color: $primary-content-light;

                &::placeholder {
                    color: $secondary-content;
                }
            }

            &-options {
                display: flex;
                flex-direction: column;
                list-style: none;
                margin: 0;
                padding: .6rem;
            }

            &-option {
                @include body;
                color: $primary-content-light;
                cursor: pointer;
                padding: .6rem;

                &:hover {
                    background-color: $hover-background-light;
                    border-radius: .4rem;
                }
            }
        }

        &__error {
            position: absolute;
            top: 100%;
            left: 0;
            margin-top: .6rem;
            @include caption;
            padding-left: .8rem;
            color: $destructive-light;
        }
    }
</style>

// source: payments.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
goog.exportSymbol('proto.payments.Amount', null, global);
goog.exportSymbol('proto.payments.ConnectedAccountInfo', null, global);
goog.exportSymbol('proto.payments.GetAccountConnectionLinkRequest', null, global);
goog.exportSymbol('proto.payments.GetAccountConnectionLinkRequest.Platform', null, global);
goog.exportSymbol('proto.payments.GetAccountConnectionLinkResponse', null, global);
goog.exportSymbol('proto.payments.GetAccountConnectionLinkResponse.StatusCase', null, global);
goog.exportSymbol('proto.payments.GetDashboardLinkResponse', null, global);
goog.exportSymbol('proto.payments.GetPaymentStatusResponse', null, global);
goog.exportSymbol('proto.payments.GetPaymentStatusResponse.StatusCase', null, global);
goog.exportSymbol('proto.payments.NotFinishedConnectionStatus', null, global);
goog.exportSymbol('proto.payments.PaymentCheckoutInfoRequest', null, global);
goog.exportSymbol('proto.payments.PaymentCheckoutInfoResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.payments.GetDashboardLinkResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.payments.GetDashboardLinkResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.payments.GetDashboardLinkResponse.displayName = 'proto.payments.GetDashboardLinkResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.payments.PaymentCheckoutInfoRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.payments.PaymentCheckoutInfoRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.payments.PaymentCheckoutInfoRequest.displayName = 'proto.payments.PaymentCheckoutInfoRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.payments.PaymentCheckoutInfoResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.payments.PaymentCheckoutInfoResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.payments.PaymentCheckoutInfoResponse.displayName = 'proto.payments.PaymentCheckoutInfoResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.payments.GetPaymentStatusResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.payments.GetPaymentStatusResponse.oneofGroups_);
};
goog.inherits(proto.payments.GetPaymentStatusResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.payments.GetPaymentStatusResponse.displayName = 'proto.payments.GetPaymentStatusResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.payments.NotFinishedConnectionStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.payments.NotFinishedConnectionStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.payments.NotFinishedConnectionStatus.displayName = 'proto.payments.NotFinishedConnectionStatus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.payments.ConnectedAccountInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.payments.ConnectedAccountInfo.repeatedFields_, null);
};
goog.inherits(proto.payments.ConnectedAccountInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.payments.ConnectedAccountInfo.displayName = 'proto.payments.ConnectedAccountInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.payments.Amount = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.payments.Amount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.payments.Amount.displayName = 'proto.payments.Amount';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.payments.GetAccountConnectionLinkRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.payments.GetAccountConnectionLinkRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.payments.GetAccountConnectionLinkRequest.displayName = 'proto.payments.GetAccountConnectionLinkRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.payments.GetAccountConnectionLinkResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.payments.GetAccountConnectionLinkResponse.oneofGroups_);
};
goog.inherits(proto.payments.GetAccountConnectionLinkResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.payments.GetAccountConnectionLinkResponse.displayName = 'proto.payments.GetAccountConnectionLinkResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.payments.GetDashboardLinkResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.payments.GetDashboardLinkResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.payments.GetDashboardLinkResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.payments.GetDashboardLinkResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    redirecturl: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.payments.GetDashboardLinkResponse}
 */
proto.payments.GetDashboardLinkResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.payments.GetDashboardLinkResponse;
  return proto.payments.GetDashboardLinkResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.payments.GetDashboardLinkResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.payments.GetDashboardLinkResponse}
 */
proto.payments.GetDashboardLinkResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRedirecturl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.payments.GetDashboardLinkResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.payments.GetDashboardLinkResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.payments.GetDashboardLinkResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.payments.GetDashboardLinkResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRedirecturl();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string redirectURL = 1;
 * @return {string}
 */
proto.payments.GetDashboardLinkResponse.prototype.getRedirecturl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.payments.GetDashboardLinkResponse} returns this
 */
proto.payments.GetDashboardLinkResponse.prototype.setRedirecturl = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.payments.PaymentCheckoutInfoRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.payments.PaymentCheckoutInfoRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.payments.PaymentCheckoutInfoRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.payments.PaymentCheckoutInfoRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    paymentid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    checkoutid: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.payments.PaymentCheckoutInfoRequest}
 */
proto.payments.PaymentCheckoutInfoRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.payments.PaymentCheckoutInfoRequest;
  return proto.payments.PaymentCheckoutInfoRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.payments.PaymentCheckoutInfoRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.payments.PaymentCheckoutInfoRequest}
 */
proto.payments.PaymentCheckoutInfoRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaymentid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCheckoutid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.payments.PaymentCheckoutInfoRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.payments.PaymentCheckoutInfoRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.payments.PaymentCheckoutInfoRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.payments.PaymentCheckoutInfoRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPaymentid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string paymentID = 1;
 * @return {string}
 */
proto.payments.PaymentCheckoutInfoRequest.prototype.getPaymentid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.payments.PaymentCheckoutInfoRequest} returns this
 */
proto.payments.PaymentCheckoutInfoRequest.prototype.setPaymentid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string checkoutID = 2;
 * @return {string}
 */
proto.payments.PaymentCheckoutInfoRequest.prototype.getCheckoutid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.payments.PaymentCheckoutInfoRequest} returns this
 */
proto.payments.PaymentCheckoutInfoRequest.prototype.setCheckoutid = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.payments.PaymentCheckoutInfoRequest} returns this
 */
proto.payments.PaymentCheckoutInfoRequest.prototype.clearCheckoutid = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.payments.PaymentCheckoutInfoRequest.prototype.hasCheckoutid = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.payments.PaymentCheckoutInfoResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.payments.PaymentCheckoutInfoResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.payments.PaymentCheckoutInfoResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.payments.PaymentCheckoutInfoResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    redirecturl: jspb.Message.getFieldWithDefault(msg, 1, ""),
    checkoutid: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.payments.PaymentCheckoutInfoResponse}
 */
proto.payments.PaymentCheckoutInfoResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.payments.PaymentCheckoutInfoResponse;
  return proto.payments.PaymentCheckoutInfoResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.payments.PaymentCheckoutInfoResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.payments.PaymentCheckoutInfoResponse}
 */
proto.payments.PaymentCheckoutInfoResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRedirecturl(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCheckoutid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.payments.PaymentCheckoutInfoResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.payments.PaymentCheckoutInfoResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.payments.PaymentCheckoutInfoResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.payments.PaymentCheckoutInfoResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRedirecturl();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCheckoutid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string redirectURL = 1;
 * @return {string}
 */
proto.payments.PaymentCheckoutInfoResponse.prototype.getRedirecturl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.payments.PaymentCheckoutInfoResponse} returns this
 */
proto.payments.PaymentCheckoutInfoResponse.prototype.setRedirecturl = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string checkoutID = 2;
 * @return {string}
 */
proto.payments.PaymentCheckoutInfoResponse.prototype.getCheckoutid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.payments.PaymentCheckoutInfoResponse} returns this
 */
proto.payments.PaymentCheckoutInfoResponse.prototype.setCheckoutid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.payments.GetPaymentStatusResponse.oneofGroups_ = [[1,2,3]];

/**
 * @enum {number}
 */
proto.payments.GetPaymentStatusResponse.StatusCase = {
  STATUS_NOT_SET: 0,
  NOTCONNECTED: 1,
  NOTFINISHED: 2,
  CONNECTED: 3
};

/**
 * @return {proto.payments.GetPaymentStatusResponse.StatusCase}
 */
proto.payments.GetPaymentStatusResponse.prototype.getStatusCase = function() {
  return /** @type {proto.payments.GetPaymentStatusResponse.StatusCase} */(jspb.Message.computeOneofCase(this, proto.payments.GetPaymentStatusResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.payments.GetPaymentStatusResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.payments.GetPaymentStatusResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.payments.GetPaymentStatusResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.payments.GetPaymentStatusResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    notconnected: (f = msg.getNotconnected()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    notfinished: (f = msg.getNotfinished()) && proto.payments.NotFinishedConnectionStatus.toObject(includeInstance, f),
    connected: (f = msg.getConnected()) && proto.payments.ConnectedAccountInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.payments.GetPaymentStatusResponse}
 */
proto.payments.GetPaymentStatusResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.payments.GetPaymentStatusResponse;
  return proto.payments.GetPaymentStatusResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.payments.GetPaymentStatusResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.payments.GetPaymentStatusResponse}
 */
proto.payments.GetPaymentStatusResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setNotconnected(value);
      break;
    case 2:
      var value = new proto.payments.NotFinishedConnectionStatus;
      reader.readMessage(value,proto.payments.NotFinishedConnectionStatus.deserializeBinaryFromReader);
      msg.setNotfinished(value);
      break;
    case 3:
      var value = new proto.payments.ConnectedAccountInfo;
      reader.readMessage(value,proto.payments.ConnectedAccountInfo.deserializeBinaryFromReader);
      msg.setConnected(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.payments.GetPaymentStatusResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.payments.GetPaymentStatusResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.payments.GetPaymentStatusResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.payments.GetPaymentStatusResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNotconnected();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getNotfinished();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.payments.NotFinishedConnectionStatus.serializeBinaryToWriter
    );
  }
  f = message.getConnected();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.payments.ConnectedAccountInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Empty notConnected = 1;
 * @return {?proto.google.protobuf.Empty}
 */
proto.payments.GetPaymentStatusResponse.prototype.getNotconnected = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 1));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.payments.GetPaymentStatusResponse} returns this
*/
proto.payments.GetPaymentStatusResponse.prototype.setNotconnected = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.payments.GetPaymentStatusResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.payments.GetPaymentStatusResponse} returns this
 */
proto.payments.GetPaymentStatusResponse.prototype.clearNotconnected = function() {
  return this.setNotconnected(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.payments.GetPaymentStatusResponse.prototype.hasNotconnected = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional NotFinishedConnectionStatus notFinished = 2;
 * @return {?proto.payments.NotFinishedConnectionStatus}
 */
proto.payments.GetPaymentStatusResponse.prototype.getNotfinished = function() {
  return /** @type{?proto.payments.NotFinishedConnectionStatus} */ (
    jspb.Message.getWrapperField(this, proto.payments.NotFinishedConnectionStatus, 2));
};


/**
 * @param {?proto.payments.NotFinishedConnectionStatus|undefined} value
 * @return {!proto.payments.GetPaymentStatusResponse} returns this
*/
proto.payments.GetPaymentStatusResponse.prototype.setNotfinished = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.payments.GetPaymentStatusResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.payments.GetPaymentStatusResponse} returns this
 */
proto.payments.GetPaymentStatusResponse.prototype.clearNotfinished = function() {
  return this.setNotfinished(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.payments.GetPaymentStatusResponse.prototype.hasNotfinished = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ConnectedAccountInfo connected = 3;
 * @return {?proto.payments.ConnectedAccountInfo}
 */
proto.payments.GetPaymentStatusResponse.prototype.getConnected = function() {
  return /** @type{?proto.payments.ConnectedAccountInfo} */ (
    jspb.Message.getWrapperField(this, proto.payments.ConnectedAccountInfo, 3));
};


/**
 * @param {?proto.payments.ConnectedAccountInfo|undefined} value
 * @return {!proto.payments.GetPaymentStatusResponse} returns this
*/
proto.payments.GetPaymentStatusResponse.prototype.setConnected = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.payments.GetPaymentStatusResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.payments.GetPaymentStatusResponse} returns this
 */
proto.payments.GetPaymentStatusResponse.prototype.clearConnected = function() {
  return this.setConnected(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.payments.GetPaymentStatusResponse.prototype.hasConnected = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.payments.NotFinishedConnectionStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.payments.NotFinishedConnectionStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.payments.NotFinishedConnectionStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.payments.NotFinishedConnectionStatus.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.payments.NotFinishedConnectionStatus}
 */
proto.payments.NotFinishedConnectionStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.payments.NotFinishedConnectionStatus;
  return proto.payments.NotFinishedConnectionStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.payments.NotFinishedConnectionStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.payments.NotFinishedConnectionStatus}
 */
proto.payments.NotFinishedConnectionStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.payments.NotFinishedConnectionStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.payments.NotFinishedConnectionStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.payments.NotFinishedConnectionStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.payments.NotFinishedConnectionStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.payments.ConnectedAccountInfo.repeatedFields_ = [3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.payments.ConnectedAccountInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.payments.ConnectedAccountInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.payments.ConnectedAccountInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.payments.ConnectedAccountInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    defaultbalanceavailable: (f = msg.getDefaultbalanceavailable()) && proto.payments.Amount.toObject(includeInstance, f),
    defaultbalancepending: (f = msg.getDefaultbalancepending()) && proto.payments.Amount.toObject(includeInstance, f),
    otherbalancesavailableList: jspb.Message.toObjectList(msg.getOtherbalancesavailableList(),
    proto.payments.Amount.toObject, includeInstance),
    otherbalancespendingList: jspb.Message.toObjectList(msg.getOtherbalancespendingList(),
    proto.payments.Amount.toObject, includeInstance),
    stripeaccountid: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.payments.ConnectedAccountInfo}
 */
proto.payments.ConnectedAccountInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.payments.ConnectedAccountInfo;
  return proto.payments.ConnectedAccountInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.payments.ConnectedAccountInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.payments.ConnectedAccountInfo}
 */
proto.payments.ConnectedAccountInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.payments.Amount;
      reader.readMessage(value,proto.payments.Amount.deserializeBinaryFromReader);
      msg.setDefaultbalanceavailable(value);
      break;
    case 2:
      var value = new proto.payments.Amount;
      reader.readMessage(value,proto.payments.Amount.deserializeBinaryFromReader);
      msg.setDefaultbalancepending(value);
      break;
    case 3:
      var value = new proto.payments.Amount;
      reader.readMessage(value,proto.payments.Amount.deserializeBinaryFromReader);
      msg.addOtherbalancesavailable(value);
      break;
    case 4:
      var value = new proto.payments.Amount;
      reader.readMessage(value,proto.payments.Amount.deserializeBinaryFromReader);
      msg.addOtherbalancespending(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setStripeaccountid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.payments.ConnectedAccountInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.payments.ConnectedAccountInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.payments.ConnectedAccountInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.payments.ConnectedAccountInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDefaultbalanceavailable();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.payments.Amount.serializeBinaryToWriter
    );
  }
  f = message.getDefaultbalancepending();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.payments.Amount.serializeBinaryToWriter
    );
  }
  f = message.getOtherbalancesavailableList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.payments.Amount.serializeBinaryToWriter
    );
  }
  f = message.getOtherbalancespendingList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.payments.Amount.serializeBinaryToWriter
    );
  }
  f = message.getStripeaccountid();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional Amount defaultBalanceAvailable = 1;
 * @return {?proto.payments.Amount}
 */
proto.payments.ConnectedAccountInfo.prototype.getDefaultbalanceavailable = function() {
  return /** @type{?proto.payments.Amount} */ (
    jspb.Message.getWrapperField(this, proto.payments.Amount, 1));
};


/**
 * @param {?proto.payments.Amount|undefined} value
 * @return {!proto.payments.ConnectedAccountInfo} returns this
*/
proto.payments.ConnectedAccountInfo.prototype.setDefaultbalanceavailable = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.payments.ConnectedAccountInfo} returns this
 */
proto.payments.ConnectedAccountInfo.prototype.clearDefaultbalanceavailable = function() {
  return this.setDefaultbalanceavailable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.payments.ConnectedAccountInfo.prototype.hasDefaultbalanceavailable = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Amount defaultBalancePending = 2;
 * @return {?proto.payments.Amount}
 */
proto.payments.ConnectedAccountInfo.prototype.getDefaultbalancepending = function() {
  return /** @type{?proto.payments.Amount} */ (
    jspb.Message.getWrapperField(this, proto.payments.Amount, 2));
};


/**
 * @param {?proto.payments.Amount|undefined} value
 * @return {!proto.payments.ConnectedAccountInfo} returns this
*/
proto.payments.ConnectedAccountInfo.prototype.setDefaultbalancepending = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.payments.ConnectedAccountInfo} returns this
 */
proto.payments.ConnectedAccountInfo.prototype.clearDefaultbalancepending = function() {
  return this.setDefaultbalancepending(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.payments.ConnectedAccountInfo.prototype.hasDefaultbalancepending = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated Amount otherBalancesAvailable = 3;
 * @return {!Array<!proto.payments.Amount>}
 */
proto.payments.ConnectedAccountInfo.prototype.getOtherbalancesavailableList = function() {
  return /** @type{!Array<!proto.payments.Amount>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.payments.Amount, 3));
};


/**
 * @param {!Array<!proto.payments.Amount>} value
 * @return {!proto.payments.ConnectedAccountInfo} returns this
*/
proto.payments.ConnectedAccountInfo.prototype.setOtherbalancesavailableList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.payments.Amount=} opt_value
 * @param {number=} opt_index
 * @return {!proto.payments.Amount}
 */
proto.payments.ConnectedAccountInfo.prototype.addOtherbalancesavailable = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.payments.Amount, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.payments.ConnectedAccountInfo} returns this
 */
proto.payments.ConnectedAccountInfo.prototype.clearOtherbalancesavailableList = function() {
  return this.setOtherbalancesavailableList([]);
};


/**
 * repeated Amount otherBalancesPending = 4;
 * @return {!Array<!proto.payments.Amount>}
 */
proto.payments.ConnectedAccountInfo.prototype.getOtherbalancespendingList = function() {
  return /** @type{!Array<!proto.payments.Amount>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.payments.Amount, 4));
};


/**
 * @param {!Array<!proto.payments.Amount>} value
 * @return {!proto.payments.ConnectedAccountInfo} returns this
*/
proto.payments.ConnectedAccountInfo.prototype.setOtherbalancespendingList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.payments.Amount=} opt_value
 * @param {number=} opt_index
 * @return {!proto.payments.Amount}
 */
proto.payments.ConnectedAccountInfo.prototype.addOtherbalancespending = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.payments.Amount, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.payments.ConnectedAccountInfo} returns this
 */
proto.payments.ConnectedAccountInfo.prototype.clearOtherbalancespendingList = function() {
  return this.setOtherbalancespendingList([]);
};


/**
 * optional string stripeAccountID = 5;
 * @return {string}
 */
proto.payments.ConnectedAccountInfo.prototype.getStripeaccountid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.payments.ConnectedAccountInfo} returns this
 */
proto.payments.ConnectedAccountInfo.prototype.setStripeaccountid = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.payments.Amount.prototype.toObject = function(opt_includeInstance) {
  return proto.payments.Amount.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.payments.Amount} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.payments.Amount.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFieldWithDefault(msg, 1, 0),
    currency: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.payments.Amount}
 */
proto.payments.Amount.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.payments.Amount;
  return proto.payments.Amount.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.payments.Amount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.payments.Amount}
 */
proto.payments.Amount.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setValue(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.payments.Amount.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.payments.Amount.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.payments.Amount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.payments.Amount.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int64 value = 1;
 * @return {number}
 */
proto.payments.Amount.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.payments.Amount} returns this
 */
proto.payments.Amount.prototype.setValue = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string currency = 2;
 * @return {string}
 */
proto.payments.Amount.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.payments.Amount} returns this
 */
proto.payments.Amount.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.payments.GetAccountConnectionLinkRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.payments.GetAccountConnectionLinkRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.payments.GetAccountConnectionLinkRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.payments.GetAccountConnectionLinkRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    platform: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.payments.GetAccountConnectionLinkRequest}
 */
proto.payments.GetAccountConnectionLinkRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.payments.GetAccountConnectionLinkRequest;
  return proto.payments.GetAccountConnectionLinkRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.payments.GetAccountConnectionLinkRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.payments.GetAccountConnectionLinkRequest}
 */
proto.payments.GetAccountConnectionLinkRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.payments.GetAccountConnectionLinkRequest.Platform} */ (reader.readEnum());
      msg.setPlatform(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.payments.GetAccountConnectionLinkRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.payments.GetAccountConnectionLinkRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.payments.GetAccountConnectionLinkRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.payments.GetAccountConnectionLinkRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPlatform();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.payments.GetAccountConnectionLinkRequest.Platform = {
  WEB: 0,
  IOS: 1
};

/**
 * optional Platform platform = 1;
 * @return {!proto.payments.GetAccountConnectionLinkRequest.Platform}
 */
proto.payments.GetAccountConnectionLinkRequest.prototype.getPlatform = function() {
  return /** @type {!proto.payments.GetAccountConnectionLinkRequest.Platform} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.payments.GetAccountConnectionLinkRequest.Platform} value
 * @return {!proto.payments.GetAccountConnectionLinkRequest} returns this
 */
proto.payments.GetAccountConnectionLinkRequest.prototype.setPlatform = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.payments.GetAccountConnectionLinkResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.payments.GetAccountConnectionLinkResponse.StatusCase = {
  STATUS_NOT_SET: 0,
  CONNECTIONURL: 1,
  CONNECTED: 2
};

/**
 * @return {proto.payments.GetAccountConnectionLinkResponse.StatusCase}
 */
proto.payments.GetAccountConnectionLinkResponse.prototype.getStatusCase = function() {
  return /** @type {proto.payments.GetAccountConnectionLinkResponse.StatusCase} */(jspb.Message.computeOneofCase(this, proto.payments.GetAccountConnectionLinkResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.payments.GetAccountConnectionLinkResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.payments.GetAccountConnectionLinkResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.payments.GetAccountConnectionLinkResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.payments.GetAccountConnectionLinkResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    connectionurl: jspb.Message.getFieldWithDefault(msg, 1, ""),
    connected: (f = msg.getConnected()) && proto.payments.ConnectedAccountInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.payments.GetAccountConnectionLinkResponse}
 */
proto.payments.GetAccountConnectionLinkResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.payments.GetAccountConnectionLinkResponse;
  return proto.payments.GetAccountConnectionLinkResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.payments.GetAccountConnectionLinkResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.payments.GetAccountConnectionLinkResponse}
 */
proto.payments.GetAccountConnectionLinkResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setConnectionurl(value);
      break;
    case 2:
      var value = new proto.payments.ConnectedAccountInfo;
      reader.readMessage(value,proto.payments.ConnectedAccountInfo.deserializeBinaryFromReader);
      msg.setConnected(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.payments.GetAccountConnectionLinkResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.payments.GetAccountConnectionLinkResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.payments.GetAccountConnectionLinkResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.payments.GetAccountConnectionLinkResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getConnected();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.payments.ConnectedAccountInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional string connectionURL = 1;
 * @return {string}
 */
proto.payments.GetAccountConnectionLinkResponse.prototype.getConnectionurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.payments.GetAccountConnectionLinkResponse} returns this
 */
proto.payments.GetAccountConnectionLinkResponse.prototype.setConnectionurl = function(value) {
  return jspb.Message.setOneofField(this, 1, proto.payments.GetAccountConnectionLinkResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.payments.GetAccountConnectionLinkResponse} returns this
 */
proto.payments.GetAccountConnectionLinkResponse.prototype.clearConnectionurl = function() {
  return jspb.Message.setOneofField(this, 1, proto.payments.GetAccountConnectionLinkResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.payments.GetAccountConnectionLinkResponse.prototype.hasConnectionurl = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ConnectedAccountInfo connected = 2;
 * @return {?proto.payments.ConnectedAccountInfo}
 */
proto.payments.GetAccountConnectionLinkResponse.prototype.getConnected = function() {
  return /** @type{?proto.payments.ConnectedAccountInfo} */ (
    jspb.Message.getWrapperField(this, proto.payments.ConnectedAccountInfo, 2));
};


/**
 * @param {?proto.payments.ConnectedAccountInfo|undefined} value
 * @return {!proto.payments.GetAccountConnectionLinkResponse} returns this
*/
proto.payments.GetAccountConnectionLinkResponse.prototype.setConnected = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.payments.GetAccountConnectionLinkResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.payments.GetAccountConnectionLinkResponse} returns this
 */
proto.payments.GetAccountConnectionLinkResponse.prototype.clearConnected = function() {
  return this.setConnected(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.payments.GetAccountConnectionLinkResponse.prototype.hasConnected = function() {
  return jspb.Message.getField(this, 2) != null;
};


goog.object.extend(exports, proto.payments);

<template>
  <div id="app">
      <authorization-layout v-if="!isAuth || isInvoice"></authorization-layout>
      <main-layout v-else></main-layout>
      <notification />
      <cookie-banner @setCookie="enableStatistic" />
  </div>
</template>

<script>
    import AuthorizationLayout from "@/components/common/Layout/AuthorizationLayout";
    import MainLayout from "@/components/common/Layout/MainLayout";
    import Notification from "@/components/common/Notification/Notification";
    import { setOptions, bootstrap } from 'vue-gtag';
    import { mapState } from "vuex";
    import CookieBanner from "./components/common/CookieBanner/CookieBanner.vue";

    export default {
        name: 'App',
        components: {
            AuthorizationLayout,
            MainLayout,
            Notification,
            CookieBanner
        },
        computed: {
            ...mapState(['isAuth', 'isInvoice']),
            ...mapState('CookieSettings', ['cookieSettings']),
        },
        methods: {
            async enableStatistic() {
                if (this.cookieSettings.statistics) {
                    setOptions({
                        config: { id: 'G-L69YRXL0RL' }
                    });
                    await bootstrap();
                }
            }
        }
    }
</script>

<style lang="scss">
    // TODO настроить нормальную сборку css
    //components
    @import "./scss/normalize.scss";
    @import "./scss/fonts.scss";
    @import "./scss/base-styles";
    @import "./components/common/Layout/layout";
    @import "./components/common/Modal/modal";
    @import "./components/common/SvgIcon/svg-icon";
    @import "./components/common/ButtonBaseAuthorization/button-base-authorization";
    @import "./components/common/InputBase/input-base";
    @import "./components/common/FormBase/form-base";
    @import "./components/common/SocialLinks/social-links";
    @import "./components/common/SearchBase/search-base";
    @import "./components/common/InputAvatar/input-avatar";
    @import "./components/common/InputPhoneBase/input-phone-base";
    @import "./components/common/ButtonBase/button-base";
    @import "./components/common/SearchBase/search-base";
    @import "./components/common/AvatarBase/avatar-base";
    @import "./components/common/DatePickerBase/date-picker-base";
    @import "./components/StudentDetail/StudentDetailLessons/student-detail-lessons";
    @import "./components/CoursesPage/courses-page-editor";
    @import "./components/common/TableBase/table-base";
    @import './components/common/SVGIllustration/svg-illustration.scss';

    //pages
    @import "./components/StudentsPage/students-page";
    @import "./components/StudentDetail/student-detail";
    @import "./components/SchedulePage/schedule-page";
</style>

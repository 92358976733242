<template>
    <label :class="['input-avatar', { 'input-avatar--img': !!this.photo }]"
           :style="{width: `${width}rem`, height: `${height}rem`}"
    >
        <svg-icon :width="4.3" :height="3.9" name="photo" v-if="!photo"></svg-icon>
        <img class="input-avatar__photo" :src="`https://api.petroka.app/${photo}`" alt="avatar" v-else />
        <input class="input-avatar__file" type="file" accept="image/png, image/jpeg" @change="upload($event)">
    </label>
</template>

<script>
    import SvgIcon from "@/components/common/SvgIcon/SvgIcon";
    import { uploadAvatar } from '../../../services/apiStudents';
    import errorProcessing from '../../../services/errorProcessing';
    import { mapState } from 'vuex';

    export default {
        name: "InputAvatar",
        components: {
            SvgIcon
        },
        props: {
            width: {
                type: Number,
                default: 10,
            },
            height: {
                type: Number,
                default: 10,
            },
            options: {
                type: Object,
                default: () => {}
            }
        },
        data() {
            return {
                photo: null
            }
        },
        mounted() {
            if (this.currentStudent && Object.values(this.currentStudent).length) {
                this.photo = this.currentStudent.base.photourl;
            }
        },
        computed: {
            ...mapState('Students', ['currentStudent']),
        },
        methods: {
            async upload(event) {
                const file = event.target.files[0];

                if (file) {
                    const data = new FormData();
                    data.append('file', file);

                    try {
                        const response = await uploadAvatar(data);
                        const json = await response.json();
                        this.photo = json.fileUrl;
                    } catch (err) {
                        errorProcessing(err)();
                    }
                    
                    this.$emit('upload', {
                        name: this.options.name,
                        value: this.photo
                    });
                }
            }
        }
    }
</script>

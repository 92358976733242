// source: students.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
goog.exportSymbol('proto.students.AddLessonRequest', null, global);
goog.exportSymbol('proto.students.AddStudentRequest', null, global);
goog.exportSymbol('proto.students.AddStudentResponse', null, global);
goog.exportSymbol('proto.students.Anchor', null, global);
goog.exportSymbol('proto.students.Anchor.ValueCase', null, global);
goog.exportSymbol('proto.students.AvailableNotificationPreferenceResponse', null, global);
goog.exportSymbol('proto.students.BulkAddLessonRequest', null, global);
goog.exportSymbol('proto.students.BulkAddLessonResponse', null, global);
goog.exportSymbol('proto.students.BulkAddStudentRequest', null, global);
goog.exportSymbol('proto.students.BulkAddStudentResponse', null, global);
goog.exportSymbol('proto.students.BulkEditLessonRequest', null, global);
goog.exportSymbol('proto.students.BulkEditPaymentRequest', null, global);
goog.exportSymbol('proto.students.BulkEditStudentRequest', null, global);
goog.exportSymbol('proto.students.BulkEditStudentResponse', null, global);
goog.exportSymbol('proto.students.BulkRemoveLessonRequest', null, global);
goog.exportSymbol('proto.students.BulkRemoveStudentRequest', null, global);
goog.exportSymbol('proto.students.BulkRemoveStudentResponse', null, global);
goog.exportSymbol('proto.students.ContactInfo', null, global);
goog.exportSymbol('proto.students.ContactInfo.ContactType', null, global);
goog.exportSymbol('proto.students.CreatePaymentInfo', null, global);
goog.exportSymbol('proto.students.DateAnchor', null, global);
goog.exportSymbol('proto.students.DateAnchor.ValueCase', null, global);
goog.exportSymbol('proto.students.EditLessonRequest', null, global);
goog.exportSymbol('proto.students.EditPaymentRequest', null, global);
goog.exportSymbol('proto.students.EditStudentRequest', null, global);
goog.exportSymbol('proto.students.GetFullPaymentInfoRequest', null, global);
goog.exportSymbol('proto.students.GetFullPaymentInfoResponse', null, global);
goog.exportSymbol('proto.students.GetFullProfileRequest', null, global);
goog.exportSymbol('proto.students.GetLessonListByIdsRequest', null, global);
goog.exportSymbol('proto.students.GetLessonListRequest', null, global);
goog.exportSymbol('proto.students.GetLessonListResponse', null, global);
goog.exportSymbol('proto.students.GetPaymentListRequest', null, global);
goog.exportSymbol('proto.students.GetPaymentListRequest.SortCase', null, global);
goog.exportSymbol('proto.students.GetPaymentListResponse', null, global);
goog.exportSymbol('proto.students.GetPaymentsByIdListRequest', null, global);
goog.exportSymbol('proto.students.GetPaymentsByIdListResponse', null, global);
goog.exportSymbol('proto.students.GetProfileListByIdsResponse', null, global);
goog.exportSymbol('proto.students.GetProfileListRequest', null, global);
goog.exportSymbol('proto.students.GetScheduleRequest', null, global);
goog.exportSymbol('proto.students.GetScheduleResponse', null, global);
goog.exportSymbol('proto.students.GetStudentListResponse', null, global);
goog.exportSymbol('proto.students.InitiateLessonPaymentRequest', null, global);
goog.exportSymbol('proto.students.Lesson', null, global);
goog.exportSymbol('proto.students.LessonAnchor', null, global);
goog.exportSymbol('proto.students.LessonList', null, global);
goog.exportSymbol('proto.students.LessonListResponse', null, global);
goog.exportSymbol('proto.students.LessonStamp', null, global);
goog.exportSymbol('proto.students.LessonViewModel', null, global);
goog.exportSymbol('proto.students.LessonWithPayment', null, global);
goog.exportSymbol('proto.students.LessonWithPaymentStamps', null, global);
goog.exportSymbol('proto.students.Payment', null, global);
goog.exportSymbol('proto.students.Payment.NotificationPreference', null, global);
goog.exportSymbol('proto.students.Payment.PaymentStatus', null, global);
goog.exportSymbol('proto.students.Payment.PaymentValute', null, global);
goog.exportSymbol('proto.students.PaymentList', null, global);
goog.exportSymbol('proto.students.PaymentStamp', null, global);
goog.exportSymbol('proto.students.Profile', null, global);
goog.exportSymbol('proto.students.ProfileInfoByCourseRequest', null, global);
goog.exportSymbol('proto.students.ProfileShort', null, global);
goog.exportSymbol('proto.students.ProfileStamp', null, global);
goog.exportSymbol('proto.students.RemoveLessonRequest', null, global);
goog.exportSymbol('proto.students.RemovePaymentRequest', null, global);
goog.exportSymbol('proto.students.RemoveStudentRequest', null, global);
goog.exportSymbol('proto.students.StudentInfo', null, global);
goog.exportSymbol('proto.students.StudentInfo.ContentinfoCase', null, global);
goog.exportSymbol('proto.students.StudentInfoStamp', null, global);
goog.exportSymbol('proto.students.UpdateLessonListRequest', null, global);
goog.exportSymbol('proto.students.UpdateLessonListResponse', null, global);
goog.exportSymbol('proto.students.UpdateScheduleRequest', null, global);
goog.exportSymbol('proto.students.UpdateScheduleResponse', null, global);
goog.exportSymbol('proto.students.UpdateStudentListRequest', null, global);
goog.exportSymbol('proto.students.UpdateStudentListResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.students.ProfileInfoByCourseRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.students.ProfileInfoByCourseRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.students.ProfileInfoByCourseRequest.displayName = 'proto.students.ProfileInfoByCourseRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.students.GetFullProfileRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.students.GetFullProfileRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.students.GetFullProfileRequest.displayName = 'proto.students.GetFullProfileRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.students.ProfileShort = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.students.ProfileShort, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.students.ProfileShort.displayName = 'proto.students.ProfileShort';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.students.Profile = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.students.Profile.repeatedFields_, null);
};
goog.inherits(proto.students.Profile, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.students.Profile.displayName = 'proto.students.Profile';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.students.ContactInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.students.ContactInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.students.ContactInfo.displayName = 'proto.students.ContactInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.students.RemoveStudentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.students.RemoveStudentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.students.RemoveStudentRequest.displayName = 'proto.students.RemoveStudentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.students.BulkRemoveStudentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.students.BulkRemoveStudentRequest.repeatedFields_, null);
};
goog.inherits(proto.students.BulkRemoveStudentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.students.BulkRemoveStudentRequest.displayName = 'proto.students.BulkRemoveStudentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.students.BulkRemoveStudentResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.students.BulkRemoveStudentResponse.repeatedFields_, null);
};
goog.inherits(proto.students.BulkRemoveStudentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.students.BulkRemoveStudentResponse.displayName = 'proto.students.BulkRemoveStudentResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.students.GetStudentListResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.students.GetStudentListResponse.repeatedFields_, null);
};
goog.inherits(proto.students.GetStudentListResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.students.GetStudentListResponse.displayName = 'proto.students.GetStudentListResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.students.UpdateStudentListRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.students.UpdateStudentListRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.students.UpdateStudentListRequest.displayName = 'proto.students.UpdateStudentListRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.students.UpdateStudentListResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.students.UpdateStudentListResponse.repeatedFields_, null);
};
goog.inherits(proto.students.UpdateStudentListResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.students.UpdateStudentListResponse.displayName = 'proto.students.UpdateStudentListResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.students.GetProfileListRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.students.GetProfileListRequest.repeatedFields_, null);
};
goog.inherits(proto.students.GetProfileListRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.students.GetProfileListRequest.displayName = 'proto.students.GetProfileListRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.students.GetProfileListByIdsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.students.GetProfileListByIdsResponse.repeatedFields_, null);
};
goog.inherits(proto.students.GetProfileListByIdsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.students.GetProfileListByIdsResponse.displayName = 'proto.students.GetProfileListByIdsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.students.StudentInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.students.StudentInfo.oneofGroups_);
};
goog.inherits(proto.students.StudentInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.students.StudentInfo.displayName = 'proto.students.StudentInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.students.LessonWithPayment = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.students.LessonWithPayment, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.students.LessonWithPayment.displayName = 'proto.students.LessonWithPayment';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.students.LessonViewModel = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.students.LessonViewModel, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.students.LessonViewModel.displayName = 'proto.students.LessonViewModel';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.students.StudentInfoStamp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.students.StudentInfoStamp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.students.StudentInfoStamp.displayName = 'proto.students.StudentInfoStamp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.students.LessonWithPaymentStamps = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.students.LessonWithPaymentStamps, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.students.LessonWithPaymentStamps.displayName = 'proto.students.LessonWithPaymentStamps';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.students.ProfileStamp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.students.ProfileStamp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.students.ProfileStamp.displayName = 'proto.students.ProfileStamp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.students.BulkAddStudentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.students.BulkAddStudentRequest.repeatedFields_, null);
};
goog.inherits(proto.students.BulkAddStudentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.students.BulkAddStudentRequest.displayName = 'proto.students.BulkAddStudentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.students.BulkAddStudentResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.students.BulkAddStudentResponse.repeatedFields_, null);
};
goog.inherits(proto.students.BulkAddStudentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.students.BulkAddStudentResponse.displayName = 'proto.students.BulkAddStudentResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.students.AddStudentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.students.AddStudentRequest.repeatedFields_, null);
};
goog.inherits(proto.students.AddStudentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.students.AddStudentRequest.displayName = 'proto.students.AddStudentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.students.AddStudentResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.students.AddStudentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.students.AddStudentResponse.displayName = 'proto.students.AddStudentResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.students.EditStudentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.students.EditStudentRequest.repeatedFields_, null);
};
goog.inherits(proto.students.EditStudentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.students.EditStudentRequest.displayName = 'proto.students.EditStudentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.students.BulkEditStudentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.students.BulkEditStudentRequest.repeatedFields_, null);
};
goog.inherits(proto.students.BulkEditStudentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.students.BulkEditStudentRequest.displayName = 'proto.students.BulkEditStudentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.students.BulkEditStudentResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.students.BulkEditStudentResponse.repeatedFields_, null);
};
goog.inherits(proto.students.BulkEditStudentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.students.BulkEditStudentResponse.displayName = 'proto.students.BulkEditStudentResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.students.Lesson = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.students.Lesson, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.students.Lesson.displayName = 'proto.students.Lesson';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.students.GetScheduleRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.students.GetScheduleRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.students.GetScheduleRequest.displayName = 'proto.students.GetScheduleRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.students.GetScheduleResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.students.GetScheduleResponse.repeatedFields_, null);
};
goog.inherits(proto.students.GetScheduleResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.students.GetScheduleResponse.displayName = 'proto.students.GetScheduleResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.students.UpdateScheduleRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.students.UpdateScheduleRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.students.UpdateScheduleRequest.displayName = 'proto.students.UpdateScheduleRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.students.UpdateScheduleResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.students.UpdateScheduleResponse.repeatedFields_, null);
};
goog.inherits(proto.students.UpdateScheduleResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.students.UpdateScheduleResponse.displayName = 'proto.students.UpdateScheduleResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.students.GetLessonListRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.students.GetLessonListRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.students.GetLessonListRequest.displayName = 'proto.students.GetLessonListRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.students.GetLessonListResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.students.GetLessonListResponse.repeatedFields_, null);
};
goog.inherits(proto.students.GetLessonListResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.students.GetLessonListResponse.displayName = 'proto.students.GetLessonListResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.students.UpdateLessonListRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.students.UpdateLessonListRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.students.UpdateLessonListRequest.displayName = 'proto.students.UpdateLessonListRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.students.UpdateLessonListResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.students.UpdateLessonListResponse.repeatedFields_, null);
};
goog.inherits(proto.students.UpdateLessonListResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.students.UpdateLessonListResponse.displayName = 'proto.students.UpdateLessonListResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.students.AddLessonRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.students.AddLessonRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.students.AddLessonRequest.displayName = 'proto.students.AddLessonRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.students.BulkAddLessonRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.students.BulkAddLessonRequest.repeatedFields_, null);
};
goog.inherits(proto.students.BulkAddLessonRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.students.BulkAddLessonRequest.displayName = 'proto.students.BulkAddLessonRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.students.BulkAddLessonResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.students.BulkAddLessonResponse.repeatedFields_, null);
};
goog.inherits(proto.students.BulkAddLessonResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.students.BulkAddLessonResponse.displayName = 'proto.students.BulkAddLessonResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.students.LessonList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.students.LessonList.repeatedFields_, null);
};
goog.inherits(proto.students.LessonList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.students.LessonList.displayName = 'proto.students.LessonList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.students.EditLessonRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.students.EditLessonRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.students.EditLessonRequest.displayName = 'proto.students.EditLessonRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.students.BulkEditLessonRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.students.BulkEditLessonRequest.repeatedFields_, null);
};
goog.inherits(proto.students.BulkEditLessonRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.students.BulkEditLessonRequest.displayName = 'proto.students.BulkEditLessonRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.students.RemoveLessonRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.students.RemoveLessonRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.students.RemoveLessonRequest.displayName = 'proto.students.RemoveLessonRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.students.BulkRemoveLessonRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.students.BulkRemoveLessonRequest.repeatedFields_, null);
};
goog.inherits(proto.students.BulkRemoveLessonRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.students.BulkRemoveLessonRequest.displayName = 'proto.students.BulkRemoveLessonRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.students.LessonStamp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.students.LessonStamp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.students.LessonStamp.displayName = 'proto.students.LessonStamp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.students.GetLessonListByIdsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.students.GetLessonListByIdsRequest.repeatedFields_, null);
};
goog.inherits(proto.students.GetLessonListByIdsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.students.GetLessonListByIdsRequest.displayName = 'proto.students.GetLessonListByIdsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.students.LessonListResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.students.LessonListResponse.repeatedFields_, null);
};
goog.inherits(proto.students.LessonListResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.students.LessonListResponse.displayName = 'proto.students.LessonListResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.students.GetPaymentsByIdListRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.students.GetPaymentsByIdListRequest.repeatedFields_, null);
};
goog.inherits(proto.students.GetPaymentsByIdListRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.students.GetPaymentsByIdListRequest.displayName = 'proto.students.GetPaymentsByIdListRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.students.GetPaymentsByIdListResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.students.GetPaymentsByIdListResponse.repeatedFields_, null);
};
goog.inherits(proto.students.GetPaymentsByIdListResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.students.GetPaymentsByIdListResponse.displayName = 'proto.students.GetPaymentsByIdListResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.students.Anchor = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.students.Anchor.oneofGroups_);
};
goog.inherits(proto.students.Anchor, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.students.Anchor.displayName = 'proto.students.Anchor';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.students.LessonAnchor = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.students.LessonAnchor, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.students.LessonAnchor.displayName = 'proto.students.LessonAnchor';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.students.PaymentStamp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.students.PaymentStamp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.students.PaymentStamp.displayName = 'proto.students.PaymentStamp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.students.RemovePaymentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.students.RemovePaymentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.students.RemovePaymentRequest.displayName = 'proto.students.RemovePaymentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.students.GetPaymentListRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.students.GetPaymentListRequest.oneofGroups_);
};
goog.inherits(proto.students.GetPaymentListRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.students.GetPaymentListRequest.displayName = 'proto.students.GetPaymentListRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.students.DateAnchor = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.students.DateAnchor.oneofGroups_);
};
goog.inherits(proto.students.DateAnchor, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.students.DateAnchor.displayName = 'proto.students.DateAnchor';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.students.GetPaymentListResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.students.GetPaymentListResponse.repeatedFields_, null);
};
goog.inherits(proto.students.GetPaymentListResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.students.GetPaymentListResponse.displayName = 'proto.students.GetPaymentListResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.students.CreatePaymentInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.students.CreatePaymentInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.students.CreatePaymentInfo.displayName = 'proto.students.CreatePaymentInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.students.InitiateLessonPaymentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.students.InitiateLessonPaymentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.students.InitiateLessonPaymentRequest.displayName = 'proto.students.InitiateLessonPaymentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.students.EditPaymentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.students.EditPaymentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.students.EditPaymentRequest.displayName = 'proto.students.EditPaymentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.students.BulkEditPaymentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.students.BulkEditPaymentRequest.repeatedFields_, null);
};
goog.inherits(proto.students.BulkEditPaymentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.students.BulkEditPaymentRequest.displayName = 'proto.students.BulkEditPaymentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.students.GetFullPaymentInfoRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.students.GetFullPaymentInfoRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.students.GetFullPaymentInfoRequest.displayName = 'proto.students.GetFullPaymentInfoRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.students.GetFullPaymentInfoResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.students.GetFullPaymentInfoResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.students.GetFullPaymentInfoResponse.displayName = 'proto.students.GetFullPaymentInfoResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.students.AvailableNotificationPreferenceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.students.AvailableNotificationPreferenceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.students.AvailableNotificationPreferenceResponse.displayName = 'proto.students.AvailableNotificationPreferenceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.students.Payment = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.students.Payment, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.students.Payment.displayName = 'proto.students.Payment';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.students.PaymentList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.students.PaymentList.repeatedFields_, null);
};
goog.inherits(proto.students.PaymentList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.students.PaymentList.displayName = 'proto.students.PaymentList';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.students.ProfileInfoByCourseRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.students.ProfileInfoByCourseRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.students.ProfileInfoByCourseRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.students.ProfileInfoByCourseRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    courseid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.students.ProfileInfoByCourseRequest}
 */
proto.students.ProfileInfoByCourseRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.students.ProfileInfoByCourseRequest;
  return proto.students.ProfileInfoByCourseRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.students.ProfileInfoByCourseRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.students.ProfileInfoByCourseRequest}
 */
proto.students.ProfileInfoByCourseRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCourseid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.students.ProfileInfoByCourseRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.students.ProfileInfoByCourseRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.students.ProfileInfoByCourseRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.students.ProfileInfoByCourseRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCourseid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string courseID = 1;
 * @return {string}
 */
proto.students.ProfileInfoByCourseRequest.prototype.getCourseid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.students.ProfileInfoByCourseRequest} returns this
 */
proto.students.ProfileInfoByCourseRequest.prototype.setCourseid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.students.GetFullProfileRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.students.GetFullProfileRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.students.GetFullProfileRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.students.GetFullProfileRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.students.GetFullProfileRequest}
 */
proto.students.GetFullProfileRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.students.GetFullProfileRequest;
  return proto.students.GetFullProfileRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.students.GetFullProfileRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.students.GetFullProfileRequest}
 */
proto.students.GetFullProfileRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.students.GetFullProfileRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.students.GetFullProfileRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.students.GetFullProfileRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.students.GetFullProfileRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.students.GetFullProfileRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.students.GetFullProfileRequest} returns this
 */
proto.students.GetFullProfileRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.students.ProfileShort.prototype.toObject = function(opt_includeInstance) {
  return proto.students.ProfileShort.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.students.ProfileShort} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.students.ProfileShort.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    updatedat: (f = msg.getUpdatedat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    deleted: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    firstname: jspb.Message.getFieldWithDefault(msg, 5, ""),
    lastname: jspb.Message.getFieldWithDefault(msg, 6, ""),
    photourl: jspb.Message.getFieldWithDefault(msg, 7, ""),
    idempotencykey: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.students.ProfileShort}
 */
proto.students.ProfileShort.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.students.ProfileShort;
  return proto.students.ProfileShort.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.students.ProfileShort} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.students.ProfileShort}
 */
proto.students.ProfileShort.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedat(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDeleted(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstname(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastname(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhotourl(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setIdempotencykey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.students.ProfileShort.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.students.ProfileShort.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.students.ProfileShort} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.students.ProfileShort.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUpdatedat();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDeleted();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getFirstname();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getLastname();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPhotourl();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getIdempotencykey();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.students.ProfileShort.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.students.ProfileShort} returns this
 */
proto.students.ProfileShort.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp updatedAt = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.students.ProfileShort.prototype.getUpdatedat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.students.ProfileShort} returns this
*/
proto.students.ProfileShort.prototype.setUpdatedat = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.students.ProfileShort} returns this
 */
proto.students.ProfileShort.prototype.clearUpdatedat = function() {
  return this.setUpdatedat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.students.ProfileShort.prototype.hasUpdatedat = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool deleted = 4;
 * @return {boolean}
 */
proto.students.ProfileShort.prototype.getDeleted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.students.ProfileShort} returns this
 */
proto.students.ProfileShort.prototype.setDeleted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional string firstName = 5;
 * @return {string}
 */
proto.students.ProfileShort.prototype.getFirstname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.students.ProfileShort} returns this
 */
proto.students.ProfileShort.prototype.setFirstname = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string lastName = 6;
 * @return {string}
 */
proto.students.ProfileShort.prototype.getLastname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.students.ProfileShort} returns this
 */
proto.students.ProfileShort.prototype.setLastname = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string photoURL = 7;
 * @return {string}
 */
proto.students.ProfileShort.prototype.getPhotourl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.students.ProfileShort} returns this
 */
proto.students.ProfileShort.prototype.setPhotourl = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string idempotencyKey = 8;
 * @return {string}
 */
proto.students.ProfileShort.prototype.getIdempotencykey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.students.ProfileShort} returns this
 */
proto.students.ProfileShort.prototype.setIdempotencykey = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.students.Profile.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.students.Profile.prototype.toObject = function(opt_includeInstance) {
  return proto.students.Profile.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.students.Profile} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.students.Profile.toObject = function(includeInstance, msg) {
  var f, obj = {
    base: (f = msg.getBase()) && proto.students.ProfileShort.toObject(includeInstance, f),
    updatedat: (f = msg.getUpdatedat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    contactlistList: jspb.Message.toObjectList(msg.getContactlistList(),
    proto.students.ContactInfo.toObject, includeInstance),
    courseid: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.students.Profile}
 */
proto.students.Profile.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.students.Profile;
  return proto.students.Profile.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.students.Profile} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.students.Profile}
 */
proto.students.Profile.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.students.ProfileShort;
      reader.readMessage(value,proto.students.ProfileShort.deserializeBinaryFromReader);
      msg.setBase(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedat(value);
      break;
    case 3:
      var value = new proto.students.ContactInfo;
      reader.readMessage(value,proto.students.ContactInfo.deserializeBinaryFromReader);
      msg.addContactlist(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCourseid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.students.Profile.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.students.Profile.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.students.Profile} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.students.Profile.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBase();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.students.ProfileShort.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedat();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getContactlistList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.students.ContactInfo.serializeBinaryToWriter
    );
  }
  f = message.getCourseid();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional ProfileShort base = 1;
 * @return {?proto.students.ProfileShort}
 */
proto.students.Profile.prototype.getBase = function() {
  return /** @type{?proto.students.ProfileShort} */ (
    jspb.Message.getWrapperField(this, proto.students.ProfileShort, 1));
};


/**
 * @param {?proto.students.ProfileShort|undefined} value
 * @return {!proto.students.Profile} returns this
*/
proto.students.Profile.prototype.setBase = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.students.Profile} returns this
 */
proto.students.Profile.prototype.clearBase = function() {
  return this.setBase(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.students.Profile.prototype.hasBase = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Timestamp updatedAt = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.students.Profile.prototype.getUpdatedat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.students.Profile} returns this
*/
proto.students.Profile.prototype.setUpdatedat = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.students.Profile} returns this
 */
proto.students.Profile.prototype.clearUpdatedat = function() {
  return this.setUpdatedat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.students.Profile.prototype.hasUpdatedat = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated ContactInfo contactList = 3;
 * @return {!Array<!proto.students.ContactInfo>}
 */
proto.students.Profile.prototype.getContactlistList = function() {
  return /** @type{!Array<!proto.students.ContactInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.students.ContactInfo, 3));
};


/**
 * @param {!Array<!proto.students.ContactInfo>} value
 * @return {!proto.students.Profile} returns this
*/
proto.students.Profile.prototype.setContactlistList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.students.ContactInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.students.ContactInfo}
 */
proto.students.Profile.prototype.addContactlist = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.students.ContactInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.students.Profile} returns this
 */
proto.students.Profile.prototype.clearContactlistList = function() {
  return this.setContactlistList([]);
};


/**
 * optional string courseID = 4;
 * @return {string}
 */
proto.students.Profile.prototype.getCourseid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.students.Profile} returns this
 */
proto.students.Profile.prototype.setCourseid = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.students.ContactInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.students.ContactInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.students.ContactInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.students.ContactInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    contacttype: jspb.Message.getFieldWithDefault(msg, 1, 0),
    value: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.students.ContactInfo}
 */
proto.students.ContactInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.students.ContactInfo;
  return proto.students.ContactInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.students.ContactInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.students.ContactInfo}
 */
proto.students.ContactInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.students.ContactInfo.ContactType} */ (reader.readEnum());
      msg.setContacttype(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.students.ContactInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.students.ContactInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.students.ContactInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.students.ContactInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContacttype();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.students.ContactInfo.ContactType = {
  UNKNOWN: 0,
  PHONE: 1,
  EMAIL: 2,
  INSTAGRAM: 3,
  FACEBOOK: 4,
  TELEGRAM: 5
};

/**
 * optional ContactType contactType = 1;
 * @return {!proto.students.ContactInfo.ContactType}
 */
proto.students.ContactInfo.prototype.getContacttype = function() {
  return /** @type {!proto.students.ContactInfo.ContactType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.students.ContactInfo.ContactType} value
 * @return {!proto.students.ContactInfo} returns this
 */
proto.students.ContactInfo.prototype.setContacttype = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.students.ContactInfo.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.students.ContactInfo} returns this
 */
proto.students.ContactInfo.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.students.RemoveStudentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.students.RemoveStudentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.students.RemoveStudentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.students.RemoveStudentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    studentid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.students.RemoveStudentRequest}
 */
proto.students.RemoveStudentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.students.RemoveStudentRequest;
  return proto.students.RemoveStudentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.students.RemoveStudentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.students.RemoveStudentRequest}
 */
proto.students.RemoveStudentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStudentid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.students.RemoveStudentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.students.RemoveStudentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.students.RemoveStudentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.students.RemoveStudentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStudentid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string studentId = 1;
 * @return {string}
 */
proto.students.RemoveStudentRequest.prototype.getStudentid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.students.RemoveStudentRequest} returns this
 */
proto.students.RemoveStudentRequest.prototype.setStudentid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.students.BulkRemoveStudentRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.students.BulkRemoveStudentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.students.BulkRemoveStudentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.students.BulkRemoveStudentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.students.BulkRemoveStudentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    studentidlistList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.students.BulkRemoveStudentRequest}
 */
proto.students.BulkRemoveStudentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.students.BulkRemoveStudentRequest;
  return proto.students.BulkRemoveStudentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.students.BulkRemoveStudentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.students.BulkRemoveStudentRequest}
 */
proto.students.BulkRemoveStudentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addStudentidlist(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.students.BulkRemoveStudentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.students.BulkRemoveStudentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.students.BulkRemoveStudentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.students.BulkRemoveStudentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStudentidlistList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string studentIdList = 1;
 * @return {!Array<string>}
 */
proto.students.BulkRemoveStudentRequest.prototype.getStudentidlistList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.students.BulkRemoveStudentRequest} returns this
 */
proto.students.BulkRemoveStudentRequest.prototype.setStudentidlistList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.students.BulkRemoveStudentRequest} returns this
 */
proto.students.BulkRemoveStudentRequest.prototype.addStudentidlist = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.students.BulkRemoveStudentRequest} returns this
 */
proto.students.BulkRemoveStudentRequest.prototype.clearStudentidlistList = function() {
  return this.setStudentidlistList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.students.BulkRemoveStudentResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.students.BulkRemoveStudentResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.students.BulkRemoveStudentResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.students.BulkRemoveStudentResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.students.BulkRemoveStudentResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    profilelistList: jspb.Message.toObjectList(msg.getProfilelistList(),
    proto.students.ProfileShort.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.students.BulkRemoveStudentResponse}
 */
proto.students.BulkRemoveStudentResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.students.BulkRemoveStudentResponse;
  return proto.students.BulkRemoveStudentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.students.BulkRemoveStudentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.students.BulkRemoveStudentResponse}
 */
proto.students.BulkRemoveStudentResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.students.ProfileShort;
      reader.readMessage(value,proto.students.ProfileShort.deserializeBinaryFromReader);
      msg.addProfilelist(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.students.BulkRemoveStudentResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.students.BulkRemoveStudentResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.students.BulkRemoveStudentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.students.BulkRemoveStudentResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProfilelistList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.students.ProfileShort.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ProfileShort profileList = 1;
 * @return {!Array<!proto.students.ProfileShort>}
 */
proto.students.BulkRemoveStudentResponse.prototype.getProfilelistList = function() {
  return /** @type{!Array<!proto.students.ProfileShort>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.students.ProfileShort, 1));
};


/**
 * @param {!Array<!proto.students.ProfileShort>} value
 * @return {!proto.students.BulkRemoveStudentResponse} returns this
*/
proto.students.BulkRemoveStudentResponse.prototype.setProfilelistList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.students.ProfileShort=} opt_value
 * @param {number=} opt_index
 * @return {!proto.students.ProfileShort}
 */
proto.students.BulkRemoveStudentResponse.prototype.addProfilelist = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.students.ProfileShort, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.students.BulkRemoveStudentResponse} returns this
 */
proto.students.BulkRemoveStudentResponse.prototype.clearProfilelistList = function() {
  return this.setProfilelistList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.students.GetStudentListResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.students.GetStudentListResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.students.GetStudentListResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.students.GetStudentListResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.students.GetStudentListResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    updatedat: (f = msg.getUpdatedat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    studentlistList: jspb.Message.toObjectList(msg.getStudentlistList(),
    proto.students.StudentInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.students.GetStudentListResponse}
 */
proto.students.GetStudentListResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.students.GetStudentListResponse;
  return proto.students.GetStudentListResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.students.GetStudentListResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.students.GetStudentListResponse}
 */
proto.students.GetStudentListResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedat(value);
      break;
    case 2:
      var value = new proto.students.StudentInfo;
      reader.readMessage(value,proto.students.StudentInfo.deserializeBinaryFromReader);
      msg.addStudentlist(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.students.GetStudentListResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.students.GetStudentListResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.students.GetStudentListResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.students.GetStudentListResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpdatedat();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getStudentlistList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.students.StudentInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp updatedAt = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.students.GetStudentListResponse.prototype.getUpdatedat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.students.GetStudentListResponse} returns this
*/
proto.students.GetStudentListResponse.prototype.setUpdatedat = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.students.GetStudentListResponse} returns this
 */
proto.students.GetStudentListResponse.prototype.clearUpdatedat = function() {
  return this.setUpdatedat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.students.GetStudentListResponse.prototype.hasUpdatedat = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated StudentInfo studentList = 2;
 * @return {!Array<!proto.students.StudentInfo>}
 */
proto.students.GetStudentListResponse.prototype.getStudentlistList = function() {
  return /** @type{!Array<!proto.students.StudentInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.students.StudentInfo, 2));
};


/**
 * @param {!Array<!proto.students.StudentInfo>} value
 * @return {!proto.students.GetStudentListResponse} returns this
*/
proto.students.GetStudentListResponse.prototype.setStudentlistList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.students.StudentInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.students.StudentInfo}
 */
proto.students.GetStudentListResponse.prototype.addStudentlist = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.students.StudentInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.students.GetStudentListResponse} returns this
 */
proto.students.GetStudentListResponse.prototype.clearStudentlistList = function() {
  return this.setStudentlistList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.students.UpdateStudentListRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.students.UpdateStudentListRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.students.UpdateStudentListRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.students.UpdateStudentListRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    sincedate: (f = msg.getSincedate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.students.UpdateStudentListRequest}
 */
proto.students.UpdateStudentListRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.students.UpdateStudentListRequest;
  return proto.students.UpdateStudentListRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.students.UpdateStudentListRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.students.UpdateStudentListRequest}
 */
proto.students.UpdateStudentListRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setSincedate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.students.UpdateStudentListRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.students.UpdateStudentListRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.students.UpdateStudentListRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.students.UpdateStudentListRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSincedate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp sinceDate = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.students.UpdateStudentListRequest.prototype.getSincedate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.students.UpdateStudentListRequest} returns this
*/
proto.students.UpdateStudentListRequest.prototype.setSincedate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.students.UpdateStudentListRequest} returns this
 */
proto.students.UpdateStudentListRequest.prototype.clearSincedate = function() {
  return this.setSincedate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.students.UpdateStudentListRequest.prototype.hasSincedate = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.students.UpdateStudentListResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.students.UpdateStudentListResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.students.UpdateStudentListResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.students.UpdateStudentListResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.students.UpdateStudentListResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    updatedat: (f = msg.getUpdatedat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    studentlistList: jspb.Message.toObjectList(msg.getStudentlistList(),
    proto.students.StudentInfoStamp.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.students.UpdateStudentListResponse}
 */
proto.students.UpdateStudentListResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.students.UpdateStudentListResponse;
  return proto.students.UpdateStudentListResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.students.UpdateStudentListResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.students.UpdateStudentListResponse}
 */
proto.students.UpdateStudentListResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedat(value);
      break;
    case 2:
      var value = new proto.students.StudentInfoStamp;
      reader.readMessage(value,proto.students.StudentInfoStamp.deserializeBinaryFromReader);
      msg.addStudentlist(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.students.UpdateStudentListResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.students.UpdateStudentListResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.students.UpdateStudentListResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.students.UpdateStudentListResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpdatedat();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getStudentlistList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.students.StudentInfoStamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp updatedAt = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.students.UpdateStudentListResponse.prototype.getUpdatedat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.students.UpdateStudentListResponse} returns this
*/
proto.students.UpdateStudentListResponse.prototype.setUpdatedat = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.students.UpdateStudentListResponse} returns this
 */
proto.students.UpdateStudentListResponse.prototype.clearUpdatedat = function() {
  return this.setUpdatedat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.students.UpdateStudentListResponse.prototype.hasUpdatedat = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated StudentInfoStamp studentList = 2;
 * @return {!Array<!proto.students.StudentInfoStamp>}
 */
proto.students.UpdateStudentListResponse.prototype.getStudentlistList = function() {
  return /** @type{!Array<!proto.students.StudentInfoStamp>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.students.StudentInfoStamp, 2));
};


/**
 * @param {!Array<!proto.students.StudentInfoStamp>} value
 * @return {!proto.students.UpdateStudentListResponse} returns this
*/
proto.students.UpdateStudentListResponse.prototype.setStudentlistList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.students.StudentInfoStamp=} opt_value
 * @param {number=} opt_index
 * @return {!proto.students.StudentInfoStamp}
 */
proto.students.UpdateStudentListResponse.prototype.addStudentlist = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.students.StudentInfoStamp, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.students.UpdateStudentListResponse} returns this
 */
proto.students.UpdateStudentListResponse.prototype.clearStudentlistList = function() {
  return this.setStudentlistList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.students.GetProfileListRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.students.GetProfileListRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.students.GetProfileListRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.students.GetProfileListRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.students.GetProfileListRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    idlistList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.students.GetProfileListRequest}
 */
proto.students.GetProfileListRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.students.GetProfileListRequest;
  return proto.students.GetProfileListRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.students.GetProfileListRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.students.GetProfileListRequest}
 */
proto.students.GetProfileListRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addIdlist(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.students.GetProfileListRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.students.GetProfileListRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.students.GetProfileListRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.students.GetProfileListRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdlistList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string idList = 1;
 * @return {!Array<string>}
 */
proto.students.GetProfileListRequest.prototype.getIdlistList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.students.GetProfileListRequest} returns this
 */
proto.students.GetProfileListRequest.prototype.setIdlistList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.students.GetProfileListRequest} returns this
 */
proto.students.GetProfileListRequest.prototype.addIdlist = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.students.GetProfileListRequest} returns this
 */
proto.students.GetProfileListRequest.prototype.clearIdlistList = function() {
  return this.setIdlistList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.students.GetProfileListByIdsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.students.GetProfileListByIdsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.students.GetProfileListByIdsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.students.GetProfileListByIdsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.students.GetProfileListByIdsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    profileList: jspb.Message.toObjectList(msg.getProfileList(),
    proto.students.ProfileShort.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.students.GetProfileListByIdsResponse}
 */
proto.students.GetProfileListByIdsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.students.GetProfileListByIdsResponse;
  return proto.students.GetProfileListByIdsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.students.GetProfileListByIdsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.students.GetProfileListByIdsResponse}
 */
proto.students.GetProfileListByIdsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.students.ProfileShort;
      reader.readMessage(value,proto.students.ProfileShort.deserializeBinaryFromReader);
      msg.addProfile(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.students.GetProfileListByIdsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.students.GetProfileListByIdsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.students.GetProfileListByIdsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.students.GetProfileListByIdsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProfileList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.students.ProfileShort.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ProfileShort profile = 1;
 * @return {!Array<!proto.students.ProfileShort>}
 */
proto.students.GetProfileListByIdsResponse.prototype.getProfileList = function() {
  return /** @type{!Array<!proto.students.ProfileShort>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.students.ProfileShort, 1));
};


/**
 * @param {!Array<!proto.students.ProfileShort>} value
 * @return {!proto.students.GetProfileListByIdsResponse} returns this
*/
proto.students.GetProfileListByIdsResponse.prototype.setProfileList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.students.ProfileShort=} opt_value
 * @param {number=} opt_index
 * @return {!proto.students.ProfileShort}
 */
proto.students.GetProfileListByIdsResponse.prototype.addProfile = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.students.ProfileShort, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.students.GetProfileListByIdsResponse} returns this
 */
proto.students.GetProfileListByIdsResponse.prototype.clearProfileList = function() {
  return this.setProfileList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.students.StudentInfo.oneofGroups_ = [[3,4]];

/**
 * @enum {number}
 */
proto.students.StudentInfo.ContentinfoCase = {
  CONTENTINFO_NOT_SET: 0,
  INFO: 3,
  EMPTY: 4
};

/**
 * @return {proto.students.StudentInfo.ContentinfoCase}
 */
proto.students.StudentInfo.prototype.getContentinfoCase = function() {
  return /** @type {proto.students.StudentInfo.ContentinfoCase} */(jspb.Message.computeOneofCase(this, proto.students.StudentInfo.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.students.StudentInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.students.StudentInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.students.StudentInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.students.StudentInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    profile: (f = msg.getProfile()) && proto.students.ProfileShort.toObject(includeInstance, f),
    updatedat: (f = msg.getUpdatedat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    info: (f = msg.getInfo()) && proto.students.LessonWithPayment.toObject(includeInstance, f),
    empty: (f = msg.getEmpty()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.students.StudentInfo}
 */
proto.students.StudentInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.students.StudentInfo;
  return proto.students.StudentInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.students.StudentInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.students.StudentInfo}
 */
proto.students.StudentInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.students.ProfileShort;
      reader.readMessage(value,proto.students.ProfileShort.deserializeBinaryFromReader);
      msg.setProfile(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedat(value);
      break;
    case 3:
      var value = new proto.students.LessonWithPayment;
      reader.readMessage(value,proto.students.LessonWithPayment.deserializeBinaryFromReader);
      msg.setInfo(value);
      break;
    case 4:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setEmpty(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.students.StudentInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.students.StudentInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.students.StudentInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.students.StudentInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProfile();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.students.ProfileShort.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedat();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getInfo();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.students.LessonWithPayment.serializeBinaryToWriter
    );
  }
  f = message.getEmpty();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
};


/**
 * optional ProfileShort profile = 1;
 * @return {?proto.students.ProfileShort}
 */
proto.students.StudentInfo.prototype.getProfile = function() {
  return /** @type{?proto.students.ProfileShort} */ (
    jspb.Message.getWrapperField(this, proto.students.ProfileShort, 1));
};


/**
 * @param {?proto.students.ProfileShort|undefined} value
 * @return {!proto.students.StudentInfo} returns this
*/
proto.students.StudentInfo.prototype.setProfile = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.students.StudentInfo} returns this
 */
proto.students.StudentInfo.prototype.clearProfile = function() {
  return this.setProfile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.students.StudentInfo.prototype.hasProfile = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Timestamp updatedAt = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.students.StudentInfo.prototype.getUpdatedat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.students.StudentInfo} returns this
*/
proto.students.StudentInfo.prototype.setUpdatedat = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.students.StudentInfo} returns this
 */
proto.students.StudentInfo.prototype.clearUpdatedat = function() {
  return this.setUpdatedat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.students.StudentInfo.prototype.hasUpdatedat = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional LessonWithPayment info = 3;
 * @return {?proto.students.LessonWithPayment}
 */
proto.students.StudentInfo.prototype.getInfo = function() {
  return /** @type{?proto.students.LessonWithPayment} */ (
    jspb.Message.getWrapperField(this, proto.students.LessonWithPayment, 3));
};


/**
 * @param {?proto.students.LessonWithPayment|undefined} value
 * @return {!proto.students.StudentInfo} returns this
*/
proto.students.StudentInfo.prototype.setInfo = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.students.StudentInfo.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.students.StudentInfo} returns this
 */
proto.students.StudentInfo.prototype.clearInfo = function() {
  return this.setInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.students.StudentInfo.prototype.hasInfo = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Empty empty = 4;
 * @return {?proto.google.protobuf.Empty}
 */
proto.students.StudentInfo.prototype.getEmpty = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 4));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.students.StudentInfo} returns this
*/
proto.students.StudentInfo.prototype.setEmpty = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.students.StudentInfo.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.students.StudentInfo} returns this
 */
proto.students.StudentInfo.prototype.clearEmpty = function() {
  return this.setEmpty(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.students.StudentInfo.prototype.hasEmpty = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.students.LessonWithPayment.prototype.toObject = function(opt_includeInstance) {
  return proto.students.LessonWithPayment.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.students.LessonWithPayment} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.students.LessonWithPayment.toObject = function(includeInstance, msg) {
  var f, obj = {
    lesson: (f = msg.getLesson()) && proto.students.Lesson.toObject(includeInstance, f),
    payment: (f = msg.getPayment()) && proto.students.Payment.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.students.LessonWithPayment}
 */
proto.students.LessonWithPayment.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.students.LessonWithPayment;
  return proto.students.LessonWithPayment.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.students.LessonWithPayment} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.students.LessonWithPayment}
 */
proto.students.LessonWithPayment.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.students.Lesson;
      reader.readMessage(value,proto.students.Lesson.deserializeBinaryFromReader);
      msg.setLesson(value);
      break;
    case 2:
      var value = new proto.students.Payment;
      reader.readMessage(value,proto.students.Payment.deserializeBinaryFromReader);
      msg.setPayment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.students.LessonWithPayment.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.students.LessonWithPayment.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.students.LessonWithPayment} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.students.LessonWithPayment.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLesson();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.students.Lesson.serializeBinaryToWriter
    );
  }
  f = message.getPayment();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.students.Payment.serializeBinaryToWriter
    );
  }
};


/**
 * optional Lesson lesson = 1;
 * @return {?proto.students.Lesson}
 */
proto.students.LessonWithPayment.prototype.getLesson = function() {
  return /** @type{?proto.students.Lesson} */ (
    jspb.Message.getWrapperField(this, proto.students.Lesson, 1));
};


/**
 * @param {?proto.students.Lesson|undefined} value
 * @return {!proto.students.LessonWithPayment} returns this
*/
proto.students.LessonWithPayment.prototype.setLesson = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.students.LessonWithPayment} returns this
 */
proto.students.LessonWithPayment.prototype.clearLesson = function() {
  return this.setLesson(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.students.LessonWithPayment.prototype.hasLesson = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Payment payment = 2;
 * @return {?proto.students.Payment}
 */
proto.students.LessonWithPayment.prototype.getPayment = function() {
  return /** @type{?proto.students.Payment} */ (
    jspb.Message.getWrapperField(this, proto.students.Payment, 2));
};


/**
 * @param {?proto.students.Payment|undefined} value
 * @return {!proto.students.LessonWithPayment} returns this
*/
proto.students.LessonWithPayment.prototype.setPayment = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.students.LessonWithPayment} returns this
 */
proto.students.LessonWithPayment.prototype.clearPayment = function() {
  return this.setPayment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.students.LessonWithPayment.prototype.hasPayment = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.students.LessonViewModel.prototype.toObject = function(opt_includeInstance) {
  return proto.students.LessonViewModel.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.students.LessonViewModel} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.students.LessonViewModel.toObject = function(includeInstance, msg) {
  var f, obj = {
    lesson: (f = msg.getLesson()) && proto.students.Lesson.toObject(includeInstance, f),
    payment: (f = msg.getPayment()) && proto.students.Payment.toObject(includeInstance, f),
    profile: (f = msg.getProfile()) && proto.students.ProfileShort.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.students.LessonViewModel}
 */
proto.students.LessonViewModel.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.students.LessonViewModel;
  return proto.students.LessonViewModel.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.students.LessonViewModel} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.students.LessonViewModel}
 */
proto.students.LessonViewModel.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.students.Lesson;
      reader.readMessage(value,proto.students.Lesson.deserializeBinaryFromReader);
      msg.setLesson(value);
      break;
    case 2:
      var value = new proto.students.Payment;
      reader.readMessage(value,proto.students.Payment.deserializeBinaryFromReader);
      msg.setPayment(value);
      break;
    case 3:
      var value = new proto.students.ProfileShort;
      reader.readMessage(value,proto.students.ProfileShort.deserializeBinaryFromReader);
      msg.setProfile(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.students.LessonViewModel.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.students.LessonViewModel.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.students.LessonViewModel} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.students.LessonViewModel.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLesson();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.students.Lesson.serializeBinaryToWriter
    );
  }
  f = message.getPayment();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.students.Payment.serializeBinaryToWriter
    );
  }
  f = message.getProfile();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.students.ProfileShort.serializeBinaryToWriter
    );
  }
};


/**
 * optional Lesson lesson = 1;
 * @return {?proto.students.Lesson}
 */
proto.students.LessonViewModel.prototype.getLesson = function() {
  return /** @type{?proto.students.Lesson} */ (
    jspb.Message.getWrapperField(this, proto.students.Lesson, 1));
};


/**
 * @param {?proto.students.Lesson|undefined} value
 * @return {!proto.students.LessonViewModel} returns this
*/
proto.students.LessonViewModel.prototype.setLesson = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.students.LessonViewModel} returns this
 */
proto.students.LessonViewModel.prototype.clearLesson = function() {
  return this.setLesson(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.students.LessonViewModel.prototype.hasLesson = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Payment payment = 2;
 * @return {?proto.students.Payment}
 */
proto.students.LessonViewModel.prototype.getPayment = function() {
  return /** @type{?proto.students.Payment} */ (
    jspb.Message.getWrapperField(this, proto.students.Payment, 2));
};


/**
 * @param {?proto.students.Payment|undefined} value
 * @return {!proto.students.LessonViewModel} returns this
*/
proto.students.LessonViewModel.prototype.setPayment = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.students.LessonViewModel} returns this
 */
proto.students.LessonViewModel.prototype.clearPayment = function() {
  return this.setPayment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.students.LessonViewModel.prototype.hasPayment = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ProfileShort profile = 3;
 * @return {?proto.students.ProfileShort}
 */
proto.students.LessonViewModel.prototype.getProfile = function() {
  return /** @type{?proto.students.ProfileShort} */ (
    jspb.Message.getWrapperField(this, proto.students.ProfileShort, 3));
};


/**
 * @param {?proto.students.ProfileShort|undefined} value
 * @return {!proto.students.LessonViewModel} returns this
*/
proto.students.LessonViewModel.prototype.setProfile = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.students.LessonViewModel} returns this
 */
proto.students.LessonViewModel.prototype.clearProfile = function() {
  return this.setProfile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.students.LessonViewModel.prototype.hasProfile = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.students.StudentInfoStamp.prototype.toObject = function(opt_includeInstance) {
  return proto.students.StudentInfoStamp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.students.StudentInfoStamp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.students.StudentInfoStamp.toObject = function(includeInstance, msg) {
  var f, obj = {
    profile: (f = msg.getProfile()) && proto.students.ProfileStamp.toObject(includeInstance, f),
    updatedat: (f = msg.getUpdatedat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    info: (f = msg.getInfo()) && proto.students.LessonWithPaymentStamps.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.students.StudentInfoStamp}
 */
proto.students.StudentInfoStamp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.students.StudentInfoStamp;
  return proto.students.StudentInfoStamp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.students.StudentInfoStamp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.students.StudentInfoStamp}
 */
proto.students.StudentInfoStamp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.students.ProfileStamp;
      reader.readMessage(value,proto.students.ProfileStamp.deserializeBinaryFromReader);
      msg.setProfile(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedat(value);
      break;
    case 3:
      var value = new proto.students.LessonWithPaymentStamps;
      reader.readMessage(value,proto.students.LessonWithPaymentStamps.deserializeBinaryFromReader);
      msg.setInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.students.StudentInfoStamp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.students.StudentInfoStamp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.students.StudentInfoStamp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.students.StudentInfoStamp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProfile();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.students.ProfileStamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedat();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getInfo();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.students.LessonWithPaymentStamps.serializeBinaryToWriter
    );
  }
};


/**
 * optional ProfileStamp profile = 1;
 * @return {?proto.students.ProfileStamp}
 */
proto.students.StudentInfoStamp.prototype.getProfile = function() {
  return /** @type{?proto.students.ProfileStamp} */ (
    jspb.Message.getWrapperField(this, proto.students.ProfileStamp, 1));
};


/**
 * @param {?proto.students.ProfileStamp|undefined} value
 * @return {!proto.students.StudentInfoStamp} returns this
*/
proto.students.StudentInfoStamp.prototype.setProfile = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.students.StudentInfoStamp} returns this
 */
proto.students.StudentInfoStamp.prototype.clearProfile = function() {
  return this.setProfile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.students.StudentInfoStamp.prototype.hasProfile = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Timestamp updatedAt = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.students.StudentInfoStamp.prototype.getUpdatedat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.students.StudentInfoStamp} returns this
*/
proto.students.StudentInfoStamp.prototype.setUpdatedat = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.students.StudentInfoStamp} returns this
 */
proto.students.StudentInfoStamp.prototype.clearUpdatedat = function() {
  return this.setUpdatedat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.students.StudentInfoStamp.prototype.hasUpdatedat = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional LessonWithPaymentStamps info = 3;
 * @return {?proto.students.LessonWithPaymentStamps}
 */
proto.students.StudentInfoStamp.prototype.getInfo = function() {
  return /** @type{?proto.students.LessonWithPaymentStamps} */ (
    jspb.Message.getWrapperField(this, proto.students.LessonWithPaymentStamps, 3));
};


/**
 * @param {?proto.students.LessonWithPaymentStamps|undefined} value
 * @return {!proto.students.StudentInfoStamp} returns this
*/
proto.students.StudentInfoStamp.prototype.setInfo = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.students.StudentInfoStamp} returns this
 */
proto.students.StudentInfoStamp.prototype.clearInfo = function() {
  return this.setInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.students.StudentInfoStamp.prototype.hasInfo = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.students.LessonWithPaymentStamps.prototype.toObject = function(opt_includeInstance) {
  return proto.students.LessonWithPaymentStamps.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.students.LessonWithPaymentStamps} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.students.LessonWithPaymentStamps.toObject = function(includeInstance, msg) {
  var f, obj = {
    lesson: (f = msg.getLesson()) && proto.students.LessonStamp.toObject(includeInstance, f),
    payment: (f = msg.getPayment()) && proto.students.PaymentStamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.students.LessonWithPaymentStamps}
 */
proto.students.LessonWithPaymentStamps.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.students.LessonWithPaymentStamps;
  return proto.students.LessonWithPaymentStamps.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.students.LessonWithPaymentStamps} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.students.LessonWithPaymentStamps}
 */
proto.students.LessonWithPaymentStamps.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.students.LessonStamp;
      reader.readMessage(value,proto.students.LessonStamp.deserializeBinaryFromReader);
      msg.setLesson(value);
      break;
    case 2:
      var value = new proto.students.PaymentStamp;
      reader.readMessage(value,proto.students.PaymentStamp.deserializeBinaryFromReader);
      msg.setPayment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.students.LessonWithPaymentStamps.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.students.LessonWithPaymentStamps.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.students.LessonWithPaymentStamps} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.students.LessonWithPaymentStamps.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLesson();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.students.LessonStamp.serializeBinaryToWriter
    );
  }
  f = message.getPayment();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.students.PaymentStamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional LessonStamp lesson = 1;
 * @return {?proto.students.LessonStamp}
 */
proto.students.LessonWithPaymentStamps.prototype.getLesson = function() {
  return /** @type{?proto.students.LessonStamp} */ (
    jspb.Message.getWrapperField(this, proto.students.LessonStamp, 1));
};


/**
 * @param {?proto.students.LessonStamp|undefined} value
 * @return {!proto.students.LessonWithPaymentStamps} returns this
*/
proto.students.LessonWithPaymentStamps.prototype.setLesson = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.students.LessonWithPaymentStamps} returns this
 */
proto.students.LessonWithPaymentStamps.prototype.clearLesson = function() {
  return this.setLesson(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.students.LessonWithPaymentStamps.prototype.hasLesson = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional PaymentStamp payment = 2;
 * @return {?proto.students.PaymentStamp}
 */
proto.students.LessonWithPaymentStamps.prototype.getPayment = function() {
  return /** @type{?proto.students.PaymentStamp} */ (
    jspb.Message.getWrapperField(this, proto.students.PaymentStamp, 2));
};


/**
 * @param {?proto.students.PaymentStamp|undefined} value
 * @return {!proto.students.LessonWithPaymentStamps} returns this
*/
proto.students.LessonWithPaymentStamps.prototype.setPayment = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.students.LessonWithPaymentStamps} returns this
 */
proto.students.LessonWithPaymentStamps.prototype.clearPayment = function() {
  return this.setPayment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.students.LessonWithPaymentStamps.prototype.hasPayment = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.students.ProfileStamp.prototype.toObject = function(opt_includeInstance) {
  return proto.students.ProfileStamp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.students.ProfileStamp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.students.ProfileStamp.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    updatedat: (f = msg.getUpdatedat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.students.ProfileStamp}
 */
proto.students.ProfileStamp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.students.ProfileStamp;
  return proto.students.ProfileStamp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.students.ProfileStamp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.students.ProfileStamp}
 */
proto.students.ProfileStamp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedat(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.students.ProfileStamp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.students.ProfileStamp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.students.ProfileStamp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.students.ProfileStamp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUpdatedat();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.students.ProfileStamp.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.students.ProfileStamp} returns this
 */
proto.students.ProfileStamp.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp updatedAt = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.students.ProfileStamp.prototype.getUpdatedat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.students.ProfileStamp} returns this
*/
proto.students.ProfileStamp.prototype.setUpdatedat = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.students.ProfileStamp} returns this
 */
proto.students.ProfileStamp.prototype.clearUpdatedat = function() {
  return this.setUpdatedat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.students.ProfileStamp.prototype.hasUpdatedat = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.students.BulkAddStudentRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.students.BulkAddStudentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.students.BulkAddStudentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.students.BulkAddStudentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.students.BulkAddStudentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestsList: jspb.Message.toObjectList(msg.getRequestsList(),
    proto.students.AddStudentRequest.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.students.BulkAddStudentRequest}
 */
proto.students.BulkAddStudentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.students.BulkAddStudentRequest;
  return proto.students.BulkAddStudentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.students.BulkAddStudentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.students.BulkAddStudentRequest}
 */
proto.students.BulkAddStudentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.students.AddStudentRequest;
      reader.readMessage(value,proto.students.AddStudentRequest.deserializeBinaryFromReader);
      msg.addRequests(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.students.BulkAddStudentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.students.BulkAddStudentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.students.BulkAddStudentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.students.BulkAddStudentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.students.AddStudentRequest.serializeBinaryToWriter
    );
  }
};


/**
 * repeated AddStudentRequest requests = 1;
 * @return {!Array<!proto.students.AddStudentRequest>}
 */
proto.students.BulkAddStudentRequest.prototype.getRequestsList = function() {
  return /** @type{!Array<!proto.students.AddStudentRequest>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.students.AddStudentRequest, 1));
};


/**
 * @param {!Array<!proto.students.AddStudentRequest>} value
 * @return {!proto.students.BulkAddStudentRequest} returns this
*/
proto.students.BulkAddStudentRequest.prototype.setRequestsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.students.AddStudentRequest=} opt_value
 * @param {number=} opt_index
 * @return {!proto.students.AddStudentRequest}
 */
proto.students.BulkAddStudentRequest.prototype.addRequests = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.students.AddStudentRequest, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.students.BulkAddStudentRequest} returns this
 */
proto.students.BulkAddStudentRequest.prototype.clearRequestsList = function() {
  return this.setRequestsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.students.BulkAddStudentResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.students.BulkAddStudentResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.students.BulkAddStudentResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.students.BulkAddStudentResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.students.BulkAddStudentResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    profilesList: jspb.Message.toObjectList(msg.getProfilesList(),
    proto.students.Profile.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.students.BulkAddStudentResponse}
 */
proto.students.BulkAddStudentResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.students.BulkAddStudentResponse;
  return proto.students.BulkAddStudentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.students.BulkAddStudentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.students.BulkAddStudentResponse}
 */
proto.students.BulkAddStudentResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.students.Profile;
      reader.readMessage(value,proto.students.Profile.deserializeBinaryFromReader);
      msg.addProfiles(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.students.BulkAddStudentResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.students.BulkAddStudentResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.students.BulkAddStudentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.students.BulkAddStudentResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProfilesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.students.Profile.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Profile profiles = 1;
 * @return {!Array<!proto.students.Profile>}
 */
proto.students.BulkAddStudentResponse.prototype.getProfilesList = function() {
  return /** @type{!Array<!proto.students.Profile>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.students.Profile, 1));
};


/**
 * @param {!Array<!proto.students.Profile>} value
 * @return {!proto.students.BulkAddStudentResponse} returns this
*/
proto.students.BulkAddStudentResponse.prototype.setProfilesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.students.Profile=} opt_value
 * @param {number=} opt_index
 * @return {!proto.students.Profile}
 */
proto.students.BulkAddStudentResponse.prototype.addProfiles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.students.Profile, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.students.BulkAddStudentResponse} returns this
 */
proto.students.BulkAddStudentResponse.prototype.clearProfilesList = function() {
  return this.setProfilesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.students.AddStudentRequest.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.students.AddStudentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.students.AddStudentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.students.AddStudentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.students.AddStudentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    idempotencykey: jspb.Message.getFieldWithDefault(msg, 1, ""),
    firstname: jspb.Message.getFieldWithDefault(msg, 2, ""),
    lastname: jspb.Message.getFieldWithDefault(msg, 3, ""),
    photourl: jspb.Message.getFieldWithDefault(msg, 4, ""),
    contactlistList: jspb.Message.toObjectList(msg.getContactlistList(),
    proto.students.ContactInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.students.AddStudentRequest}
 */
proto.students.AddStudentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.students.AddStudentRequest;
  return proto.students.AddStudentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.students.AddStudentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.students.AddStudentRequest}
 */
proto.students.AddStudentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setIdempotencykey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstname(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastname(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhotourl(value);
      break;
    case 5:
      var value = new proto.students.ContactInfo;
      reader.readMessage(value,proto.students.ContactInfo.deserializeBinaryFromReader);
      msg.addContactlist(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.students.AddStudentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.students.AddStudentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.students.AddStudentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.students.AddStudentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdempotencykey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFirstname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLastname();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPhotourl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getContactlistList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.students.ContactInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional string idempotencyKey = 1;
 * @return {string}
 */
proto.students.AddStudentRequest.prototype.getIdempotencykey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.students.AddStudentRequest} returns this
 */
proto.students.AddStudentRequest.prototype.setIdempotencykey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string firstName = 2;
 * @return {string}
 */
proto.students.AddStudentRequest.prototype.getFirstname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.students.AddStudentRequest} returns this
 */
proto.students.AddStudentRequest.prototype.setFirstname = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string lastName = 3;
 * @return {string}
 */
proto.students.AddStudentRequest.prototype.getLastname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.students.AddStudentRequest} returns this
 */
proto.students.AddStudentRequest.prototype.setLastname = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string photoURL = 4;
 * @return {string}
 */
proto.students.AddStudentRequest.prototype.getPhotourl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.students.AddStudentRequest} returns this
 */
proto.students.AddStudentRequest.prototype.setPhotourl = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated ContactInfo contactList = 5;
 * @return {!Array<!proto.students.ContactInfo>}
 */
proto.students.AddStudentRequest.prototype.getContactlistList = function() {
  return /** @type{!Array<!proto.students.ContactInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.students.ContactInfo, 5));
};


/**
 * @param {!Array<!proto.students.ContactInfo>} value
 * @return {!proto.students.AddStudentRequest} returns this
*/
proto.students.AddStudentRequest.prototype.setContactlistList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.students.ContactInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.students.ContactInfo}
 */
proto.students.AddStudentRequest.prototype.addContactlist = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.students.ContactInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.students.AddStudentRequest} returns this
 */
proto.students.AddStudentRequest.prototype.clearContactlistList = function() {
  return this.setContactlistList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.students.AddStudentResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.students.AddStudentResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.students.AddStudentResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.students.AddStudentResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    profile: (f = msg.getProfile()) && proto.students.Profile.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.students.AddStudentResponse}
 */
proto.students.AddStudentResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.students.AddStudentResponse;
  return proto.students.AddStudentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.students.AddStudentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.students.AddStudentResponse}
 */
proto.students.AddStudentResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.students.Profile;
      reader.readMessage(value,proto.students.Profile.deserializeBinaryFromReader);
      msg.setProfile(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.students.AddStudentResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.students.AddStudentResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.students.AddStudentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.students.AddStudentResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProfile();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.students.Profile.serializeBinaryToWriter
    );
  }
};


/**
 * optional Profile profile = 1;
 * @return {?proto.students.Profile}
 */
proto.students.AddStudentResponse.prototype.getProfile = function() {
  return /** @type{?proto.students.Profile} */ (
    jspb.Message.getWrapperField(this, proto.students.Profile, 1));
};


/**
 * @param {?proto.students.Profile|undefined} value
 * @return {!proto.students.AddStudentResponse} returns this
*/
proto.students.AddStudentResponse.prototype.setProfile = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.students.AddStudentResponse} returns this
 */
proto.students.AddStudentResponse.prototype.clearProfile = function() {
  return this.setProfile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.students.AddStudentResponse.prototype.hasProfile = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.students.EditStudentRequest.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.students.EditStudentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.students.EditStudentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.students.EditStudentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.students.EditStudentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    firstname: jspb.Message.getFieldWithDefault(msg, 2, ""),
    lastname: jspb.Message.getFieldWithDefault(msg, 3, ""),
    photourl: jspb.Message.getFieldWithDefault(msg, 4, ""),
    contactlistList: jspb.Message.toObjectList(msg.getContactlistList(),
    proto.students.ContactInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.students.EditStudentRequest}
 */
proto.students.EditStudentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.students.EditStudentRequest;
  return proto.students.EditStudentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.students.EditStudentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.students.EditStudentRequest}
 */
proto.students.EditStudentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstname(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastname(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhotourl(value);
      break;
    case 5:
      var value = new proto.students.ContactInfo;
      reader.readMessage(value,proto.students.ContactInfo.deserializeBinaryFromReader);
      msg.addContactlist(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.students.EditStudentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.students.EditStudentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.students.EditStudentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.students.EditStudentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFirstname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLastname();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPhotourl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getContactlistList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.students.ContactInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.students.EditStudentRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.students.EditStudentRequest} returns this
 */
proto.students.EditStudentRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string firstName = 2;
 * @return {string}
 */
proto.students.EditStudentRequest.prototype.getFirstname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.students.EditStudentRequest} returns this
 */
proto.students.EditStudentRequest.prototype.setFirstname = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string lastName = 3;
 * @return {string}
 */
proto.students.EditStudentRequest.prototype.getLastname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.students.EditStudentRequest} returns this
 */
proto.students.EditStudentRequest.prototype.setLastname = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string photoURL = 4;
 * @return {string}
 */
proto.students.EditStudentRequest.prototype.getPhotourl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.students.EditStudentRequest} returns this
 */
proto.students.EditStudentRequest.prototype.setPhotourl = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated ContactInfo contactList = 5;
 * @return {!Array<!proto.students.ContactInfo>}
 */
proto.students.EditStudentRequest.prototype.getContactlistList = function() {
  return /** @type{!Array<!proto.students.ContactInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.students.ContactInfo, 5));
};


/**
 * @param {!Array<!proto.students.ContactInfo>} value
 * @return {!proto.students.EditStudentRequest} returns this
*/
proto.students.EditStudentRequest.prototype.setContactlistList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.students.ContactInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.students.ContactInfo}
 */
proto.students.EditStudentRequest.prototype.addContactlist = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.students.ContactInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.students.EditStudentRequest} returns this
 */
proto.students.EditStudentRequest.prototype.clearContactlistList = function() {
  return this.setContactlistList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.students.BulkEditStudentRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.students.BulkEditStudentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.students.BulkEditStudentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.students.BulkEditStudentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.students.BulkEditStudentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    editlistList: jspb.Message.toObjectList(msg.getEditlistList(),
    proto.students.EditStudentRequest.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.students.BulkEditStudentRequest}
 */
proto.students.BulkEditStudentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.students.BulkEditStudentRequest;
  return proto.students.BulkEditStudentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.students.BulkEditStudentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.students.BulkEditStudentRequest}
 */
proto.students.BulkEditStudentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.students.EditStudentRequest;
      reader.readMessage(value,proto.students.EditStudentRequest.deserializeBinaryFromReader);
      msg.addEditlist(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.students.BulkEditStudentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.students.BulkEditStudentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.students.BulkEditStudentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.students.BulkEditStudentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEditlistList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.students.EditStudentRequest.serializeBinaryToWriter
    );
  }
};


/**
 * repeated EditStudentRequest editList = 1;
 * @return {!Array<!proto.students.EditStudentRequest>}
 */
proto.students.BulkEditStudentRequest.prototype.getEditlistList = function() {
  return /** @type{!Array<!proto.students.EditStudentRequest>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.students.EditStudentRequest, 1));
};


/**
 * @param {!Array<!proto.students.EditStudentRequest>} value
 * @return {!proto.students.BulkEditStudentRequest} returns this
*/
proto.students.BulkEditStudentRequest.prototype.setEditlistList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.students.EditStudentRequest=} opt_value
 * @param {number=} opt_index
 * @return {!proto.students.EditStudentRequest}
 */
proto.students.BulkEditStudentRequest.prototype.addEditlist = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.students.EditStudentRequest, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.students.BulkEditStudentRequest} returns this
 */
proto.students.BulkEditStudentRequest.prototype.clearEditlistList = function() {
  return this.setEditlistList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.students.BulkEditStudentResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.students.BulkEditStudentResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.students.BulkEditStudentResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.students.BulkEditStudentResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.students.BulkEditStudentResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    profilelistList: jspb.Message.toObjectList(msg.getProfilelistList(),
    proto.students.Profile.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.students.BulkEditStudentResponse}
 */
proto.students.BulkEditStudentResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.students.BulkEditStudentResponse;
  return proto.students.BulkEditStudentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.students.BulkEditStudentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.students.BulkEditStudentResponse}
 */
proto.students.BulkEditStudentResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.students.Profile;
      reader.readMessage(value,proto.students.Profile.deserializeBinaryFromReader);
      msg.addProfilelist(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.students.BulkEditStudentResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.students.BulkEditStudentResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.students.BulkEditStudentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.students.BulkEditStudentResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProfilelistList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.students.Profile.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Profile profileList = 1;
 * @return {!Array<!proto.students.Profile>}
 */
proto.students.BulkEditStudentResponse.prototype.getProfilelistList = function() {
  return /** @type{!Array<!proto.students.Profile>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.students.Profile, 1));
};


/**
 * @param {!Array<!proto.students.Profile>} value
 * @return {!proto.students.BulkEditStudentResponse} returns this
*/
proto.students.BulkEditStudentResponse.prototype.setProfilelistList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.students.Profile=} opt_value
 * @param {number=} opt_index
 * @return {!proto.students.Profile}
 */
proto.students.BulkEditStudentResponse.prototype.addProfilelist = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.students.Profile, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.students.BulkEditStudentResponse} returns this
 */
proto.students.BulkEditStudentResponse.prototype.clearProfilelistList = function() {
  return this.setProfilelistList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.students.Lesson.prototype.toObject = function(opt_includeInstance) {
  return proto.students.Lesson.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.students.Lesson} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.students.Lesson.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    createdat: (f = msg.getCreatedat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updatedat: (f = msg.getUpdatedat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    deleted: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    location: jspb.Message.getFieldWithDefault(msg, 5, ""),
    startdate: (f = msg.getStartdate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    enddate: (f = msg.getEnddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    reminderduration: jspb.Message.getFieldWithDefault(msg, 8, 0),
    idempotencykey: jspb.Message.getFieldWithDefault(msg, 9, ""),
    studentid: jspb.Message.getFieldWithDefault(msg, 10, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.students.Lesson}
 */
proto.students.Lesson.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.students.Lesson;
  return proto.students.Lesson.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.students.Lesson} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.students.Lesson}
 */
proto.students.Lesson.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedat(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedat(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDeleted(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocation(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStartdate(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEnddate(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setReminderduration(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setIdempotencykey(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setStudentid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.students.Lesson.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.students.Lesson.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.students.Lesson} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.students.Lesson.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCreatedat();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedat();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDeleted();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getLocation();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getStartdate();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEnddate();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getReminderduration();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getIdempotencykey();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getStudentid();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.students.Lesson.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.students.Lesson} returns this
 */
proto.students.Lesson.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp createdAt = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.students.Lesson.prototype.getCreatedat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.students.Lesson} returns this
*/
proto.students.Lesson.prototype.setCreatedat = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.students.Lesson} returns this
 */
proto.students.Lesson.prototype.clearCreatedat = function() {
  return this.setCreatedat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.students.Lesson.prototype.hasCreatedat = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp updatedAt = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.students.Lesson.prototype.getUpdatedat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.students.Lesson} returns this
*/
proto.students.Lesson.prototype.setUpdatedat = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.students.Lesson} returns this
 */
proto.students.Lesson.prototype.clearUpdatedat = function() {
  return this.setUpdatedat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.students.Lesson.prototype.hasUpdatedat = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bool deleted = 4;
 * @return {boolean}
 */
proto.students.Lesson.prototype.getDeleted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.students.Lesson} returns this
 */
proto.students.Lesson.prototype.setDeleted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional string location = 5;
 * @return {string}
 */
proto.students.Lesson.prototype.getLocation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.students.Lesson} returns this
 */
proto.students.Lesson.prototype.setLocation = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional google.protobuf.Timestamp startDate = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.students.Lesson.prototype.getStartdate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.students.Lesson} returns this
*/
proto.students.Lesson.prototype.setStartdate = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.students.Lesson} returns this
 */
proto.students.Lesson.prototype.clearStartdate = function() {
  return this.setStartdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.students.Lesson.prototype.hasStartdate = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.Timestamp endDate = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.students.Lesson.prototype.getEnddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.students.Lesson} returns this
*/
proto.students.Lesson.prototype.setEnddate = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.students.Lesson} returns this
 */
proto.students.Lesson.prototype.clearEnddate = function() {
  return this.setEnddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.students.Lesson.prototype.hasEnddate = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional int32 reminderDuration = 8;
 * @return {number}
 */
proto.students.Lesson.prototype.getReminderduration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.students.Lesson} returns this
 */
proto.students.Lesson.prototype.setReminderduration = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional string idempotencyKey = 9;
 * @return {string}
 */
proto.students.Lesson.prototype.getIdempotencykey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.students.Lesson} returns this
 */
proto.students.Lesson.prototype.setIdempotencykey = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string studentId = 10;
 * @return {string}
 */
proto.students.Lesson.prototype.getStudentid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.students.Lesson} returns this
 */
proto.students.Lesson.prototype.setStudentid = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.students.GetScheduleRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.students.GetScheduleRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.students.GetScheduleRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.students.GetScheduleRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    startdate: (f = msg.getStartdate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    enddate: (f = msg.getEnddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.students.GetScheduleRequest}
 */
proto.students.GetScheduleRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.students.GetScheduleRequest;
  return proto.students.GetScheduleRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.students.GetScheduleRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.students.GetScheduleRequest}
 */
proto.students.GetScheduleRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStartdate(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEnddate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.students.GetScheduleRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.students.GetScheduleRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.students.GetScheduleRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.students.GetScheduleRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStartdate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEnddate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp startDate = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.students.GetScheduleRequest.prototype.getStartdate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.students.GetScheduleRequest} returns this
*/
proto.students.GetScheduleRequest.prototype.setStartdate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.students.GetScheduleRequest} returns this
 */
proto.students.GetScheduleRequest.prototype.clearStartdate = function() {
  return this.setStartdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.students.GetScheduleRequest.prototype.hasStartdate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Timestamp endDate = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.students.GetScheduleRequest.prototype.getEnddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.students.GetScheduleRequest} returns this
*/
proto.students.GetScheduleRequest.prototype.setEnddate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.students.GetScheduleRequest} returns this
 */
proto.students.GetScheduleRequest.prototype.clearEnddate = function() {
  return this.setEnddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.students.GetScheduleRequest.prototype.hasEnddate = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.students.GetScheduleResponse.repeatedFields_ = [1,2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.students.GetScheduleResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.students.GetScheduleResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.students.GetScheduleResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.students.GetScheduleResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    lessonlistList: jspb.Message.toObjectList(msg.getLessonlistList(),
    proto.students.LessonStamp.toObject, includeInstance),
    paymentlistList: jspb.Message.toObjectList(msg.getPaymentlistList(),
    proto.students.PaymentStamp.toObject, includeInstance),
    profilelistList: jspb.Message.toObjectList(msg.getProfilelistList(),
    proto.students.ProfileStamp.toObject, includeInstance),
    updatedat: (f = msg.getUpdatedat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.students.GetScheduleResponse}
 */
proto.students.GetScheduleResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.students.GetScheduleResponse;
  return proto.students.GetScheduleResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.students.GetScheduleResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.students.GetScheduleResponse}
 */
proto.students.GetScheduleResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.students.LessonStamp;
      reader.readMessage(value,proto.students.LessonStamp.deserializeBinaryFromReader);
      msg.addLessonlist(value);
      break;
    case 2:
      var value = new proto.students.PaymentStamp;
      reader.readMessage(value,proto.students.PaymentStamp.deserializeBinaryFromReader);
      msg.addPaymentlist(value);
      break;
    case 3:
      var value = new proto.students.ProfileStamp;
      reader.readMessage(value,proto.students.ProfileStamp.deserializeBinaryFromReader);
      msg.addProfilelist(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedat(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.students.GetScheduleResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.students.GetScheduleResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.students.GetScheduleResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.students.GetScheduleResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLessonlistList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.students.LessonStamp.serializeBinaryToWriter
    );
  }
  f = message.getPaymentlistList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.students.PaymentStamp.serializeBinaryToWriter
    );
  }
  f = message.getProfilelistList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.students.ProfileStamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedat();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * repeated LessonStamp lessonList = 1;
 * @return {!Array<!proto.students.LessonStamp>}
 */
proto.students.GetScheduleResponse.prototype.getLessonlistList = function() {
  return /** @type{!Array<!proto.students.LessonStamp>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.students.LessonStamp, 1));
};


/**
 * @param {!Array<!proto.students.LessonStamp>} value
 * @return {!proto.students.GetScheduleResponse} returns this
*/
proto.students.GetScheduleResponse.prototype.setLessonlistList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.students.LessonStamp=} opt_value
 * @param {number=} opt_index
 * @return {!proto.students.LessonStamp}
 */
proto.students.GetScheduleResponse.prototype.addLessonlist = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.students.LessonStamp, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.students.GetScheduleResponse} returns this
 */
proto.students.GetScheduleResponse.prototype.clearLessonlistList = function() {
  return this.setLessonlistList([]);
};


/**
 * repeated PaymentStamp paymentList = 2;
 * @return {!Array<!proto.students.PaymentStamp>}
 */
proto.students.GetScheduleResponse.prototype.getPaymentlistList = function() {
  return /** @type{!Array<!proto.students.PaymentStamp>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.students.PaymentStamp, 2));
};


/**
 * @param {!Array<!proto.students.PaymentStamp>} value
 * @return {!proto.students.GetScheduleResponse} returns this
*/
proto.students.GetScheduleResponse.prototype.setPaymentlistList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.students.PaymentStamp=} opt_value
 * @param {number=} opt_index
 * @return {!proto.students.PaymentStamp}
 */
proto.students.GetScheduleResponse.prototype.addPaymentlist = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.students.PaymentStamp, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.students.GetScheduleResponse} returns this
 */
proto.students.GetScheduleResponse.prototype.clearPaymentlistList = function() {
  return this.setPaymentlistList([]);
};


/**
 * repeated ProfileStamp profileList = 3;
 * @return {!Array<!proto.students.ProfileStamp>}
 */
proto.students.GetScheduleResponse.prototype.getProfilelistList = function() {
  return /** @type{!Array<!proto.students.ProfileStamp>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.students.ProfileStamp, 3));
};


/**
 * @param {!Array<!proto.students.ProfileStamp>} value
 * @return {!proto.students.GetScheduleResponse} returns this
*/
proto.students.GetScheduleResponse.prototype.setProfilelistList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.students.ProfileStamp=} opt_value
 * @param {number=} opt_index
 * @return {!proto.students.ProfileStamp}
 */
proto.students.GetScheduleResponse.prototype.addProfilelist = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.students.ProfileStamp, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.students.GetScheduleResponse} returns this
 */
proto.students.GetScheduleResponse.prototype.clearProfilelistList = function() {
  return this.setProfilelistList([]);
};


/**
 * optional google.protobuf.Timestamp updatedAt = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.students.GetScheduleResponse.prototype.getUpdatedat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.students.GetScheduleResponse} returns this
*/
proto.students.GetScheduleResponse.prototype.setUpdatedat = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.students.GetScheduleResponse} returns this
 */
proto.students.GetScheduleResponse.prototype.clearUpdatedat = function() {
  return this.setUpdatedat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.students.GetScheduleResponse.prototype.hasUpdatedat = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.students.UpdateScheduleRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.students.UpdateScheduleRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.students.UpdateScheduleRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.students.UpdateScheduleRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    sincedate: (f = msg.getSincedate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.students.UpdateScheduleRequest}
 */
proto.students.UpdateScheduleRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.students.UpdateScheduleRequest;
  return proto.students.UpdateScheduleRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.students.UpdateScheduleRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.students.UpdateScheduleRequest}
 */
proto.students.UpdateScheduleRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setSincedate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.students.UpdateScheduleRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.students.UpdateScheduleRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.students.UpdateScheduleRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.students.UpdateScheduleRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSincedate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp sinceDate = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.students.UpdateScheduleRequest.prototype.getSincedate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.students.UpdateScheduleRequest} returns this
*/
proto.students.UpdateScheduleRequest.prototype.setSincedate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.students.UpdateScheduleRequest} returns this
 */
proto.students.UpdateScheduleRequest.prototype.clearSincedate = function() {
  return this.setSincedate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.students.UpdateScheduleRequest.prototype.hasSincedate = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.students.UpdateScheduleResponse.repeatedFields_ = [1,2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.students.UpdateScheduleResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.students.UpdateScheduleResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.students.UpdateScheduleResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.students.UpdateScheduleResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    lessonlistList: jspb.Message.toObjectList(msg.getLessonlistList(),
    proto.students.LessonStamp.toObject, includeInstance),
    paymentlistList: jspb.Message.toObjectList(msg.getPaymentlistList(),
    proto.students.PaymentStamp.toObject, includeInstance),
    profilelistList: jspb.Message.toObjectList(msg.getProfilelistList(),
    proto.students.ProfileStamp.toObject, includeInstance),
    updatedat: (f = msg.getUpdatedat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.students.UpdateScheduleResponse}
 */
proto.students.UpdateScheduleResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.students.UpdateScheduleResponse;
  return proto.students.UpdateScheduleResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.students.UpdateScheduleResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.students.UpdateScheduleResponse}
 */
proto.students.UpdateScheduleResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.students.LessonStamp;
      reader.readMessage(value,proto.students.LessonStamp.deserializeBinaryFromReader);
      msg.addLessonlist(value);
      break;
    case 2:
      var value = new proto.students.PaymentStamp;
      reader.readMessage(value,proto.students.PaymentStamp.deserializeBinaryFromReader);
      msg.addPaymentlist(value);
      break;
    case 3:
      var value = new proto.students.ProfileStamp;
      reader.readMessage(value,proto.students.ProfileStamp.deserializeBinaryFromReader);
      msg.addProfilelist(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedat(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.students.UpdateScheduleResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.students.UpdateScheduleResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.students.UpdateScheduleResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.students.UpdateScheduleResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLessonlistList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.students.LessonStamp.serializeBinaryToWriter
    );
  }
  f = message.getPaymentlistList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.students.PaymentStamp.serializeBinaryToWriter
    );
  }
  f = message.getProfilelistList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.students.ProfileStamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedat();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * repeated LessonStamp lessonList = 1;
 * @return {!Array<!proto.students.LessonStamp>}
 */
proto.students.UpdateScheduleResponse.prototype.getLessonlistList = function() {
  return /** @type{!Array<!proto.students.LessonStamp>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.students.LessonStamp, 1));
};


/**
 * @param {!Array<!proto.students.LessonStamp>} value
 * @return {!proto.students.UpdateScheduleResponse} returns this
*/
proto.students.UpdateScheduleResponse.prototype.setLessonlistList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.students.LessonStamp=} opt_value
 * @param {number=} opt_index
 * @return {!proto.students.LessonStamp}
 */
proto.students.UpdateScheduleResponse.prototype.addLessonlist = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.students.LessonStamp, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.students.UpdateScheduleResponse} returns this
 */
proto.students.UpdateScheduleResponse.prototype.clearLessonlistList = function() {
  return this.setLessonlistList([]);
};


/**
 * repeated PaymentStamp paymentList = 2;
 * @return {!Array<!proto.students.PaymentStamp>}
 */
proto.students.UpdateScheduleResponse.prototype.getPaymentlistList = function() {
  return /** @type{!Array<!proto.students.PaymentStamp>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.students.PaymentStamp, 2));
};


/**
 * @param {!Array<!proto.students.PaymentStamp>} value
 * @return {!proto.students.UpdateScheduleResponse} returns this
*/
proto.students.UpdateScheduleResponse.prototype.setPaymentlistList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.students.PaymentStamp=} opt_value
 * @param {number=} opt_index
 * @return {!proto.students.PaymentStamp}
 */
proto.students.UpdateScheduleResponse.prototype.addPaymentlist = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.students.PaymentStamp, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.students.UpdateScheduleResponse} returns this
 */
proto.students.UpdateScheduleResponse.prototype.clearPaymentlistList = function() {
  return this.setPaymentlistList([]);
};


/**
 * repeated ProfileStamp profileList = 3;
 * @return {!Array<!proto.students.ProfileStamp>}
 */
proto.students.UpdateScheduleResponse.prototype.getProfilelistList = function() {
  return /** @type{!Array<!proto.students.ProfileStamp>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.students.ProfileStamp, 3));
};


/**
 * @param {!Array<!proto.students.ProfileStamp>} value
 * @return {!proto.students.UpdateScheduleResponse} returns this
*/
proto.students.UpdateScheduleResponse.prototype.setProfilelistList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.students.ProfileStamp=} opt_value
 * @param {number=} opt_index
 * @return {!proto.students.ProfileStamp}
 */
proto.students.UpdateScheduleResponse.prototype.addProfilelist = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.students.ProfileStamp, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.students.UpdateScheduleResponse} returns this
 */
proto.students.UpdateScheduleResponse.prototype.clearProfilelistList = function() {
  return this.setProfilelistList([]);
};


/**
 * optional google.protobuf.Timestamp updatedAt = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.students.UpdateScheduleResponse.prototype.getUpdatedat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.students.UpdateScheduleResponse} returns this
*/
proto.students.UpdateScheduleResponse.prototype.setUpdatedat = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.students.UpdateScheduleResponse} returns this
 */
proto.students.UpdateScheduleResponse.prototype.clearUpdatedat = function() {
  return this.setUpdatedat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.students.UpdateScheduleResponse.prototype.hasUpdatedat = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.students.GetLessonListRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.students.GetLessonListRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.students.GetLessonListRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.students.GetLessonListRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    studentid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    anchor: (f = msg.getAnchor()) && proto.students.Anchor.toObject(includeInstance, f),
    limit: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.students.GetLessonListRequest}
 */
proto.students.GetLessonListRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.students.GetLessonListRequest;
  return proto.students.GetLessonListRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.students.GetLessonListRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.students.GetLessonListRequest}
 */
proto.students.GetLessonListRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStudentid(value);
      break;
    case 2:
      var value = new proto.students.Anchor;
      reader.readMessage(value,proto.students.Anchor.deserializeBinaryFromReader);
      msg.setAnchor(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLimit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.students.GetLessonListRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.students.GetLessonListRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.students.GetLessonListRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.students.GetLessonListRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStudentid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAnchor();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.students.Anchor.serializeBinaryToWriter
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional string studentId = 1;
 * @return {string}
 */
proto.students.GetLessonListRequest.prototype.getStudentid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.students.GetLessonListRequest} returns this
 */
proto.students.GetLessonListRequest.prototype.setStudentid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Anchor anchor = 2;
 * @return {?proto.students.Anchor}
 */
proto.students.GetLessonListRequest.prototype.getAnchor = function() {
  return /** @type{?proto.students.Anchor} */ (
    jspb.Message.getWrapperField(this, proto.students.Anchor, 2));
};


/**
 * @param {?proto.students.Anchor|undefined} value
 * @return {!proto.students.GetLessonListRequest} returns this
*/
proto.students.GetLessonListRequest.prototype.setAnchor = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.students.GetLessonListRequest} returns this
 */
proto.students.GetLessonListRequest.prototype.clearAnchor = function() {
  return this.setAnchor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.students.GetLessonListRequest.prototype.hasAnchor = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int32 limit = 3;
 * @return {number}
 */
proto.students.GetLessonListRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.students.GetLessonListRequest} returns this
 */
proto.students.GetLessonListRequest.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.students.GetLessonListResponse.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.students.GetLessonListResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.students.GetLessonListResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.students.GetLessonListResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.students.GetLessonListResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    lessonlistList: jspb.Message.toObjectList(msg.getLessonlistList(),
    proto.students.LessonStamp.toObject, includeInstance),
    paymentlistList: jspb.Message.toObjectList(msg.getPaymentlistList(),
    proto.students.PaymentStamp.toObject, includeInstance),
    firstlessondate: (f = msg.getFirstlessondate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updatedat: (f = msg.getUpdatedat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.students.GetLessonListResponse}
 */
proto.students.GetLessonListResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.students.GetLessonListResponse;
  return proto.students.GetLessonListResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.students.GetLessonListResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.students.GetLessonListResponse}
 */
proto.students.GetLessonListResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.students.LessonStamp;
      reader.readMessage(value,proto.students.LessonStamp.deserializeBinaryFromReader);
      msg.addLessonlist(value);
      break;
    case 2:
      var value = new proto.students.PaymentStamp;
      reader.readMessage(value,proto.students.PaymentStamp.deserializeBinaryFromReader);
      msg.addPaymentlist(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setFirstlessondate(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedat(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.students.GetLessonListResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.students.GetLessonListResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.students.GetLessonListResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.students.GetLessonListResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLessonlistList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.students.LessonStamp.serializeBinaryToWriter
    );
  }
  f = message.getPaymentlistList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.students.PaymentStamp.serializeBinaryToWriter
    );
  }
  f = message.getFirstlessondate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedat();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * repeated LessonStamp lessonList = 1;
 * @return {!Array<!proto.students.LessonStamp>}
 */
proto.students.GetLessonListResponse.prototype.getLessonlistList = function() {
  return /** @type{!Array<!proto.students.LessonStamp>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.students.LessonStamp, 1));
};


/**
 * @param {!Array<!proto.students.LessonStamp>} value
 * @return {!proto.students.GetLessonListResponse} returns this
*/
proto.students.GetLessonListResponse.prototype.setLessonlistList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.students.LessonStamp=} opt_value
 * @param {number=} opt_index
 * @return {!proto.students.LessonStamp}
 */
proto.students.GetLessonListResponse.prototype.addLessonlist = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.students.LessonStamp, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.students.GetLessonListResponse} returns this
 */
proto.students.GetLessonListResponse.prototype.clearLessonlistList = function() {
  return this.setLessonlistList([]);
};


/**
 * repeated PaymentStamp paymentList = 2;
 * @return {!Array<!proto.students.PaymentStamp>}
 */
proto.students.GetLessonListResponse.prototype.getPaymentlistList = function() {
  return /** @type{!Array<!proto.students.PaymentStamp>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.students.PaymentStamp, 2));
};


/**
 * @param {!Array<!proto.students.PaymentStamp>} value
 * @return {!proto.students.GetLessonListResponse} returns this
*/
proto.students.GetLessonListResponse.prototype.setPaymentlistList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.students.PaymentStamp=} opt_value
 * @param {number=} opt_index
 * @return {!proto.students.PaymentStamp}
 */
proto.students.GetLessonListResponse.prototype.addPaymentlist = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.students.PaymentStamp, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.students.GetLessonListResponse} returns this
 */
proto.students.GetLessonListResponse.prototype.clearPaymentlistList = function() {
  return this.setPaymentlistList([]);
};


/**
 * optional google.protobuf.Timestamp firstLessonDate = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.students.GetLessonListResponse.prototype.getFirstlessondate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.students.GetLessonListResponse} returns this
*/
proto.students.GetLessonListResponse.prototype.setFirstlessondate = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.students.GetLessonListResponse} returns this
 */
proto.students.GetLessonListResponse.prototype.clearFirstlessondate = function() {
  return this.setFirstlessondate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.students.GetLessonListResponse.prototype.hasFirstlessondate = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Timestamp updatedAt = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.students.GetLessonListResponse.prototype.getUpdatedat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.students.GetLessonListResponse} returns this
*/
proto.students.GetLessonListResponse.prototype.setUpdatedat = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.students.GetLessonListResponse} returns this
 */
proto.students.GetLessonListResponse.prototype.clearUpdatedat = function() {
  return this.setUpdatedat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.students.GetLessonListResponse.prototype.hasUpdatedat = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.students.UpdateLessonListRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.students.UpdateLessonListRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.students.UpdateLessonListRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.students.UpdateLessonListRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    studentid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sincedate: (f = msg.getSincedate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.students.UpdateLessonListRequest}
 */
proto.students.UpdateLessonListRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.students.UpdateLessonListRequest;
  return proto.students.UpdateLessonListRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.students.UpdateLessonListRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.students.UpdateLessonListRequest}
 */
proto.students.UpdateLessonListRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStudentid(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setSincedate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.students.UpdateLessonListRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.students.UpdateLessonListRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.students.UpdateLessonListRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.students.UpdateLessonListRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStudentid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSincedate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string studentID = 1;
 * @return {string}
 */
proto.students.UpdateLessonListRequest.prototype.getStudentid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.students.UpdateLessonListRequest} returns this
 */
proto.students.UpdateLessonListRequest.prototype.setStudentid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp sinceDate = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.students.UpdateLessonListRequest.prototype.getSincedate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.students.UpdateLessonListRequest} returns this
*/
proto.students.UpdateLessonListRequest.prototype.setSincedate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.students.UpdateLessonListRequest} returns this
 */
proto.students.UpdateLessonListRequest.prototype.clearSincedate = function() {
  return this.setSincedate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.students.UpdateLessonListRequest.prototype.hasSincedate = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.students.UpdateLessonListResponse.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.students.UpdateLessonListResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.students.UpdateLessonListResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.students.UpdateLessonListResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.students.UpdateLessonListResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    lessonlistList: jspb.Message.toObjectList(msg.getLessonlistList(),
    proto.students.LessonStamp.toObject, includeInstance),
    paymentlistList: jspb.Message.toObjectList(msg.getPaymentlistList(),
    proto.students.PaymentStamp.toObject, includeInstance),
    updatedat: (f = msg.getUpdatedat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.students.UpdateLessonListResponse}
 */
proto.students.UpdateLessonListResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.students.UpdateLessonListResponse;
  return proto.students.UpdateLessonListResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.students.UpdateLessonListResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.students.UpdateLessonListResponse}
 */
proto.students.UpdateLessonListResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.students.LessonStamp;
      reader.readMessage(value,proto.students.LessonStamp.deserializeBinaryFromReader);
      msg.addLessonlist(value);
      break;
    case 2:
      var value = new proto.students.PaymentStamp;
      reader.readMessage(value,proto.students.PaymentStamp.deserializeBinaryFromReader);
      msg.addPaymentlist(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedat(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.students.UpdateLessonListResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.students.UpdateLessonListResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.students.UpdateLessonListResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.students.UpdateLessonListResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLessonlistList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.students.LessonStamp.serializeBinaryToWriter
    );
  }
  f = message.getPaymentlistList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.students.PaymentStamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedat();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * repeated LessonStamp lessonList = 1;
 * @return {!Array<!proto.students.LessonStamp>}
 */
proto.students.UpdateLessonListResponse.prototype.getLessonlistList = function() {
  return /** @type{!Array<!proto.students.LessonStamp>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.students.LessonStamp, 1));
};


/**
 * @param {!Array<!proto.students.LessonStamp>} value
 * @return {!proto.students.UpdateLessonListResponse} returns this
*/
proto.students.UpdateLessonListResponse.prototype.setLessonlistList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.students.LessonStamp=} opt_value
 * @param {number=} opt_index
 * @return {!proto.students.LessonStamp}
 */
proto.students.UpdateLessonListResponse.prototype.addLessonlist = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.students.LessonStamp, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.students.UpdateLessonListResponse} returns this
 */
proto.students.UpdateLessonListResponse.prototype.clearLessonlistList = function() {
  return this.setLessonlistList([]);
};


/**
 * repeated PaymentStamp paymentList = 2;
 * @return {!Array<!proto.students.PaymentStamp>}
 */
proto.students.UpdateLessonListResponse.prototype.getPaymentlistList = function() {
  return /** @type{!Array<!proto.students.PaymentStamp>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.students.PaymentStamp, 2));
};


/**
 * @param {!Array<!proto.students.PaymentStamp>} value
 * @return {!proto.students.UpdateLessonListResponse} returns this
*/
proto.students.UpdateLessonListResponse.prototype.setPaymentlistList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.students.PaymentStamp=} opt_value
 * @param {number=} opt_index
 * @return {!proto.students.PaymentStamp}
 */
proto.students.UpdateLessonListResponse.prototype.addPaymentlist = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.students.PaymentStamp, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.students.UpdateLessonListResponse} returns this
 */
proto.students.UpdateLessonListResponse.prototype.clearPaymentlistList = function() {
  return this.setPaymentlistList([]);
};


/**
 * optional google.protobuf.Timestamp updatedAt = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.students.UpdateLessonListResponse.prototype.getUpdatedat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.students.UpdateLessonListResponse} returns this
*/
proto.students.UpdateLessonListResponse.prototype.setUpdatedat = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.students.UpdateLessonListResponse} returns this
 */
proto.students.UpdateLessonListResponse.prototype.clearUpdatedat = function() {
  return this.setUpdatedat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.students.UpdateLessonListResponse.prototype.hasUpdatedat = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.students.AddLessonRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.students.AddLessonRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.students.AddLessonRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.students.AddLessonRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    studentid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    idempotencykey: jspb.Message.getFieldWithDefault(msg, 2, ""),
    location: jspb.Message.getFieldWithDefault(msg, 3, ""),
    startDate: (f = msg.getStartDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    endDate: (f = msg.getEndDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    reminderduration: jspb.Message.getFieldWithDefault(msg, 6, 0),
    paymentinfo: (f = msg.getPaymentinfo()) && proto.students.CreatePaymentInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.students.AddLessonRequest}
 */
proto.students.AddLessonRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.students.AddLessonRequest;
  return proto.students.AddLessonRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.students.AddLessonRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.students.AddLessonRequest}
 */
proto.students.AddLessonRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStudentid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setIdempotencykey(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocation(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStartDate(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEndDate(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setReminderduration(value);
      break;
    case 7:
      var value = new proto.students.CreatePaymentInfo;
      reader.readMessage(value,proto.students.CreatePaymentInfo.deserializeBinaryFromReader);
      msg.setPaymentinfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.students.AddLessonRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.students.AddLessonRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.students.AddLessonRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.students.AddLessonRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStudentid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIdempotencykey();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLocation();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getStartDate();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEndDate();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getReminderduration();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getPaymentinfo();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.students.CreatePaymentInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional string studentID = 1;
 * @return {string}
 */
proto.students.AddLessonRequest.prototype.getStudentid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.students.AddLessonRequest} returns this
 */
proto.students.AddLessonRequest.prototype.setStudentid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string idempotencyKey = 2;
 * @return {string}
 */
proto.students.AddLessonRequest.prototype.getIdempotencykey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.students.AddLessonRequest} returns this
 */
proto.students.AddLessonRequest.prototype.setIdempotencykey = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string location = 3;
 * @return {string}
 */
proto.students.AddLessonRequest.prototype.getLocation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.students.AddLessonRequest} returns this
 */
proto.students.AddLessonRequest.prototype.setLocation = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional google.protobuf.Timestamp start_date = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.students.AddLessonRequest.prototype.getStartDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.students.AddLessonRequest} returns this
*/
proto.students.AddLessonRequest.prototype.setStartDate = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.students.AddLessonRequest} returns this
 */
proto.students.AddLessonRequest.prototype.clearStartDate = function() {
  return this.setStartDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.students.AddLessonRequest.prototype.hasStartDate = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.Timestamp end_date = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.students.AddLessonRequest.prototype.getEndDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.students.AddLessonRequest} returns this
*/
proto.students.AddLessonRequest.prototype.setEndDate = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.students.AddLessonRequest} returns this
 */
proto.students.AddLessonRequest.prototype.clearEndDate = function() {
  return this.setEndDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.students.AddLessonRequest.prototype.hasEndDate = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional int32 reminderDuration = 6;
 * @return {number}
 */
proto.students.AddLessonRequest.prototype.getReminderduration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.students.AddLessonRequest} returns this
 */
proto.students.AddLessonRequest.prototype.setReminderduration = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional CreatePaymentInfo paymentInfo = 7;
 * @return {?proto.students.CreatePaymentInfo}
 */
proto.students.AddLessonRequest.prototype.getPaymentinfo = function() {
  return /** @type{?proto.students.CreatePaymentInfo} */ (
    jspb.Message.getWrapperField(this, proto.students.CreatePaymentInfo, 7));
};


/**
 * @param {?proto.students.CreatePaymentInfo|undefined} value
 * @return {!proto.students.AddLessonRequest} returns this
*/
proto.students.AddLessonRequest.prototype.setPaymentinfo = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.students.AddLessonRequest} returns this
 */
proto.students.AddLessonRequest.prototype.clearPaymentinfo = function() {
  return this.setPaymentinfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.students.AddLessonRequest.prototype.hasPaymentinfo = function() {
  return jspb.Message.getField(this, 7) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.students.BulkAddLessonRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.students.BulkAddLessonRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.students.BulkAddLessonRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.students.BulkAddLessonRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.students.BulkAddLessonRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestlistList: jspb.Message.toObjectList(msg.getRequestlistList(),
    proto.students.AddLessonRequest.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.students.BulkAddLessonRequest}
 */
proto.students.BulkAddLessonRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.students.BulkAddLessonRequest;
  return proto.students.BulkAddLessonRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.students.BulkAddLessonRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.students.BulkAddLessonRequest}
 */
proto.students.BulkAddLessonRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.students.AddLessonRequest;
      reader.readMessage(value,proto.students.AddLessonRequest.deserializeBinaryFromReader);
      msg.addRequestlist(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.students.BulkAddLessonRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.students.BulkAddLessonRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.students.BulkAddLessonRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.students.BulkAddLessonRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestlistList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.students.AddLessonRequest.serializeBinaryToWriter
    );
  }
};


/**
 * repeated AddLessonRequest requestList = 1;
 * @return {!Array<!proto.students.AddLessonRequest>}
 */
proto.students.BulkAddLessonRequest.prototype.getRequestlistList = function() {
  return /** @type{!Array<!proto.students.AddLessonRequest>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.students.AddLessonRequest, 1));
};


/**
 * @param {!Array<!proto.students.AddLessonRequest>} value
 * @return {!proto.students.BulkAddLessonRequest} returns this
*/
proto.students.BulkAddLessonRequest.prototype.setRequestlistList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.students.AddLessonRequest=} opt_value
 * @param {number=} opt_index
 * @return {!proto.students.AddLessonRequest}
 */
proto.students.BulkAddLessonRequest.prototype.addRequestlist = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.students.AddLessonRequest, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.students.BulkAddLessonRequest} returns this
 */
proto.students.BulkAddLessonRequest.prototype.clearRequestlistList = function() {
  return this.setRequestlistList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.students.BulkAddLessonResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.students.BulkAddLessonResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.students.BulkAddLessonResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.students.BulkAddLessonResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.students.BulkAddLessonResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    responselistList: jspb.Message.toObjectList(msg.getResponselistList(),
    proto.students.LessonWithPayment.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.students.BulkAddLessonResponse}
 */
proto.students.BulkAddLessonResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.students.BulkAddLessonResponse;
  return proto.students.BulkAddLessonResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.students.BulkAddLessonResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.students.BulkAddLessonResponse}
 */
proto.students.BulkAddLessonResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.students.LessonWithPayment;
      reader.readMessage(value,proto.students.LessonWithPayment.deserializeBinaryFromReader);
      msg.addResponselist(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.students.BulkAddLessonResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.students.BulkAddLessonResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.students.BulkAddLessonResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.students.BulkAddLessonResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResponselistList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.students.LessonWithPayment.serializeBinaryToWriter
    );
  }
};


/**
 * repeated LessonWithPayment responseList = 1;
 * @return {!Array<!proto.students.LessonWithPayment>}
 */
proto.students.BulkAddLessonResponse.prototype.getResponselistList = function() {
  return /** @type{!Array<!proto.students.LessonWithPayment>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.students.LessonWithPayment, 1));
};


/**
 * @param {!Array<!proto.students.LessonWithPayment>} value
 * @return {!proto.students.BulkAddLessonResponse} returns this
*/
proto.students.BulkAddLessonResponse.prototype.setResponselistList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.students.LessonWithPayment=} opt_value
 * @param {number=} opt_index
 * @return {!proto.students.LessonWithPayment}
 */
proto.students.BulkAddLessonResponse.prototype.addResponselist = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.students.LessonWithPayment, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.students.BulkAddLessonResponse} returns this
 */
proto.students.BulkAddLessonResponse.prototype.clearResponselistList = function() {
  return this.setResponselistList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.students.LessonList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.students.LessonList.prototype.toObject = function(opt_includeInstance) {
  return proto.students.LessonList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.students.LessonList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.students.LessonList.toObject = function(includeInstance, msg) {
  var f, obj = {
    lessonlistList: jspb.Message.toObjectList(msg.getLessonlistList(),
    proto.students.Lesson.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.students.LessonList}
 */
proto.students.LessonList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.students.LessonList;
  return proto.students.LessonList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.students.LessonList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.students.LessonList}
 */
proto.students.LessonList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.students.Lesson;
      reader.readMessage(value,proto.students.Lesson.deserializeBinaryFromReader);
      msg.addLessonlist(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.students.LessonList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.students.LessonList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.students.LessonList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.students.LessonList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLessonlistList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.students.Lesson.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Lesson lessonList = 1;
 * @return {!Array<!proto.students.Lesson>}
 */
proto.students.LessonList.prototype.getLessonlistList = function() {
  return /** @type{!Array<!proto.students.Lesson>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.students.Lesson, 1));
};


/**
 * @param {!Array<!proto.students.Lesson>} value
 * @return {!proto.students.LessonList} returns this
*/
proto.students.LessonList.prototype.setLessonlistList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.students.Lesson=} opt_value
 * @param {number=} opt_index
 * @return {!proto.students.Lesson}
 */
proto.students.LessonList.prototype.addLessonlist = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.students.Lesson, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.students.LessonList} returns this
 */
proto.students.LessonList.prototype.clearLessonlistList = function() {
  return this.setLessonlistList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.students.EditLessonRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.students.EditLessonRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.students.EditLessonRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.students.EditLessonRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    location: jspb.Message.getFieldWithDefault(msg, 2, ""),
    startDate: (f = msg.getStartDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    endDate: (f = msg.getEndDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    reminderduration: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.students.EditLessonRequest}
 */
proto.students.EditLessonRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.students.EditLessonRequest;
  return proto.students.EditLessonRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.students.EditLessonRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.students.EditLessonRequest}
 */
proto.students.EditLessonRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocation(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStartDate(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEndDate(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setReminderduration(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.students.EditLessonRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.students.EditLessonRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.students.EditLessonRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.students.EditLessonRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLocation();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStartDate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEndDate();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getReminderduration();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.students.EditLessonRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.students.EditLessonRequest} returns this
 */
proto.students.EditLessonRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string location = 2;
 * @return {string}
 */
proto.students.EditLessonRequest.prototype.getLocation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.students.EditLessonRequest} returns this
 */
proto.students.EditLessonRequest.prototype.setLocation = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp start_date = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.students.EditLessonRequest.prototype.getStartDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.students.EditLessonRequest} returns this
*/
proto.students.EditLessonRequest.prototype.setStartDate = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.students.EditLessonRequest} returns this
 */
proto.students.EditLessonRequest.prototype.clearStartDate = function() {
  return this.setStartDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.students.EditLessonRequest.prototype.hasStartDate = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Timestamp end_date = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.students.EditLessonRequest.prototype.getEndDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.students.EditLessonRequest} returns this
*/
proto.students.EditLessonRequest.prototype.setEndDate = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.students.EditLessonRequest} returns this
 */
proto.students.EditLessonRequest.prototype.clearEndDate = function() {
  return this.setEndDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.students.EditLessonRequest.prototype.hasEndDate = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional int32 reminderDuration = 5;
 * @return {number}
 */
proto.students.EditLessonRequest.prototype.getReminderduration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.students.EditLessonRequest} returns this
 */
proto.students.EditLessonRequest.prototype.setReminderduration = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.students.BulkEditLessonRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.students.BulkEditLessonRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.students.BulkEditLessonRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.students.BulkEditLessonRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.students.BulkEditLessonRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestlistList: jspb.Message.toObjectList(msg.getRequestlistList(),
    proto.students.EditLessonRequest.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.students.BulkEditLessonRequest}
 */
proto.students.BulkEditLessonRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.students.BulkEditLessonRequest;
  return proto.students.BulkEditLessonRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.students.BulkEditLessonRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.students.BulkEditLessonRequest}
 */
proto.students.BulkEditLessonRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.students.EditLessonRequest;
      reader.readMessage(value,proto.students.EditLessonRequest.deserializeBinaryFromReader);
      msg.addRequestlist(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.students.BulkEditLessonRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.students.BulkEditLessonRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.students.BulkEditLessonRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.students.BulkEditLessonRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestlistList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.students.EditLessonRequest.serializeBinaryToWriter
    );
  }
};


/**
 * repeated EditLessonRequest requestList = 1;
 * @return {!Array<!proto.students.EditLessonRequest>}
 */
proto.students.BulkEditLessonRequest.prototype.getRequestlistList = function() {
  return /** @type{!Array<!proto.students.EditLessonRequest>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.students.EditLessonRequest, 1));
};


/**
 * @param {!Array<!proto.students.EditLessonRequest>} value
 * @return {!proto.students.BulkEditLessonRequest} returns this
*/
proto.students.BulkEditLessonRequest.prototype.setRequestlistList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.students.EditLessonRequest=} opt_value
 * @param {number=} opt_index
 * @return {!proto.students.EditLessonRequest}
 */
proto.students.BulkEditLessonRequest.prototype.addRequestlist = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.students.EditLessonRequest, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.students.BulkEditLessonRequest} returns this
 */
proto.students.BulkEditLessonRequest.prototype.clearRequestlistList = function() {
  return this.setRequestlistList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.students.RemoveLessonRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.students.RemoveLessonRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.students.RemoveLessonRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.students.RemoveLessonRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.students.RemoveLessonRequest}
 */
proto.students.RemoveLessonRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.students.RemoveLessonRequest;
  return proto.students.RemoveLessonRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.students.RemoveLessonRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.students.RemoveLessonRequest}
 */
proto.students.RemoveLessonRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.students.RemoveLessonRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.students.RemoveLessonRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.students.RemoveLessonRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.students.RemoveLessonRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.students.RemoveLessonRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.students.RemoveLessonRequest} returns this
 */
proto.students.RemoveLessonRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.students.BulkRemoveLessonRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.students.BulkRemoveLessonRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.students.BulkRemoveLessonRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.students.BulkRemoveLessonRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.students.BulkRemoveLessonRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    idlistList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.students.BulkRemoveLessonRequest}
 */
proto.students.BulkRemoveLessonRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.students.BulkRemoveLessonRequest;
  return proto.students.BulkRemoveLessonRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.students.BulkRemoveLessonRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.students.BulkRemoveLessonRequest}
 */
proto.students.BulkRemoveLessonRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addIdlist(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.students.BulkRemoveLessonRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.students.BulkRemoveLessonRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.students.BulkRemoveLessonRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.students.BulkRemoveLessonRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdlistList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string idList = 1;
 * @return {!Array<string>}
 */
proto.students.BulkRemoveLessonRequest.prototype.getIdlistList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.students.BulkRemoveLessonRequest} returns this
 */
proto.students.BulkRemoveLessonRequest.prototype.setIdlistList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.students.BulkRemoveLessonRequest} returns this
 */
proto.students.BulkRemoveLessonRequest.prototype.addIdlist = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.students.BulkRemoveLessonRequest} returns this
 */
proto.students.BulkRemoveLessonRequest.prototype.clearIdlistList = function() {
  return this.setIdlistList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.students.LessonStamp.prototype.toObject = function(opt_includeInstance) {
  return proto.students.LessonStamp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.students.LessonStamp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.students.LessonStamp.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 2, ""),
    updatedat: (f = msg.getUpdatedat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.students.LessonStamp}
 */
proto.students.LessonStamp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.students.LessonStamp;
  return proto.students.LessonStamp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.students.LessonStamp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.students.LessonStamp}
 */
proto.students.LessonStamp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedat(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.students.LessonStamp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.students.LessonStamp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.students.LessonStamp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.students.LessonStamp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUpdatedat();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 2;
 * @return {string}
 */
proto.students.LessonStamp.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.students.LessonStamp} returns this
 */
proto.students.LessonStamp.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp updatedAt = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.students.LessonStamp.prototype.getUpdatedat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.students.LessonStamp} returns this
*/
proto.students.LessonStamp.prototype.setUpdatedat = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.students.LessonStamp} returns this
 */
proto.students.LessonStamp.prototype.clearUpdatedat = function() {
  return this.setUpdatedat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.students.LessonStamp.prototype.hasUpdatedat = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.students.GetLessonListByIdsRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.students.GetLessonListByIdsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.students.GetLessonListByIdsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.students.GetLessonListByIdsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.students.GetLessonListByIdsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    idlistList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.students.GetLessonListByIdsRequest}
 */
proto.students.GetLessonListByIdsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.students.GetLessonListByIdsRequest;
  return proto.students.GetLessonListByIdsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.students.GetLessonListByIdsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.students.GetLessonListByIdsRequest}
 */
proto.students.GetLessonListByIdsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addIdlist(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.students.GetLessonListByIdsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.students.GetLessonListByIdsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.students.GetLessonListByIdsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.students.GetLessonListByIdsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdlistList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string idList = 1;
 * @return {!Array<string>}
 */
proto.students.GetLessonListByIdsRequest.prototype.getIdlistList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.students.GetLessonListByIdsRequest} returns this
 */
proto.students.GetLessonListByIdsRequest.prototype.setIdlistList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.students.GetLessonListByIdsRequest} returns this
 */
proto.students.GetLessonListByIdsRequest.prototype.addIdlist = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.students.GetLessonListByIdsRequest} returns this
 */
proto.students.GetLessonListByIdsRequest.prototype.clearIdlistList = function() {
  return this.setIdlistList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.students.LessonListResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.students.LessonListResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.students.LessonListResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.students.LessonListResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.students.LessonListResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    lessonlistList: jspb.Message.toObjectList(msg.getLessonlistList(),
    proto.students.Lesson.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.students.LessonListResponse}
 */
proto.students.LessonListResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.students.LessonListResponse;
  return proto.students.LessonListResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.students.LessonListResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.students.LessonListResponse}
 */
proto.students.LessonListResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.students.Lesson;
      reader.readMessage(value,proto.students.Lesson.deserializeBinaryFromReader);
      msg.addLessonlist(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.students.LessonListResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.students.LessonListResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.students.LessonListResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.students.LessonListResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLessonlistList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.students.Lesson.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Lesson lessonList = 1;
 * @return {!Array<!proto.students.Lesson>}
 */
proto.students.LessonListResponse.prototype.getLessonlistList = function() {
  return /** @type{!Array<!proto.students.Lesson>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.students.Lesson, 1));
};


/**
 * @param {!Array<!proto.students.Lesson>} value
 * @return {!proto.students.LessonListResponse} returns this
*/
proto.students.LessonListResponse.prototype.setLessonlistList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.students.Lesson=} opt_value
 * @param {number=} opt_index
 * @return {!proto.students.Lesson}
 */
proto.students.LessonListResponse.prototype.addLessonlist = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.students.Lesson, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.students.LessonListResponse} returns this
 */
proto.students.LessonListResponse.prototype.clearLessonlistList = function() {
  return this.setLessonlistList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.students.GetPaymentsByIdListRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.students.GetPaymentsByIdListRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.students.GetPaymentsByIdListRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.students.GetPaymentsByIdListRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.students.GetPaymentsByIdListRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    idlistList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.students.GetPaymentsByIdListRequest}
 */
proto.students.GetPaymentsByIdListRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.students.GetPaymentsByIdListRequest;
  return proto.students.GetPaymentsByIdListRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.students.GetPaymentsByIdListRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.students.GetPaymentsByIdListRequest}
 */
proto.students.GetPaymentsByIdListRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addIdlist(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.students.GetPaymentsByIdListRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.students.GetPaymentsByIdListRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.students.GetPaymentsByIdListRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.students.GetPaymentsByIdListRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdlistList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string idList = 1;
 * @return {!Array<string>}
 */
proto.students.GetPaymentsByIdListRequest.prototype.getIdlistList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.students.GetPaymentsByIdListRequest} returns this
 */
proto.students.GetPaymentsByIdListRequest.prototype.setIdlistList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.students.GetPaymentsByIdListRequest} returns this
 */
proto.students.GetPaymentsByIdListRequest.prototype.addIdlist = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.students.GetPaymentsByIdListRequest} returns this
 */
proto.students.GetPaymentsByIdListRequest.prototype.clearIdlistList = function() {
  return this.setIdlistList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.students.GetPaymentsByIdListResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.students.GetPaymentsByIdListResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.students.GetPaymentsByIdListResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.students.GetPaymentsByIdListResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.students.GetPaymentsByIdListResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    paymentlistList: jspb.Message.toObjectList(msg.getPaymentlistList(),
    proto.students.Payment.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.students.GetPaymentsByIdListResponse}
 */
proto.students.GetPaymentsByIdListResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.students.GetPaymentsByIdListResponse;
  return proto.students.GetPaymentsByIdListResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.students.GetPaymentsByIdListResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.students.GetPaymentsByIdListResponse}
 */
proto.students.GetPaymentsByIdListResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.students.Payment;
      reader.readMessage(value,proto.students.Payment.deserializeBinaryFromReader);
      msg.addPaymentlist(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.students.GetPaymentsByIdListResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.students.GetPaymentsByIdListResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.students.GetPaymentsByIdListResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.students.GetPaymentsByIdListResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPaymentlistList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.students.Payment.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Payment paymentList = 1;
 * @return {!Array<!proto.students.Payment>}
 */
proto.students.GetPaymentsByIdListResponse.prototype.getPaymentlistList = function() {
  return /** @type{!Array<!proto.students.Payment>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.students.Payment, 1));
};


/**
 * @param {!Array<!proto.students.Payment>} value
 * @return {!proto.students.GetPaymentsByIdListResponse} returns this
*/
proto.students.GetPaymentsByIdListResponse.prototype.setPaymentlistList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.students.Payment=} opt_value
 * @param {number=} opt_index
 * @return {!proto.students.Payment}
 */
proto.students.GetPaymentsByIdListResponse.prototype.addPaymentlist = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.students.Payment, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.students.GetPaymentsByIdListResponse} returns this
 */
proto.students.GetPaymentsByIdListResponse.prototype.clearPaymentlistList = function() {
  return this.setPaymentlistList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.students.Anchor.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.students.Anchor.ValueCase = {
  VALUE_NOT_SET: 0,
  FROMANCHOR: 1,
  FROMSCRATCH: 2
};

/**
 * @return {proto.students.Anchor.ValueCase}
 */
proto.students.Anchor.prototype.getValueCase = function() {
  return /** @type {proto.students.Anchor.ValueCase} */(jspb.Message.computeOneofCase(this, proto.students.Anchor.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.students.Anchor.prototype.toObject = function(opt_includeInstance) {
  return proto.students.Anchor.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.students.Anchor} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.students.Anchor.toObject = function(includeInstance, msg) {
  var f, obj = {
    fromanchor: (f = msg.getFromanchor()) && proto.students.LessonAnchor.toObject(includeInstance, f),
    fromscratch: (f = msg.getFromscratch()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.students.Anchor}
 */
proto.students.Anchor.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.students.Anchor;
  return proto.students.Anchor.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.students.Anchor} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.students.Anchor}
 */
proto.students.Anchor.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.students.LessonAnchor;
      reader.readMessage(value,proto.students.LessonAnchor.deserializeBinaryFromReader);
      msg.setFromanchor(value);
      break;
    case 2:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setFromscratch(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.students.Anchor.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.students.Anchor.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.students.Anchor} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.students.Anchor.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFromanchor();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.students.LessonAnchor.serializeBinaryToWriter
    );
  }
  f = message.getFromscratch();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
};


/**
 * optional LessonAnchor fromAnchor = 1;
 * @return {?proto.students.LessonAnchor}
 */
proto.students.Anchor.prototype.getFromanchor = function() {
  return /** @type{?proto.students.LessonAnchor} */ (
    jspb.Message.getWrapperField(this, proto.students.LessonAnchor, 1));
};


/**
 * @param {?proto.students.LessonAnchor|undefined} value
 * @return {!proto.students.Anchor} returns this
*/
proto.students.Anchor.prototype.setFromanchor = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.students.Anchor.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.students.Anchor} returns this
 */
proto.students.Anchor.prototype.clearFromanchor = function() {
  return this.setFromanchor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.students.Anchor.prototype.hasFromanchor = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Empty fromScratch = 2;
 * @return {?proto.google.protobuf.Empty}
 */
proto.students.Anchor.prototype.getFromscratch = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 2));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.students.Anchor} returns this
*/
proto.students.Anchor.prototype.setFromscratch = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.students.Anchor.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.students.Anchor} returns this
 */
proto.students.Anchor.prototype.clearFromscratch = function() {
  return this.setFromscratch(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.students.Anchor.prototype.hasFromscratch = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.students.LessonAnchor.prototype.toObject = function(opt_includeInstance) {
  return proto.students.LessonAnchor.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.students.LessonAnchor} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.students.LessonAnchor.toObject = function(includeInstance, msg) {
  var f, obj = {
    startdate: (f = msg.getStartdate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    createdat: (f = msg.getCreatedat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.students.LessonAnchor}
 */
proto.students.LessonAnchor.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.students.LessonAnchor;
  return proto.students.LessonAnchor.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.students.LessonAnchor} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.students.LessonAnchor}
 */
proto.students.LessonAnchor.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStartdate(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedat(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.students.LessonAnchor.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.students.LessonAnchor.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.students.LessonAnchor} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.students.LessonAnchor.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStartdate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCreatedat();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp startDate = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.students.LessonAnchor.prototype.getStartdate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.students.LessonAnchor} returns this
*/
proto.students.LessonAnchor.prototype.setStartdate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.students.LessonAnchor} returns this
 */
proto.students.LessonAnchor.prototype.clearStartdate = function() {
  return this.setStartdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.students.LessonAnchor.prototype.hasStartdate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Timestamp createdAt = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.students.LessonAnchor.prototype.getCreatedat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.students.LessonAnchor} returns this
*/
proto.students.LessonAnchor.prototype.setCreatedat = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.students.LessonAnchor} returns this
 */
proto.students.LessonAnchor.prototype.clearCreatedat = function() {
  return this.setCreatedat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.students.LessonAnchor.prototype.hasCreatedat = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.students.PaymentStamp.prototype.toObject = function(opt_includeInstance) {
  return proto.students.PaymentStamp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.students.PaymentStamp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.students.PaymentStamp.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    updatedat: (f = msg.getUpdatedat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.students.PaymentStamp}
 */
proto.students.PaymentStamp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.students.PaymentStamp;
  return proto.students.PaymentStamp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.students.PaymentStamp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.students.PaymentStamp}
 */
proto.students.PaymentStamp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedat(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.students.PaymentStamp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.students.PaymentStamp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.students.PaymentStamp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.students.PaymentStamp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUpdatedat();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.students.PaymentStamp.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.students.PaymentStamp} returns this
 */
proto.students.PaymentStamp.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp updatedAt = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.students.PaymentStamp.prototype.getUpdatedat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.students.PaymentStamp} returns this
*/
proto.students.PaymentStamp.prototype.setUpdatedat = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.students.PaymentStamp} returns this
 */
proto.students.PaymentStamp.prototype.clearUpdatedat = function() {
  return this.setUpdatedat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.students.PaymentStamp.prototype.hasUpdatedat = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.students.RemovePaymentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.students.RemovePaymentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.students.RemovePaymentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.students.RemovePaymentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.students.RemovePaymentRequest}
 */
proto.students.RemovePaymentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.students.RemovePaymentRequest;
  return proto.students.RemovePaymentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.students.RemovePaymentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.students.RemovePaymentRequest}
 */
proto.students.RemovePaymentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.students.RemovePaymentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.students.RemovePaymentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.students.RemovePaymentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.students.RemovePaymentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.students.RemovePaymentRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.students.RemovePaymentRequest} returns this
 */
proto.students.RemovePaymentRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.students.GetPaymentListRequest.oneofGroups_ = [[2]];

/**
 * @enum {number}
 */
proto.students.GetPaymentListRequest.SortCase = {
  SORT_NOT_SET: 0,
  BYDATE: 2
};

/**
 * @return {proto.students.GetPaymentListRequest.SortCase}
 */
proto.students.GetPaymentListRequest.prototype.getSortCase = function() {
  return /** @type {proto.students.GetPaymentListRequest.SortCase} */(jspb.Message.computeOneofCase(this, proto.students.GetPaymentListRequest.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.students.GetPaymentListRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.students.GetPaymentListRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.students.GetPaymentListRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.students.GetPaymentListRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    limit: jspb.Message.getFieldWithDefault(msg, 1, 0),
    bydate: (f = msg.getBydate()) && proto.students.DateAnchor.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.students.GetPaymentListRequest}
 */
proto.students.GetPaymentListRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.students.GetPaymentListRequest;
  return proto.students.GetPaymentListRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.students.GetPaymentListRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.students.GetPaymentListRequest}
 */
proto.students.GetPaymentListRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLimit(value);
      break;
    case 2:
      var value = new proto.students.DateAnchor;
      reader.readMessage(value,proto.students.DateAnchor.deserializeBinaryFromReader);
      msg.setBydate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.students.GetPaymentListRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.students.GetPaymentListRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.students.GetPaymentListRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.students.GetPaymentListRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getBydate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.students.DateAnchor.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 limit = 1;
 * @return {number}
 */
proto.students.GetPaymentListRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.students.GetPaymentListRequest} returns this
 */
proto.students.GetPaymentListRequest.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional DateAnchor byDate = 2;
 * @return {?proto.students.DateAnchor}
 */
proto.students.GetPaymentListRequest.prototype.getBydate = function() {
  return /** @type{?proto.students.DateAnchor} */ (
    jspb.Message.getWrapperField(this, proto.students.DateAnchor, 2));
};


/**
 * @param {?proto.students.DateAnchor|undefined} value
 * @return {!proto.students.GetPaymentListRequest} returns this
*/
proto.students.GetPaymentListRequest.prototype.setBydate = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.students.GetPaymentListRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.students.GetPaymentListRequest} returns this
 */
proto.students.GetPaymentListRequest.prototype.clearBydate = function() {
  return this.setBydate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.students.GetPaymentListRequest.prototype.hasBydate = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.students.DateAnchor.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.students.DateAnchor.ValueCase = {
  VALUE_NOT_SET: 0,
  FROMANCHOR: 1,
  FROMSCRATCH: 2
};

/**
 * @return {proto.students.DateAnchor.ValueCase}
 */
proto.students.DateAnchor.prototype.getValueCase = function() {
  return /** @type {proto.students.DateAnchor.ValueCase} */(jspb.Message.computeOneofCase(this, proto.students.DateAnchor.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.students.DateAnchor.prototype.toObject = function(opt_includeInstance) {
  return proto.students.DateAnchor.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.students.DateAnchor} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.students.DateAnchor.toObject = function(includeInstance, msg) {
  var f, obj = {
    fromanchor: (f = msg.getFromanchor()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    fromscratch: (f = msg.getFromscratch()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.students.DateAnchor}
 */
proto.students.DateAnchor.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.students.DateAnchor;
  return proto.students.DateAnchor.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.students.DateAnchor} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.students.DateAnchor}
 */
proto.students.DateAnchor.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setFromanchor(value);
      break;
    case 2:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setFromscratch(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.students.DateAnchor.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.students.DateAnchor.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.students.DateAnchor} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.students.DateAnchor.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFromanchor();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getFromscratch();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp fromAnchor = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.students.DateAnchor.prototype.getFromanchor = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.students.DateAnchor} returns this
*/
proto.students.DateAnchor.prototype.setFromanchor = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.students.DateAnchor.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.students.DateAnchor} returns this
 */
proto.students.DateAnchor.prototype.clearFromanchor = function() {
  return this.setFromanchor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.students.DateAnchor.prototype.hasFromanchor = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Empty fromScratch = 2;
 * @return {?proto.google.protobuf.Empty}
 */
proto.students.DateAnchor.prototype.getFromscratch = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 2));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.students.DateAnchor} returns this
*/
proto.students.DateAnchor.prototype.setFromscratch = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.students.DateAnchor.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.students.DateAnchor} returns this
 */
proto.students.DateAnchor.prototype.clearFromscratch = function() {
  return this.setFromscratch(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.students.DateAnchor.prototype.hasFromscratch = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.students.GetPaymentListResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.students.GetPaymentListResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.students.GetPaymentListResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.students.GetPaymentListResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.students.GetPaymentListResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    lessonsList: jspb.Message.toObjectList(msg.getLessonsList(),
    proto.students.LessonViewModel.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.students.GetPaymentListResponse}
 */
proto.students.GetPaymentListResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.students.GetPaymentListResponse;
  return proto.students.GetPaymentListResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.students.GetPaymentListResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.students.GetPaymentListResponse}
 */
proto.students.GetPaymentListResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.students.LessonViewModel;
      reader.readMessage(value,proto.students.LessonViewModel.deserializeBinaryFromReader);
      msg.addLessons(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.students.GetPaymentListResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.students.GetPaymentListResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.students.GetPaymentListResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.students.GetPaymentListResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLessonsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.students.LessonViewModel.serializeBinaryToWriter
    );
  }
};


/**
 * repeated LessonViewModel lessons = 1;
 * @return {!Array<!proto.students.LessonViewModel>}
 */
proto.students.GetPaymentListResponse.prototype.getLessonsList = function() {
  return /** @type{!Array<!proto.students.LessonViewModel>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.students.LessonViewModel, 1));
};


/**
 * @param {!Array<!proto.students.LessonViewModel>} value
 * @return {!proto.students.GetPaymentListResponse} returns this
*/
proto.students.GetPaymentListResponse.prototype.setLessonsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.students.LessonViewModel=} opt_value
 * @param {number=} opt_index
 * @return {!proto.students.LessonViewModel}
 */
proto.students.GetPaymentListResponse.prototype.addLessons = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.students.LessonViewModel, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.students.GetPaymentListResponse} returns this
 */
proto.students.GetPaymentListResponse.prototype.clearLessonsList = function() {
  return this.setLessonsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.students.CreatePaymentInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.students.CreatePaymentInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.students.CreatePaymentInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.students.CreatePaymentInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    cost: jspb.Message.getFieldWithDefault(msg, 1, 0),
    valute: jspb.Message.getFieldWithDefault(msg, 2, 0),
    notificationpreference: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.students.CreatePaymentInfo}
 */
proto.students.CreatePaymentInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.students.CreatePaymentInfo;
  return proto.students.CreatePaymentInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.students.CreatePaymentInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.students.CreatePaymentInfo}
 */
proto.students.CreatePaymentInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCost(value);
      break;
    case 2:
      var value = /** @type {!proto.students.Payment.PaymentValute} */ (reader.readEnum());
      msg.setValute(value);
      break;
    case 3:
      var value = /** @type {!proto.students.Payment.NotificationPreference} */ (reader.readEnum());
      msg.setNotificationpreference(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.students.CreatePaymentInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.students.CreatePaymentInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.students.CreatePaymentInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.students.CreatePaymentInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCost();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getValute();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getNotificationpreference();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional int64 cost = 1;
 * @return {number}
 */
proto.students.CreatePaymentInfo.prototype.getCost = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.students.CreatePaymentInfo} returns this
 */
proto.students.CreatePaymentInfo.prototype.setCost = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional Payment.PaymentValute valute = 2;
 * @return {!proto.students.Payment.PaymentValute}
 */
proto.students.CreatePaymentInfo.prototype.getValute = function() {
  return /** @type {!proto.students.Payment.PaymentValute} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.students.Payment.PaymentValute} value
 * @return {!proto.students.CreatePaymentInfo} returns this
 */
proto.students.CreatePaymentInfo.prototype.setValute = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional Payment.NotificationPreference notificationPreference = 3;
 * @return {!proto.students.Payment.NotificationPreference}
 */
proto.students.CreatePaymentInfo.prototype.getNotificationpreference = function() {
  return /** @type {!proto.students.Payment.NotificationPreference} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.students.Payment.NotificationPreference} value
 * @return {!proto.students.CreatePaymentInfo} returns this
 */
proto.students.CreatePaymentInfo.prototype.setNotificationpreference = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.students.InitiateLessonPaymentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.students.InitiateLessonPaymentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.students.InitiateLessonPaymentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.students.InitiateLessonPaymentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    paymentid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.students.InitiateLessonPaymentRequest}
 */
proto.students.InitiateLessonPaymentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.students.InitiateLessonPaymentRequest;
  return proto.students.InitiateLessonPaymentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.students.InitiateLessonPaymentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.students.InitiateLessonPaymentRequest}
 */
proto.students.InitiateLessonPaymentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaymentid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.students.InitiateLessonPaymentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.students.InitiateLessonPaymentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.students.InitiateLessonPaymentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.students.InitiateLessonPaymentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPaymentid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string paymentId = 1;
 * @return {string}
 */
proto.students.InitiateLessonPaymentRequest.prototype.getPaymentid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.students.InitiateLessonPaymentRequest} returns this
 */
proto.students.InitiateLessonPaymentRequest.prototype.setPaymentid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.students.EditPaymentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.students.EditPaymentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.students.EditPaymentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.students.EditPaymentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    cost: jspb.Message.getFieldWithDefault(msg, 2, 0),
    valute: jspb.Message.getFieldWithDefault(msg, 3, 0),
    notificationpreference: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.students.EditPaymentRequest}
 */
proto.students.EditPaymentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.students.EditPaymentRequest;
  return proto.students.EditPaymentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.students.EditPaymentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.students.EditPaymentRequest}
 */
proto.students.EditPaymentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCost(value);
      break;
    case 3:
      var value = /** @type {!proto.students.Payment.PaymentValute} */ (reader.readEnum());
      msg.setValute(value);
      break;
    case 4:
      var value = /** @type {!proto.students.Payment.NotificationPreference} */ (reader.readEnum());
      msg.setNotificationpreference(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.students.EditPaymentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.students.EditPaymentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.students.EditPaymentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.students.EditPaymentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCost();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getValute();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getNotificationpreference();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.students.EditPaymentRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.students.EditPaymentRequest} returns this
 */
proto.students.EditPaymentRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 cost = 2;
 * @return {number}
 */
proto.students.EditPaymentRequest.prototype.getCost = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.students.EditPaymentRequest} returns this
 */
proto.students.EditPaymentRequest.prototype.setCost = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional Payment.PaymentValute valute = 3;
 * @return {!proto.students.Payment.PaymentValute}
 */
proto.students.EditPaymentRequest.prototype.getValute = function() {
  return /** @type {!proto.students.Payment.PaymentValute} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.students.Payment.PaymentValute} value
 * @return {!proto.students.EditPaymentRequest} returns this
 */
proto.students.EditPaymentRequest.prototype.setValute = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional Payment.NotificationPreference notificationPreference = 4;
 * @return {!proto.students.Payment.NotificationPreference}
 */
proto.students.EditPaymentRequest.prototype.getNotificationpreference = function() {
  return /** @type {!proto.students.Payment.NotificationPreference} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.students.Payment.NotificationPreference} value
 * @return {!proto.students.EditPaymentRequest} returns this
 */
proto.students.EditPaymentRequest.prototype.setNotificationpreference = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.students.BulkEditPaymentRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.students.BulkEditPaymentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.students.BulkEditPaymentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.students.BulkEditPaymentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.students.BulkEditPaymentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    paymentlistList: jspb.Message.toObjectList(msg.getPaymentlistList(),
    proto.students.EditPaymentRequest.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.students.BulkEditPaymentRequest}
 */
proto.students.BulkEditPaymentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.students.BulkEditPaymentRequest;
  return proto.students.BulkEditPaymentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.students.BulkEditPaymentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.students.BulkEditPaymentRequest}
 */
proto.students.BulkEditPaymentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.students.EditPaymentRequest;
      reader.readMessage(value,proto.students.EditPaymentRequest.deserializeBinaryFromReader);
      msg.addPaymentlist(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.students.BulkEditPaymentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.students.BulkEditPaymentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.students.BulkEditPaymentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.students.BulkEditPaymentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPaymentlistList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.students.EditPaymentRequest.serializeBinaryToWriter
    );
  }
};


/**
 * repeated EditPaymentRequest paymentList = 1;
 * @return {!Array<!proto.students.EditPaymentRequest>}
 */
proto.students.BulkEditPaymentRequest.prototype.getPaymentlistList = function() {
  return /** @type{!Array<!proto.students.EditPaymentRequest>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.students.EditPaymentRequest, 1));
};


/**
 * @param {!Array<!proto.students.EditPaymentRequest>} value
 * @return {!proto.students.BulkEditPaymentRequest} returns this
*/
proto.students.BulkEditPaymentRequest.prototype.setPaymentlistList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.students.EditPaymentRequest=} opt_value
 * @param {number=} opt_index
 * @return {!proto.students.EditPaymentRequest}
 */
proto.students.BulkEditPaymentRequest.prototype.addPaymentlist = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.students.EditPaymentRequest, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.students.BulkEditPaymentRequest} returns this
 */
proto.students.BulkEditPaymentRequest.prototype.clearPaymentlistList = function() {
  return this.setPaymentlistList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.students.GetFullPaymentInfoRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.students.GetFullPaymentInfoRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.students.GetFullPaymentInfoRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.students.GetFullPaymentInfoRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    paymentid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.students.GetFullPaymentInfoRequest}
 */
proto.students.GetFullPaymentInfoRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.students.GetFullPaymentInfoRequest;
  return proto.students.GetFullPaymentInfoRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.students.GetFullPaymentInfoRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.students.GetFullPaymentInfoRequest}
 */
proto.students.GetFullPaymentInfoRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaymentid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.students.GetFullPaymentInfoRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.students.GetFullPaymentInfoRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.students.GetFullPaymentInfoRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.students.GetFullPaymentInfoRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPaymentid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string paymentId = 1;
 * @return {string}
 */
proto.students.GetFullPaymentInfoRequest.prototype.getPaymentid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.students.GetFullPaymentInfoRequest} returns this
 */
proto.students.GetFullPaymentInfoRequest.prototype.setPaymentid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.students.GetFullPaymentInfoResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.students.GetFullPaymentInfoResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.students.GetFullPaymentInfoResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.students.GetFullPaymentInfoResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    payment: (f = msg.getPayment()) && proto.students.Payment.toObject(includeInstance, f),
    availablenotificationpreferencebitmask: jspb.Message.getFieldWithDefault(msg, 2, 0),
    iseditingavailable: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.students.GetFullPaymentInfoResponse}
 */
proto.students.GetFullPaymentInfoResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.students.GetFullPaymentInfoResponse;
  return proto.students.GetFullPaymentInfoResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.students.GetFullPaymentInfoResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.students.GetFullPaymentInfoResponse}
 */
proto.students.GetFullPaymentInfoResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.students.Payment;
      reader.readMessage(value,proto.students.Payment.deserializeBinaryFromReader);
      msg.setPayment(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAvailablenotificationpreferencebitmask(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIseditingavailable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.students.GetFullPaymentInfoResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.students.GetFullPaymentInfoResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.students.GetFullPaymentInfoResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.students.GetFullPaymentInfoResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPayment();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.students.Payment.serializeBinaryToWriter
    );
  }
  f = message.getAvailablenotificationpreferencebitmask();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getIseditingavailable();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional Payment payment = 1;
 * @return {?proto.students.Payment}
 */
proto.students.GetFullPaymentInfoResponse.prototype.getPayment = function() {
  return /** @type{?proto.students.Payment} */ (
    jspb.Message.getWrapperField(this, proto.students.Payment, 1));
};


/**
 * @param {?proto.students.Payment|undefined} value
 * @return {!proto.students.GetFullPaymentInfoResponse} returns this
*/
proto.students.GetFullPaymentInfoResponse.prototype.setPayment = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.students.GetFullPaymentInfoResponse} returns this
 */
proto.students.GetFullPaymentInfoResponse.prototype.clearPayment = function() {
  return this.setPayment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.students.GetFullPaymentInfoResponse.prototype.hasPayment = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional uint32 availableNotificationPreferenceBitmask = 2;
 * @return {number}
 */
proto.students.GetFullPaymentInfoResponse.prototype.getAvailablenotificationpreferencebitmask = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.students.GetFullPaymentInfoResponse} returns this
 */
proto.students.GetFullPaymentInfoResponse.prototype.setAvailablenotificationpreferencebitmask = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional bool isEditingAvailable = 3;
 * @return {boolean}
 */
proto.students.GetFullPaymentInfoResponse.prototype.getIseditingavailable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.students.GetFullPaymentInfoResponse} returns this
 */
proto.students.GetFullPaymentInfoResponse.prototype.setIseditingavailable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.students.AvailableNotificationPreferenceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.students.AvailableNotificationPreferenceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.students.AvailableNotificationPreferenceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.students.AvailableNotificationPreferenceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    notificationpreferencebitmask: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.students.AvailableNotificationPreferenceResponse}
 */
proto.students.AvailableNotificationPreferenceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.students.AvailableNotificationPreferenceResponse;
  return proto.students.AvailableNotificationPreferenceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.students.AvailableNotificationPreferenceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.students.AvailableNotificationPreferenceResponse}
 */
proto.students.AvailableNotificationPreferenceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNotificationpreferencebitmask(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.students.AvailableNotificationPreferenceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.students.AvailableNotificationPreferenceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.students.AvailableNotificationPreferenceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.students.AvailableNotificationPreferenceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNotificationpreferencebitmask();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 notificationPreferenceBitmask = 1;
 * @return {number}
 */
proto.students.AvailableNotificationPreferenceResponse.prototype.getNotificationpreferencebitmask = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.students.AvailableNotificationPreferenceResponse} returns this
 */
proto.students.AvailableNotificationPreferenceResponse.prototype.setNotificationpreferencebitmask = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.students.Payment.prototype.toObject = function(opt_includeInstance) {
  return proto.students.Payment.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.students.Payment} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.students.Payment.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    updatedat: (f = msg.getUpdatedat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    deleted: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    lessonid: jspb.Message.getFieldWithDefault(msg, 4, ""),
    cost: jspb.Message.getFieldWithDefault(msg, 5, 0),
    valute: jspb.Message.getFieldWithDefault(msg, 6, 0),
    paymentstatus: jspb.Message.getFieldWithDefault(msg, 7, 0),
    lastnotificationdate: (f = msg.getLastnotificationdate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    paymentcompletiondate: (f = msg.getPaymentcompletiondate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    notificaitonpreference: jspb.Message.getFieldWithDefault(msg, 10, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.students.Payment}
 */
proto.students.Payment.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.students.Payment;
  return proto.students.Payment.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.students.Payment} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.students.Payment}
 */
proto.students.Payment.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedat(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDeleted(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLessonid(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCost(value);
      break;
    case 6:
      var value = /** @type {!proto.students.Payment.PaymentValute} */ (reader.readEnum());
      msg.setValute(value);
      break;
    case 7:
      var value = /** @type {!proto.students.Payment.PaymentStatus} */ (reader.readEnum());
      msg.setPaymentstatus(value);
      break;
    case 8:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastnotificationdate(value);
      break;
    case 9:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setPaymentcompletiondate(value);
      break;
    case 10:
      var value = /** @type {!proto.students.Payment.NotificationPreference} */ (reader.readEnum());
      msg.setNotificaitonpreference(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.students.Payment.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.students.Payment.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.students.Payment} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.students.Payment.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUpdatedat();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDeleted();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getLessonid();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCost();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getValute();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getPaymentstatus();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getLastnotificationdate();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getPaymentcompletiondate();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getNotificaitonpreference();
  if (f !== 0.0) {
    writer.writeEnum(
      10,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.students.Payment.PaymentStatus = {
  UNKNOWN_STATUS: 0,
  AWAITS_CONFIRMATION: 1,
  PENDING: 2,
  IN_PROGRESS: 3,
  COMPLETED: 4
};

/**
 * @enum {number}
 */
proto.students.Payment.NotificationPreference = {
  UNKNOWN_PREFERENCE: 0,
  MANUAL: 1,
  EMAIL: 2,
  SMS: 4
};

/**
 * @enum {number}
 */
proto.students.Payment.PaymentValute = {
  UNKNOWN_VALUTE: 0,
  US_DOLLAR: 1,
  EURO: 2,
  UK_POUNDS: 3
};

/**
 * optional string id = 1;
 * @return {string}
 */
proto.students.Payment.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.students.Payment} returns this
 */
proto.students.Payment.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp updatedAt = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.students.Payment.prototype.getUpdatedat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.students.Payment} returns this
*/
proto.students.Payment.prototype.setUpdatedat = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.students.Payment} returns this
 */
proto.students.Payment.prototype.clearUpdatedat = function() {
  return this.setUpdatedat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.students.Payment.prototype.hasUpdatedat = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool deleted = 3;
 * @return {boolean}
 */
proto.students.Payment.prototype.getDeleted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.students.Payment} returns this
 */
proto.students.Payment.prototype.setDeleted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional string lessonId = 4;
 * @return {string}
 */
proto.students.Payment.prototype.getLessonid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.students.Payment} returns this
 */
proto.students.Payment.prototype.setLessonid = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int64 cost = 5;
 * @return {number}
 */
proto.students.Payment.prototype.getCost = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.students.Payment} returns this
 */
proto.students.Payment.prototype.setCost = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional PaymentValute valute = 6;
 * @return {!proto.students.Payment.PaymentValute}
 */
proto.students.Payment.prototype.getValute = function() {
  return /** @type {!proto.students.Payment.PaymentValute} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.students.Payment.PaymentValute} value
 * @return {!proto.students.Payment} returns this
 */
proto.students.Payment.prototype.setValute = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional PaymentStatus paymentStatus = 7;
 * @return {!proto.students.Payment.PaymentStatus}
 */
proto.students.Payment.prototype.getPaymentstatus = function() {
  return /** @type {!proto.students.Payment.PaymentStatus} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.students.Payment.PaymentStatus} value
 * @return {!proto.students.Payment} returns this
 */
proto.students.Payment.prototype.setPaymentstatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional google.protobuf.Timestamp lastNotificationDate = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.students.Payment.prototype.getLastnotificationdate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 8));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.students.Payment} returns this
*/
proto.students.Payment.prototype.setLastnotificationdate = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.students.Payment} returns this
 */
proto.students.Payment.prototype.clearLastnotificationdate = function() {
  return this.setLastnotificationdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.students.Payment.prototype.hasLastnotificationdate = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.Timestamp paymentCompletionDate = 9;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.students.Payment.prototype.getPaymentcompletiondate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 9));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.students.Payment} returns this
*/
proto.students.Payment.prototype.setPaymentcompletiondate = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.students.Payment} returns this
 */
proto.students.Payment.prototype.clearPaymentcompletiondate = function() {
  return this.setPaymentcompletiondate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.students.Payment.prototype.hasPaymentcompletiondate = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional NotificationPreference notificaitonPreference = 10;
 * @return {!proto.students.Payment.NotificationPreference}
 */
proto.students.Payment.prototype.getNotificaitonpreference = function() {
  return /** @type {!proto.students.Payment.NotificationPreference} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {!proto.students.Payment.NotificationPreference} value
 * @return {!proto.students.Payment} returns this
 */
proto.students.Payment.prototype.setNotificaitonpreference = function(value) {
  return jspb.Message.setProto3EnumField(this, 10, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.students.PaymentList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.students.PaymentList.prototype.toObject = function(opt_includeInstance) {
  return proto.students.PaymentList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.students.PaymentList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.students.PaymentList.toObject = function(includeInstance, msg) {
  var f, obj = {
    paymentlistList: jspb.Message.toObjectList(msg.getPaymentlistList(),
    proto.students.Payment.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.students.PaymentList}
 */
proto.students.PaymentList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.students.PaymentList;
  return proto.students.PaymentList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.students.PaymentList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.students.PaymentList}
 */
proto.students.PaymentList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.students.Payment;
      reader.readMessage(value,proto.students.Payment.deserializeBinaryFromReader);
      msg.addPaymentlist(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.students.PaymentList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.students.PaymentList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.students.PaymentList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.students.PaymentList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPaymentlistList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.students.Payment.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Payment paymentList = 1;
 * @return {!Array<!proto.students.Payment>}
 */
proto.students.PaymentList.prototype.getPaymentlistList = function() {
  return /** @type{!Array<!proto.students.Payment>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.students.Payment, 1));
};


/**
 * @param {!Array<!proto.students.Payment>} value
 * @return {!proto.students.PaymentList} returns this
*/
proto.students.PaymentList.prototype.setPaymentlistList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.students.Payment=} opt_value
 * @param {number=} opt_index
 * @return {!proto.students.Payment}
 */
proto.students.PaymentList.prototype.addPaymentlist = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.students.Payment, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.students.PaymentList} returns this
 */
proto.students.PaymentList.prototype.clearPaymentlistList = function() {
  return this.setPaymentlistList([]);
};


goog.object.extend(exports, proto.students);

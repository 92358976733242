import copyText from '../utils/clipboard.js';

export default {
    props: {
        url: {
            type: String,
            default: ''
        },
        textUrl: {
            type: String,
            default: ''
        },
        description: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            icon: {
                name: 'copy',
                width: 1.6,
                height: 1.6
            },
            tooltipPosition: {
                bottom: 0,
                left: 'unset',
                top: 'unset',
                right: 0
            },
            showTooltip: false,
        }
    },
    methods: {
        async handleCopy() {
            await copyText(this.url);
            this.showTooltip = true;
        },
        closeTooltip() {
            this.showTooltip = false;
        }
    }
}

import { createApp } from 'vue'
import App from './App.vue'
import { router } from './router'
import { store } from './store'
import Notifications from "@kyvg/vue3-notification";
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import VueGtag from 'vue-gtag';

const app = createApp(App);

app.use(store);
app.use(router);
app.use(Notifications);
app.use(VueGtag, {
    bootstrap: false,
}, router);

app.directive('focus', {
    mounted: function (el, binding) {
        if (binding.value) {
            el.focus();
        }
    }
});

app.directive('clickOutside', {
    beforeMount: (el, binding) => {
        el.clickOutsideEvent = event => {
          if (!(el == event.target || el.contains(event.target))) {
            binding.value();
          }
        };
        document.addEventListener("click", el.clickOutsideEvent);
    },
    unmounted: el => {
        document.removeEventListener("click", el.clickOutsideEvent);
    },
})

dayjs.extend(customParseFormat);
app.provide('$dayjs', dayjs);

app.mount('#app');

import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb';
import { Payment } from './proto/students_pb';

function getCookie(name) {
    let matches = document.cookie.match(new RegExp(
        "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
}

function setCookie(name, value, options = {}) {

    options = {
        path: '/',
        // при необходимости добавьте другие значения по умолчанию
        ...options
    };

    if (options.expires instanceof Date) {
        options.expires = options.expires.toUTCString();
    }

    let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);

    for (let optionKey in options) {
        updatedCookie += "; " + optionKey;
        let optionValue = options[optionKey];
        if (optionValue !== true) {
            updatedCookie += "=" + optionValue;
        }
    }

    document.cookie = updatedCookie;
}


function deleteCookie(name) {
    setCookie(name, "", {
        'max-age': -1
    })
}

function getTimestampDate(date) {
    return Timestamp.fromDate(date);
}

function getDateFromTimestamp(timestamp) {
    const instance = new Timestamp();

    instance.setSeconds(timestamp.seconds);
    instance.setNanos(timestamp.nanos);
    
    return instance.toDate();
}

function getTimestamp(timestamp) {
    const instance = new Timestamp();

    instance.setSeconds(timestamp.seconds);
    instance.setNanos(timestamp.nanos);

    return instance;
}

function getPaymentInfoKey(type, number) {
    let localType = '';

    switch(type) {
        case 'notificaitonpreference':
            localType = 'NotificationPreference';
            break;
        case 'valute':
            localType = 'PaymentValute';
            break;
        default:
            break;
    }

    return Object.keys(Payment[localType]).find(key => Payment[localType][key] === number) || 'UNKOWN';
}

export { setCookie, getCookie, deleteCookie, getTimestampDate, getDateFromTimestamp, getTimestamp, getPaymentInfoKey };
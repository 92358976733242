import { StudentsPromiseClient, SchedulePromiseClient, PaymentsPromiseClient } from './proto/students_grpc_web_pb.js';
import {
    AddStudentRequest,
    GetFullProfileRequest,
    GetProfileListRequest,
    EditStudentRequest,
    AddLessonRequest,
    EditLessonRequest,
    GetLessonListRequest,
    GetLessonListByIdsRequest,
    GetPaymentsByIdListRequest,
    EditPaymentRequest,
    Anchor,
    LessonAnchor,
    ProfileInfoByCourseRequest,
    RemoveLessonRequest
} from './proto/students_pb.js';
import GoogleEmpty from 'google-protobuf/google/protobuf/empty_pb.js';
import { getCookie } from "@/services/common";
import { getTimestampDate, getTimestamp } from './common';

const studentsInstancePromise = new StudentsPromiseClient(process.env.API_URL, null, null);
const scheduleInstancePromise = new SchedulePromiseClient(process.env.API_URL, null, null);
const paymentsInstancePromise = new PaymentsPromiseClient(process.env.API_URL, null, null);
const emptyProtobuf = new GoogleEmpty.Empty;

const getStudentsList = function () {
    const credentials = { session: getCookie('session') };
    return studentsInstancePromise.getStudentList(emptyProtobuf, credentials);
}

const addStudent = function (uuid, firstName, lastName, photo, contacts) {
    const credentials = { session: getCookie('session') };
    const request = new AddStudentRequest();

    request.setIdempotencykey(uuid);
    request.setFirstname(firstName);
    request.setLastname(lastName);
    request.setPhotourl(photo);
    request.setContactlistList(contacts);

    return studentsInstancePromise.addStudent(request, credentials);
}

const getStudentById = function (id) {
    const credentials = { session: getCookie('session') };
    const request = new GetFullProfileRequest();

    request.setId(id);

    return studentsInstancePromise.getFullProfile(request, credentials);
}

const getStudentsByIds = function (ids) {
    const credentials = { session: getCookie('session') };
    const request = new GetProfileListRequest();

    request.setIdlistList(ids);

    return studentsInstancePromise.getProfileListByIds(request, credentials);
}

const editStudent = function (id, firstName, lastName, photo, contacts) {
    const credentials = { session: getCookie('session') };
    const request = new EditStudentRequest();

    request.setId(id);
    request.setFirstname(firstName);
    request.setLastname(lastName);
    request.setPhotourl(photo);
    request.setContactlistList(contacts);

    return studentsInstancePromise.editStudent(request, credentials);
}

const uploadAvatar = function (data) {
    const url = 'https://api.petroka.app/';

    return fetch(`${url}static/upload?withBlurhash=true`, {
                headers: {
                    session: getCookie('session'),
                },
                method: 'POST',
                body: data,
            });
}

const getLessonsIdsList = function (studentId, limit, requestOptions = null) {
    const credentials = { session: getCookie('session') };
    const request = new GetLessonListRequest();
    const anchor = new Anchor();

    if (!requestOptions) {
        anchor.setFromscratch(new GoogleEmpty.Empty);
    } else {
        const lessonAnchor = new LessonAnchor();

        lessonAnchor.setCreatedat(getTimestamp(requestOptions.createAt));
        lessonAnchor.setStartdate(getTimestamp(requestOptions.startDate));
        anchor.setFromanchor(lessonAnchor);
    }

    request.setStudentid(studentId);
    request.setAnchor(anchor);
    request.setLimit(limit);

    return scheduleInstancePromise.getLessonList(request, credentials);
}

const getLessonsByIds = function (ids) {
    const credentials = { session: getCookie('session') };
    const request = new GetLessonListByIdsRequest();

    request.setIdlistList(ids);

    return scheduleInstancePromise.getLessonListByIds(request, credentials);
}

const addLesson = function (studentId, uuid, location, startDate, endDate, duration, paymentInfo) {
    const credentials = { session: getCookie('session') };
    const request = new AddLessonRequest();
    const startDateTimestamp = getTimestampDate(startDate);
    const endDateTimestamp = getTimestampDate(endDate);

    request.setStudentid(studentId);
    request.setIdempotencykey(uuid);
    request.setLocation(location);
    request.setStartDate(startDateTimestamp);
    request.setEndDate(endDateTimestamp);
    request.setReminderduration(duration);
    request.setPaymentinfo(paymentInfo);

    return scheduleInstancePromise.addLesson(request, credentials);
}

const editLesson = function (lessonId, location, startDate, endDate, duration) {
    const credentials = { session: getCookie('session') };
    const request = new EditLessonRequest();
    const startDateTimestamp = getTimestampDate(startDate);
    const endDateTimestamp = getTimestampDate(endDate);

    request.setId(lessonId);
    request.setLocation(location);
    request.setStartDate(startDateTimestamp);
    request.setEndDate(endDateTimestamp);
    request.setReminderduration(duration);

    return scheduleInstancePromise.editLesson(request, credentials);
}

const editPayment = function (id, cost, valute, notificationPreference) {
    const credentials = { session: getCookie('session') };
    const request = new EditPaymentRequest();

    request.setId(id);
    request.setCost(cost);
    request.setNotificationpreference(notificationPreference);
    request.setValute(valute);

    return paymentsInstancePromise.editPayment(request, credentials);
}

const getPaymentByIds = function (ids) {
    const credentials = { session: getCookie('session') };
    const request = new GetPaymentsByIdListRequest();

    request.setIdlistList(ids);

    return paymentsInstancePromise.getPaymentsByIdList(request, credentials);
}

const getStudentByCourseId = function (id) {
    const credentials = { session: getCookie('session') };
    const request = new ProfileInfoByCourseRequest();

    request.setCourseid(id);

    return studentsInstancePromise.profileInfoByCourseID(request, credentials);
}

const removeLesson = function (id) {
    const credentials = { session: getCookie('session') };
    const request = new RemoveLessonRequest();

    request.setId(id);

    return scheduleInstancePromise.removeLesson(request, credentials);
}

export {
    getStudentsList,
    getStudentsByIds,
    addStudent,
    getStudentById,
    editStudent,
    uploadAvatar,
    addLesson,
    editLesson,
    getLessonsIdsList,
    getLessonsByIds,
    getPaymentByIds,
    editPayment,
    getStudentByCourseId,
    removeLesson
};

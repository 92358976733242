<template>
    <label class="input-phone-base">
        <span v-if="params.name && params.nameVisibility" class="input-phone-base__title">{{ params.name }}</span>
        <span class="input-phone-base__wrapper" :class="{ 'input-phone-base__wrapper--error': params.error }">
            <span class="input-phone-base__code-country-wrapper">
                <span class="input-phone-base__code-country" :value="code" @click="showSelectCountyCodeInputA($event)">{{ code }}</span>
                <span class="input-phone-base__select" ref="select">
                    <label class="input-phone-base__select-search">
                        <svg-icon :width="2" :height="2" name="lens" class="input-phone-base__select-search-icon" />
                        <input class="input-phone-base__select-search-input" type="text" @input="searchCountryCode($event)" />
                    </label>
                    <span class="input-phone-base__select-list">
                        <span v-for="(option, index) in listOfCallingCodesCountryInputA"
                             :value="`${option[0]} ${option[1]}`"
                             @click="chooseCountryCodeInputA($event)"
                             class="input-phone-base__select-option"
                             :key="`${option[1]}_${index}`"
                        >
                            <span class="input-phone-base__select-code">{{ option[0] }}</span>
                            <span class="input-phone-base__select-name">{{ option[1] }}</span>
                        </span>
                    </span>
                </span>
            </span>
            <span class="input-phone-base__number-wrapper">
                <input
                    class="input-phone-base__number"
                    type="text"
                    v-model="value"
                    maxlength="16"
                    @input="oninputPhoneNumberInputA"
                    @focus="focused"
                    @blur="focused"
                />
            </span>
            <span class="input-phone-base__error" v-if="params.error">{{ params.error }}</span>
        </span>
    </label>
</template>

<script>
    import SvgIcon from "@/components/common/SvgIcon/SvgIcon";

    export default {
        name: "InputPhoneBase",
        components: {
            SvgIcon
        },
        data() {
            return {
                value: '',
                code: '+44',
                options: this.getSelectOptions(),
                listOfCallingCodesCountryInputA: listOfCallingCodesCountryInputA,
            }
        },
        props: {
            params: {
                type: Object,
                default: () => {}
            }
        },
        mounted() {
            this.$nextTick(() => {
                if (this.params.value && this.params.value.includes('+')) {
                    this.code = this.params.value.slice(0, this.params.value.length - 10);
                }
                if (this.params.value && this.params.value.length !== this.code.length) {
                    this.value = this.params.value.slice(-10);
                }
            });

            window.addEventListener("mousedown", this.mouseDownListener);
        },
        beforeUnmount() {
            window.removeEventListener('mousedown', this.mouseDownListener);
        },
        methods: {
            focused(event) {
                const wrapper = event.target.closest('.input-phone-base__wrapper');

                if (wrapper) {
                    wrapper.classList.toggle('focus');
                }
            },
            mouseDownListener(e) {
                let selects = document.querySelectorAll(".input-phone-base__select");
                for (let i = 0; i < selects.length; i++) {
                    if (selects[i] !== e.target && !isChild(selects[i], e.target) && selects[i].style.display === 'flex') {
                        selects[i].style.display = "none";
                    }
                }
            },
            getSelectOptions() {
                if (!this.$slots.default) { return ""; }
                let options = [];
                for (let i = 0; i < this.$slots.default.length; i++) {
                    if (!this.$slots.default[i].children) { continue; }

                    let opt = {
                        name: this.$slots.default[i].children[0].text,
                        value: ""
                    };

                    if (this.$slots.default[i].data && this.$slots.default[i].data.attrs) {
                        opt.value = this.$slots.default[i].data.attrs.value;
                    }
                    options.push(opt);
                }
                return options;
            },
            chooseCountryCodeInputA(event) {
                this.$refs.select.style.display = "none";
                this.code = event.target.closest('.input-phone-base__select-option').getAttribute('value').replace(/[a-zA-Z ]/g, '');
                this.$emit('update:modelValue', {
                    value: this.code + this.value.replace(/[(_ \-)]/g, ''),
                    name: this.params.name
                });
            },
            searchCountryCode(event) {
                let value = event.target.value;

                if (value === "") {
                    let options = document.querySelectorAll(".input-phone-base__select-option");
                    for (let i = 0; i < options.length; i++) {
                        options[i].style.display = "flex";
                    }
                    return;
                }

                value = value.replace(/[ ]+/g, " ");
                value = value.split(" ");

                for (let i = 0; i < value.length; i++) {
                    if (value[i] === "") { value.splice(i, 1); }
                }

                for (let i = 0; i < value.length; i++) {
                    value[i] = value[i].toLowerCase();
                    for (let j = 0; j < value[i].length; j++) {
                        if (value[i][j] === "+") {
                            value[i] = value[i].substr(0, j)+"\\"+value[i].substr(j);
                            j++;
                        }
                    }
                }

                let options = document.querySelectorAll(".input-phone-base__select-option");

                for (let i = 0; i < options.length; i++) {
                    options[i].style.display = "none";
                    for (let j = 0; j < value.length; j++) {
                        let regExp = new RegExp('.*'+value[j]+'.*', "g");
                        let v = options[i].getAttribute("value").toLowerCase();
                        if (v.match(regExp)) {
                            options[i].style.display = "flex";
                            break;
                        }
                    }
                }
            },
            showSelectCountyCodeInputA(event) {
                let selects = document.querySelectorAll(".input-phone-base__select");
                for (let i = 0; i < selects.length; i++) {
                    selects[i].style.display = "none";
                }
                event.target.parentNode.querySelector(".input-phone-base__select").style.display = "flex";
            },
            oninputPhoneNumberInputA() {
                this.$emit('update:modelValue', {
                    value: this.code + this.value,
                    name: this.params.name
                });
            }
        }
    }

    function isChild(parent, child) {
        let node = child.parentNode;
        while (node !== null) {
            if (node === parent) {
                return true;
            }
            node = node.parentNode;
        }
        return false;
    }

    const listOfCallingCodesCountryInputA = [
        ["+1", "Canada", "canada.webp"],
        ["+1", "United States", "us.webp"],
        ["+1 340", "United States Virgin Islands", "virginIslands.webp"],
        ["+1 670", "Northern Mariana Islands", "northernMarianaIslands.webp"],
        ["+1 671", "Guam", "guam.webp"],
        ["+1 684", "American Samoa", "americanSamoa.webp"],
        ["+1 787", "Puerto Rico", "puertoRico.webp"],
        ["+1 939", "Puerto Rico", "puertoRico.webp"],
        ["+1 242", "Bahamas", "bahamas.webp"],
        ["+1 246", "Barbados", "barbados.webp"],
        ["+1 264", "Anguilla", "anguilla.webp"],
        ["+1 268", "Antigua and Barbuda", "antiguaBarbuda.webp"],
        ["+1 284", "British Virgin Islands", "britishVirginIslands.webp"],
        ["+1 345", "Cayman Islands", "caymanIslands.webp"],
        ["+1 441", "Bermuda", "bermuda.webp"],
        ["+1 473", "Grenada", "grenada.webp"],
        ["+1 649", "Turks and Caicos Islands", "turksCaicosIslands.webp"],
        ["+1 658", "Jamaica", "jamaica.webp"],
        ["+1 876", "Jamaica", "jamaica.webp"],
        ["+1 664", "Montserrat", "montserrat.webp"],
        ["+1 721", "Sint Maarten", "sintMaarten.webp"],
        ["+1 758", "Saint Lucia", "saintLucia.webp"],
        ["+1 767", "Dominica", "dominica.webp"],
        ["+1 784", "Saint Vincent and the Grenadines", "saintVincentGrenadines.webp"],
        ["+1 809", "Dominican Republic", "dominicanRepublic.webp"],
        ["+1 829", "Dominican Republic", "dominicanRepublic.webp"],
        ["+1 849", "Dominican Republic", "dominicanRepublic.webp"],
        ["+1 868", "Trinidad and Tobago", "trinidadTobago.webp"],
        ["+1 869", "Saint Kitts and Nevis", "saintKittsNevis.webp"],
        ["+20", "Egypt", "egypt.webp"],
        ["+211", "South Sudan", "southSudan.png"],
        ["+212", "Morocco", "morocco.webp"],
        ["+213", "Algeria", "algeria.webp"],
        ["+216", "Tunisia", "tunisia.webp"],
        ["+218", "Libya", "libya.webp"],
        ["+220", "Gambia", "gambia.webp"],
        ["+221", "Senegal", "senegal.webp"],
        ["+222", "Mauritania", "mauritania.webp"],
        ["+223", "Mali", "mali.webp"],
        ["+224", "Guinea", "guinea.webp"],
        ["+225", "Ivory Coast", "ivoryCoast.webp"],
        ["+226", "Burkina Faso", "burkinaFaso.webp"],
        ["+227", "Niger", "nigeria.webp"],
        ["+228", "Togo", "togo.webp"],
        ["+229", "Benin", "benin.webp"],
        ["+230", "Mauritius", "mauritius.webp"],
        ["+231", "Liberia", "liberia.webp"],
        ["+232", "Sierra Leone", "sierraLeone.webp"],
        ["+233", "Ghana", "ghana.webp"],
        ["+234", "Nigeria", "niger.webp"],
        ["+235", "Chad", "chad.webp"],
        ["+236", "Central African Republic", "centralAfricanRepublic.webp"],
        ["+237", "Cameroon", "cameroon.webp"],
        ["+238", "Cape Verde", "capeVerde.webp"],
        ["+239", "Sao Tome and Principe", "saoTomePrincipe.webp"],
        ["+240", "Equatorial Guinea", "equatorialGuinea.webp"],
        ["+241", "Gabon", "gabon.webp"],
        ["+242", "Republic of the Congo", "republicCongo.webp"],
        ["+243", "Democratic Republic of the Congo", "democraticRepublicCongo.webp"],
        ["+244", "Angola", "angola.webp"],
        ["+245", "Guinea-Bissau", "guineaBissau.webp"],
        ["+246", "British Indian Ocean Territory", "britishIndianOceanTerritory.webp"],
        ["+247", "Ascension Island", "ascensionIsland.webp"],
        ["+248", "Seychelles", "seychelles.webp"],
        ["+249", "Sudan", "sudan.webp"],
        ["+250", "Rwanda", "rwanda.webp"],
        ["+251", "Ethiopia", "ethiopia.webp"],
        ["+252", "Somalia", "somalia.webp"],
        ["+253", "Djibouti", "djibouti.webp"],
        ["+254", "Kenya", "kenya.webp"],
        ["+255", "Tanzania", "tanzania.webp"],
        ["+255 24", "Zanzibar", "zanzibar.webp"],
        ["+256", "Uganda", "uganda.webp"],
        ["+257", "Burundi", "burundi.webp"],
        ["+258", "Mozambique", "mozambique.webp"],
        ["+260", "Zambia", "zambia.webp"],
        ["+261", "Madagascar", "madagascar.webp"],
        ["+262", "Reunion", "reunion.webp"],
        ["+262 269", "Mayotte", "reunion.webp"],
        ["+262 639", "Mayotte", "reunion.webp"],
        ["+263", "Zimbabwe", "zimbabwe.webp"],
        ["+264", "Namibia", "namibia.webp"],
        ["+265", "Malawi", "malawi.webp"],
        ["+266", "Lesotho", "lesotho.webp"],
        ["+267", "Botswana", "botswana.webp"],
        ["+268", "Eswatini", "eswatini.webp"],
        ["+27", "South Africa", "southAfrica.webp"],
        ["+290", "Saint Helena", "saintHelena.webp"],
        ["+290 8", "Tristan da Cunha", "tristanDaCunha.webp"],
        ["+291", "Eritrea", "eritrea.webp"],
        ["+297", "Aruba", "aruba.webp"],
        ["+298", "Faroe Islands", "faroeIslands.webp"],
        ["+299", "Greenland", "greenland.webp"],
        ["+30", "Greece", "greece.webp"],
        ["+31", "Netherlands", "netherlands.webp"],
        ["+32", "Belgium", "belgium.webp"],
        ["+33", "France", "france.webp"],
        ["+34", "Spain", "spain.webp"],
        ["+350", "Gibraltar", "gibraltar.webp"],
        ["+351", "Portugal", "portugal.webp"],
        ["+351 291", "Madeira", "madeira.webp"],
        ["+351 292", "Azores", "azores.webp"],
        ["+351 295", "Azores", "azores.webp"],
        ["+351 296", "Azores", "azores.webp"],
        ["+352", "Luxembourg", "luxembourg.webp"],
        ["+353", "Ireland", "ireland.webp"],
        ["+354", "Iceland", "ireland.webp"],
        ["+355", "Albania", "albania.webp"],
        ["+356", "Malta", "malta.webp"],
        ["+357", "Cyprus", "cyprus.webp"],
        ["+358", "Finland", "finland.webp"],
        ["+358 18", "Aland Islands", "alandIslands.webp"],
        ["+359", "Bulgaria", "bulgaria.webp"],
        ["+36", "Hungary", "hungary.webp"],
        ["+370", "Lithuania", "lithuania.webp"],
        ["+371", "Latvia", "latvia.webp"],
        ["+372", "Estonia", "estonia.webp"],
        ["+373", "Moldova", "moldova.webp"],
        ["+374", "Armenia", "armenia.webp"],
        ["+374 47", "Artsakh", "artsakh.webp"],
        ["+374 97", "Artsakh", "artsakh.webp"],
        ["+375", "Belarus", "belarus.webp"],
        ["+376", "Andorra", "andorra.webp"],
        ["+377", "Monaco", "monaco.webp"],
        ["+378", "San Marino", "sanMarino.webp"],
        ["+379", "Vatican City", "vaticanCity.webp"],
        ["+380", "Ukraine", "ukraine.webp"],
        ["+381", "Serbia", "serbia.webp"],
        ["+382", "Montenegro", "montenegro.webp"],
        ["+383", "Kosovo", "kosovo.webp"],
        ["+385", "Croatia", "croatia.webp"],
        ["+386", "Slovenia", "slovenia.webp"],
        ["+387", "Bosnia and Herzegovina", "bosniaHerzegovina.webp"],
        ["+389", "North Macedonia", "northMacedonia.webp"],
        ["+39", "Italy", "italy.webp"],
        ["+39 06 698", "Vatican City", "vaticanCity.webp"],
        ["+39 0549", "San Marino", "sanMarino.webp"],
        ["+41 91", "Italy", "italy.webp"],
        ["+40", "Romania", "romania.webp"],
        ["+41", " Switzerland", "switzerland.webp"],
        ["+41 91", "Italy", "italy.webp"],
        ["+420", "Czech Republic", "czechRepublic.webp"],
        ["+421", "Slovakia", "slovakia.webp"],
        ["+423", "Liechtenstein", "liechtenstein.webp"],
        ["+43", "Austria", "austria.webp"],
        ["+44", "United Kingdom", "unitedKingdom.webp"],
        ["+44 1481", "Guernsey", "guernsey.webp"],
        ["+44 1534", "Jersey", "jersey.webp"],
        ["+44 1624", "Isle of Man", "isleMan.webp"],
        ["+45", "Denmark", "denmark.webp"],
        ["+46", "Sweden", "sweden.webp"],
        ["+47", "Norway", "norway.webp"],
        ["+47 79", "Svalbard", "norway.webp"],
        ["+48", "Poland", "poland.webp"],
        ["+49", "Germany", "germany.webp"],
        ["+500", "Falkland Islands", "falklandIslands.webp"],
        ["+500", "South Georgia", "southGeorgiaSouthSandwichIslands.webp"],
        ["+500", "South Sandwich Islands", "southGeorgiaSouthSandwichIslands.webp"],
        ["+501", "Belize", "belize.webp"],
        ["+502", "Guatemala", "guatemala.webp"],
        ["+503", "El Salvador", "elSalvador.webp"],
        ["+504", "Honduras", "honduras.webp"],
        ["+505", "Nicaragua", "nicaragua.webp"],
        ["+506", "Costa Rica", "costaRica.webp"],
        ["+507", "Panama", "panama.webp"],
        ["+508", "Saint-Pierre and Miquelon", "saintPierreMiquelon.webp"],
        ["+509", "Haiti", "haiti.webp"],
        ["+51", "Peru", "peru.webp"],
        ["+52", "Mexico", "mexico.webp"],
        ["+53", "Cuba", "cuba.webp"],
        ["+54", "Argentina", "argentina.webp"],
        ["+55", "Brazil", "brazil.webp"],
        ["+56", "Chile", "chile.webp"],
        ["+57", "Colombia", "colombia.webp"],
        ["+58", "Venezuela", "venezuela.webp"],
        ["+590", "Guadeloupe", "guadeloupe.webp"],
        ["+591", "Bolivia", "bolivia.webp"],
        ["+592", "Guyana", "guyana.webp"],
        ["+593", "Ecuador", "ecuador.webp"],
        ["+594", "French Guiana", "frenchGuiana.webp"],
        ["+595", "Paraguay", "paraguay.webp"],
        ["+596", "Martinique", "martinique.webp"],
        ["+597", "Suriname", "suriname.webp"],
        ["+598", "Uruguay", "uruguay.webp"],
        ["+599 3", "Sint Eustatius", "sintEustatius.png"],
        ["+599 4", "Saba", "saba.png"],
        ["+599 7", " Bonaire", "bonaire.png"],
        ["+599 9", "Curacao", "curacao.webp"],
        ["+60", "Malaysia", "malaysia.webp"],
        ["+61", "Australia", "australia.webp"],
        ["+61 8 9162", "Cocos Islands", "cocosIslands.webp"],
        ["+61 8 9164", "Christmas Island", "christmasIsland.webp"],
        ["+62", "Indonesia", "indonesia.webp"],
        ["+63", "Philippines", "philippines.webp"],
        ["+64", "New Zealand", "newZealand.webp"],
        ["+64", "Pitcairn Islands", "pitcairnIslands.webp"],
        ["+65", "Singapore", "singapore.webp"],
        ["+66", "Thailand", "thailand.webp"],
        ["+670", "East Timor", "eastTimor.webp"],
        ["+672", "AET", ""],
        ["+672 1", "AAT", "australianAntarcticTerritory.webp"],
        ["+672 3", "Norfolk Island", "norfolkIsland.webp"],
        ["+673", "Brunei", "brunei.webp"],
        ["+674", "Nauru", "nauru.webp"],
        ["+675", "Papua New Guinea", "papuaNewGuinea.webp"],
        ["+676", "Tonga", "tonga.webp"],
        ["+677", "Solomon Islands", "solomonIslands.webp"],
        ["+678", "Vanuatu", "vanuatu.webp"],
        ["+679", "Fiji", "fiji.webp"],
        ["+680", "Palau", "palau.webp"],
        ["+681", "Wallis and Futuna", "wallisFutuna.webp"],
        ["+682", "Cook Islands", "cookIslands.webp"],
        ["+683", "Niue", "niue.webp"],
        ["+685", "Samoa", "samoa.webp"],
        ["+686", "Kiribati", "kiribati.webp"],
        ["+687", "New Caledonia", "newCaledonia.webp"],
        ["+688", "Tuvalu", "tuvalu.webp"],
        ["+689", "French Polynesia", "frenchPolynesia.webp"],
        ["+690", "Tokelau", "tokelau.webp"],
        ["+691", "Federated States of Micronesia", "federatedStatesMicronesia.webp"],
        ["+692", "Marshall Islands", "marshallIslands.webp"],
        ["+7", "Russia", "russia.webp"],
        ["+7 6", "Kazakhstan", "kazakhstan.webp"],
        ["+7 7", "Kazakhstan", "kazakhstan.webp"],
        ["+7 840", "Abkhazia", "abkhazia.webp"],
        ["+7 940", "Abkhazia", "abkhazia.webp"],
        ["+800", "International Freephone (UIFN)", ""],
        ["+808", "Shared Cost Services", ""],
        ["+81", "Japan", "japan.webp"],
        ["+82", "South Korea", "southKorea.webp"],
        ["+84", "Vietnam", "vietnam.webp"],
        ["+850", "North Korea", "northKorea.webp"],
        ["+852", "Hong Kong", "hongKong.webp"],
        ["+853", "Macau", "macau.png"],
        ["+855", "Cambodia", "cambodia.webp"],
        ["+856", "Laos", "laos.webp"],
        ["+86", "China", "china.webp"],
        ["+870", "Inmarsat SNAC service", ""],
        ["+875", "Maritime Mobile service", ""],
        ["+876", "Maritime Mobile service", ""],
        ["+877", "Maritime Mobile service", ""],
        ["+878", "UPTS", ""],
        ["+879", "other services", ""],
        ["+880", "Bangladesh", "bangladesh.webp"],
        ["+881", "Global Mobile Satellite System", ""],
        ["+882", "International Networks", ""],
        ["+883", "International Networks", ""],
        ["+886", "Taiwan", "taiwan.webp"],
        ["+888", "TfDR by OCHA", ""],
        ["+90", "Turkey", ""],
        ["+90 392", "Northern Cyprus", ""],
        ["+91", "India", ""],
        ["+92", "Pakistan", ""],
        ["+92 582", "Azad Kashmir", ""],
        ["+92 581", "Gilgit Baltistan", ""],
        ["+93", "Afghanistan", ""],
        ["+94", "Sri Lanka", "sriLanka.webp"],
        ["+95", "Myanmar", "myanmar.webp"],
        ["+960", "Maldives", "maldives.webp"],
        ["+961", "Lebanon", "lebanon.webp"],
        ["+962", "Jordan", "jordan.webp"],
        ["+963", "Syria", "syria.webp"],
        ["+964", "Iraq", "iraq.webp"],
        ["+965", "Kuwait", "kuwait.webp"],
        ["+966", "Saudi Arabia", "saudiArabia.webp"],
        ["+967", "Yemen", "yemen.webp"],
        ["+968", "Oman", "oman.webp"],
        ["+970", "Palestine", "palestine.webp"],
        ["+971", "UAE", "unitedArabEmirates.webp"],
        ["+972", "Israel", "israel.webp"],
        ["+973", "Bahrain", "bahrain.webp"],
        ["+974", "Qatar", "qatar.webp"],
        ["+975", "Bhutan", "bhutan.webp"],
        ["+976", "Mongolia", "mongolia.webp"],
        ["+977", "Nepal", "nepal.webp"],
        ["+979", "IPRS", ""],
        ["+98", "Iran", "iran.webp"],
        ["+991", "ITPCS", ""],
        ["+992", "Tajikistan", "tajikistan.webp"],
        ["+993", "Turkmenistan", "turkmenistan.webp"],
        ["+994", "Azerbaijan", "azerbaijan.webp"],
        ["+995", "Georgia", "georgia.webp"],
        ["+995 34", "South Ossetia", "southOssetia.webp"],
        ["+995 44", "Abkhazia", "abkhazia.webp"],
        ["+996", "Kyrgyzstan", "kyrgyzstan.webp"],
        ["+997", "Kazakhstan", "kazakhstan.webp"],
        ["+998", "Uzbekistan", "uzbekistan.webp"],
    ];
</script>

import { createStore } from 'vuex'
import Students from './students';
import Payment from './payment';
import CookieSettings from './cookieSettings';

export const store = createStore({
    state: {
        isAuth: false,
        isConfirmPassword: false,
        isResetPassword: false,
        isInvoice: true,
        user: null,
        showModal: false,
        currentModal: '',
        modalData: null
    },
    mutations: {
        DEFAULT_MUT(state, payload) {
            state[payload.property] = payload.value;
        },
    },
    actions: {
        openModal(store, payload) {
            const eventLabel = payload.modalData.modalType
                ? `user opened modal ${payload.modalName} with type ${payload.modalData.modalType}`
                : `user opened modal ${payload.modalName}`;

            store.commit('DEFAULT_MUT', {
                property: 'modalData',
                value: payload.modalData,
            });
            store.commit('DEFAULT_MUT', {
                property: 'showModal',
                value: true,
            });
            store.commit('DEFAULT_MUT', {
                property: 'currentModal',
                value: payload.modalName,
            });
            // this._vm.$gtag.event(`open_modal_${payload.modalName}`, {
            //     'event_category': 'modals',
            //     'event_label': eventLabel,
            // });
        },
        clearStore({ commit }) {
            commit('DEFAULT_MUT', {
                property: 'isAuth',
                value: false,
            });
            commit('DEFAULT_MUT', {
                property: 'user',
                value: null,
            });
            commit('Students/clearState', null, { root: true });
            commit('Payment/clearState', null, { root: true });
        }
    },
    modules: {
        Students,
        Payment,
        CookieSettings
    }
})

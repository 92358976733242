<template>
    <button :class="['button-base', {'button-base--loading': showButtonLoader}]"
            :disabled="disabled"
    >
        <slot name="tooltip"></slot>
        <svg-icon
            v-if="icon && !showButtonLoader"
            class="button-base__icon"
            :name="icon.name"
            :width="icon.width"
            :height="icon.height"
        />
        <span class="button-base__text" v-if="text">{{ text }}</span>
        <div class="button-base__shortcut" v-if="shortcut.enabled">
            <span class="button-base__shortcut-text">{{ shortcut.text }}</span>
        </div>
    </button>
</template>

<script>
    import SvgIcon from "@/components/common/SvgIcon/SvgIcon";

    export default {
        name: "ButtonBase",
        components: {
            SvgIcon
        },
        props: {
            text: {
                type: String,
                default: ''
            },
            icon: {
                type: Object,
                default: () => {}
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            isLoading: {
                type: Boolean,
                deafult: false
            },
            shortcut: {
                type: Object,
                default: () => {
                    return {
                        text: '',
                        key: '',
                        enabled: false,
                        callback: null,
                        arguments: [],
                        shiftKey: false,
                        altKey: false,
                        ctrlKey: false,
                    }
                }
            }
        },
        data() {
            return {
                showButtonLoader: false,
                delay: null,
            }
        },
        mounted() {
            if (this.shortcut.enabled && this.shortcut.key) {
                window.addEventListener('keyup', this.shortcutPress);
            }
        },
        beforeUnmount() {
            if (this.shortcut.enabled && this.shortcut.key) {
                window.removeEventListener('keyup', this.shortcutPress);
            }
        },
        methods: {
            shortcutPress(event) {
                if (event.code === this.shortcut.key &&
                    event.shiftKey === this.shortcut.shiftKey &&
                    event.ctrlKey === this.shortcut.ctrlKey &&
                    event.altKey === this.shortcut.altKey
                ) {
                    this.shortcut.callback(...this.shortcut.arguments);
                }
            }
        },
        watch: {
            isLoading: {
                handler(newValue) {
                    if (this.delay) clearTimeout(this.delay);
                    if (newValue) {
                        const _this = this;
                        this.delay = setTimeout(function() {
                            _this.showButtonLoader = true;
                        }, 500)
                    } else {
                        this.showButtonLoader = false;
                    }
                },
                deep: false
            }
        },
    }
</script>

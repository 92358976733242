<template>
    <img :class="className" :src="iconPath" :style="`width: ${calculatedWidth}; height: ${calculatedHeight};`" />
</template>

<script>
    export default {
        name: 'svg-illustration',
        props: {
            name: {
                type: String,
                required: true
            },

            title: {
                type: String,
                default: null
            },

            width: {
                type: Number,
                default: null
            },

            height: {
                type: Number,
                default: null
            },
        },

        computed: {
            iconPath() {
                let icon = require(`@/assets/img/${this.name}.svg`);
                if (Object.prototype.hasOwnProperty.call(icon, 'default')) {
                    icon = icon.default;
                }

                return icon;
            },
            className() {
                return 'svg-illustration svg-illustration--' + this.name;
            },
            calculatedWidth() {
                return this.width ? `${this.width}rem` : 'auto';
            },
            calculatedHeight() {
                return this.height ? `${this.height}rem` : 'auto';
            }
        }
    }
</script>

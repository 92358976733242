<template>
    <div class="modal-container">
        <div class="modal-header">
            <h3 class="modal-title modal-title--big">Calendar sharing</h3>
        </div>
        <div class="modal-body">
            <div class="modal-group modal-group--no-gap">
                <svg-illustration class="modal-group__illustration" :height="19.5" :width="20" name="calendar-sharing" />
                <h4 class="modal-title modal-title--gradient modal-title--big modal-title--mb-20">Look at just one place. <br> This is how it simple</h4>
                <ul class="modal-info-list">
                    <li class="modal-info-list__item red">
                        <div class="modal-info-list__item-icon-wrapper">
                            <div class="modal-info-list__item-icon-circle">
                                <svg-icon class="modal-info-list__item-icon" :width="1.4" :height="1.4" name="plane" />
                            </div>
                        </div>
                        <h5 class="modal-info-list__item-title">Don’t reinvent the wheel</h5>
                        <p class="modal-info-list__item-text">Forget abound manual copying and keeping your schedule up to date</p>
                    </li>
                    <li class="modal-info-list__item blue">
                        <div class="modal-info-list__item-icon-wrapper">
                            <div class="modal-info-list__item-icon-circle">
                                <svg-icon class="modal-info-list__item-icon" :width="1.4" :height="1.4" name="calendar-color" />
                            </div>
                        </div>
                        <h5 class="modal-info-list__item-title">Works everywhere</h5>
                        <p class="modal-info-list__item-text">Apple, Google, Outlook and many more are supported!</p>
                    </li>
                    <li class="modal-info-list__item yellow">
                        <div class="modal-info-list__item-icon-wrapper">
                            <div class="modal-info-list__item-icon-circle">
                                <svg-icon class="modal-info-list__item-icon" :width="1.4" :height="1.4" name="gift" />
                            </div>
                        </div>
                        <h5 class="modal-info-list__item-title">Petroka Pro</h5>
                        <p class="modal-info-list__item-text">This, and many more features available via pro subscription. Includes 30-day free trial</p>
                    </li>
                </ul>
            </div>
            <div class="modal-group modal-group--no-gap" v-if="!shouldPromote">
                <div class="modal-group__header">
                    <h4 class="modal-title">Follow instructions</h4>
                    <select-base
                        @update:modelValue="setInstruction"
                        class="modal-select"
                        v-bind="selectOptions"
                    />
                </div>
                <div class="modal-group__container">
                    <p class="modal-group__description" v-if="instructions[currentInstruction].description">{{ instructions[currentInstruction].description }}</p>
                    <ol class="modal-group__list">
                        <li class="modal-group__list-item"
                            v-for="(item, id) in instructions[currentInstruction].list"
                            :key="id"
                        >
                            <p class="modal-group__list-text">{{ item.text }}</p>
                            <clipboard-base
                                v-if="item.type === 'link'"
                                :url="url"
                                :textUrl="url"
                                description="Don’t share this link publicly"
                            />
                        </li>
                    </ol>
                </div>
            </div>
        </div>
        <div class="modal-footer modal-footer--buttons">
            <button-base text="Close"
                         class="button-base--white"
                         :shortcut="{ text: 'Esc', enabled: true }"
                         @click="$emit('close')" />
            <button-base text="Let me try"
                         @click="sharingCalendar"
                         :shortcut="buttonLetMeTryShortcut"
                         v-if="shouldPromote" />
        </div>
    </div>
</template>

<script>
    import SvgIcon from '@/components/common/SvgIcon/SvgIcon';
    import SelectBase from '@/components/common/SelectBase/SelectBase';
    import ClipboardBase from '../ClipboardBase/ClipboardBase.vue';
    import ButtonBase from '@/components/common/ButtonBase/ButtonBase';
    import SvgIllustration from '../SVGIllustration/SvgIllustration.vue';
    import { mapMutations, mapState } from 'vuex';
    import { enableSharingCalendar } from '@/services/apiSchedule';
    import errorProcessing from '../../../services/errorProcessing';

    export default {
        name: 'ModalInfoSharing',
        components: {
            SvgIcon,
            SelectBase,
            ClipboardBase,
            ButtonBase,
            SvgIllustration
        },
        data() {
            return {
                selectOptions: {
                    options: [
                        {
                            label: 'Web',
                            value: 'web',
                        },
                        {
                            label: 'iPhone',
                            value: 'iphone',
                        },
                        {
                            label: 'macOS',
                            value: 'macos',
                        },
                    ],
                    label: 'label',
                    searchable: false,
                    prefix: 'For',
                    value: {
                        label: 'Web',
                        value: 'web',
                    }
                },
                instructions: {
                    macos: {
                        description: '',
                        list: [
                            {
                                text: 'Open default Settings app',
                                type: 'text'
                            },
                            {
                                text: 'Go to File -> New calendar subscriptionor ⌘⌥S',
                                type: 'text'
                            },
                            {
                                text: 'Paste following link.',
                                type: 'link'
                            },
                            {
                                text: 'Wait couple of seconds and configure parameters as you want. Gongratulations, you can now see your lessons inside application! We will synchronize all changes from Petroka',
                                type: 'text'
                            },
                        ]
                    },
                    web: {
                        description: '',
                        list: [
                            {
                                text: 'Open your calendar website',
                                type: 'text'
                            },
                            {
                                text: 'Tap Add another calendar. Subscribe to calendar',
                                type: 'text'
                            },
                            {
                                text: 'Paste following link.',
                                type: 'link'
                            },
                            {
                                text: 'Wait couple of seconds and configure parameters as you want. Gongratulations, you can now see your lessons inside application! We will synchronize all changes from Petroka',
                                type: 'text'
                            },
                        ]
                    },
                    iphone: {
                        description: '',
                        list: [
                            {
                                text: 'Open default Settings app',
                                type: 'text'
                            },
                            {
                                text: 'Go to Calendar -> Accounts -> Add account -> Other -> Add Subscribed Calendar',
                                type: 'text'
                            },
                            {
                                text: 'Paste following link.',
                                type: 'link'
                            },
                            {
                                text: 'Wait couple of seconds and configure parameters as you want. Gongratulations, you can now see your lessons inside application! We will synchronize all changes from Petroka',
                                type: 'text'
                            },
                        ]
                    }
                },
                currentInstruction: 'web',
                buttonLetMeTryShortcut: {
                    enabled: true,
                    key: 'Enter',
                    text: '⏎',
                    callback: this.sharingCalendar,
                    shiftKey: false,
                    altKey: false,
                    ctrlKey: false,
                    arguments: []
                },
            }
        },
        computed: {
            ...mapState('Students', ['shouldPromote', 'calendarId']),
            url() {
                return `https://cal.petroka.app/ics/${this.calendarId}`;
            }
        },
        methods: {
            ...mapMutations('Students', ['setShouldPromote', 'setCalendarId']),
            setInstruction(option) {
                this.currentInstruction = option.value;
                this.selectOptions.value = option;
            },
            async sharingCalendar() {
                try {
                    const response = await enableSharingCalendar();

                    this.$gtag.event('enable_sharing_calendar', {
                        'event_category': 'schedule',
                        'event_label': 'enable sharing calendar',
                    });
                    this.setShouldPromote(false);
                    this.setCalendarId(response.toObject().calendarid);
                } catch (error) {
                    errorProcessing(error)();
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .svg-icon {
        flex-shrink: 0;

        &--plane {
            fill: #EB5757;
        }

        &--calendar-color {
            fill: #5F71F6;
        }

        &--gift {
            fill: #EAC545;
        }
    }
</style>

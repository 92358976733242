<template>
    <div class="modal-container">
        <div class="modal-header">
            <h3 class="modal-title">{{ modalData.modalActionText }}</h3>
        </div>
        <div class="modal-body">
            <p class="modal-text">{{ modalData.modalWarningText }}</p>
        </div>
        <div class="modal-footer modal-footer--buttons">
            <button-base
                class="button-base--white"
                @click="$emit('close')"
                text="Cancel"
            />
            <button-base
                class="button-base--white button-base--warning"
                @click="alertAction"
                :text="modalData.modalActionButtonText"
            />
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import ButtonBase from '../ButtonBase/ButtonBase.vue';

    export default {
        name: 'ModalAlert',
        components: {
            ButtonBase,
        },
        computed: {
            ...mapState(['modalData']),
        },
        methods: {
            async alertAction() {
                await this.modalData.modalAlertCallback(this.modalData.callbackPayload);
                this.$gtag.event('delete_lesson', {
                    'event_category': 'lesson',
                    'event_label': 'delete lesson',
                });
                this.$emit('close');
            }
        }
    }
</script>

<template>
    <div class="modal-container">
        <div class="modal-header">
            <h3 class="modal-title modal-title--big">{{ modalData.modalActionText }} lesson</h3>
        </div>
        <div class="modal-body">
            <div class="modal-group" v-if="modalData.modalType.indexOf('Payment') === -1">
                <h4 class="modal-title">General</h4>
                <input-base
                    @update:modelValue="getFieldData"
                    class="modal__input"
                    :options="inputs[0]"
                    :disabled="true"
                    v-if="modalData.modalType === 'AddFromStudent' || modalData.modalType.indexOf('Edit') !== -1"
                />
                <select-base
                    class="modal-select"
                    @update:modelValue="getFieldData"
                    v-bind="{
                        ...inputs[0].selectOptions,
                        name: inputs[0].name,
                        error: inputs[0].error
                    }"
                    v-else
                />
                <input-base
                    @update:modelValue="getFieldData"
                    class="modal__input"
                    :options="inputs[1]"
                />
                <date-picker-base
                    @input="getFieldData"
                    :options="inputs[2].datePickerOptions"
                    :confirmText="inputs[2].confirmText"
                    :value="inputs[2].value"
                    class="modal-data-picker"
                    :name="inputs[2].name"
                    :error="inputs[2].error"
                />
                <date-picker-base
                    @input="getFieldData"
                    :options="inputs[3].datePickerOptions"
                    :confirmText="inputs[3].confirmText"
                    :disabled="!inputs[3].startDateValue"
                    :value="inputs[3].value"
                    :name="inputs[3].name"
                    class="modal-data-picker"
                    :error="inputs[3].error"
                />
                <select-base
                    @update:modelValue="getFieldData"
                    class="modal-select"
                    v-bind="{
                        ...inputs[4].selectOptions,
                        name: inputs[4].name
                    }"
                />
            </div>
            <div class="modal-group" v-if="modalData.modalType.indexOf('Lesson') === -1">
                <h4 class="modal-title">Payment</h4>
                <input-base
                    @update:modelValue="getFieldData"
                    class="modal__input"
                    :options="inputs[5]"
                />
                <select-base
                    @update:modelValue="getFieldData"
                    class="modal-select"
                    v-bind="{
                        ...inputs[6].selectOptions,
                        name: inputs[6].name
                    }"
                />
                <select-base
                    @update:modelValue="getFieldData"
                    class="modal-select"
                    v-bind="{
                        ...inputs[7].selectOptions,
                        name: inputs[7].name
                    }"
                />
            </div>
        </div>
        <div class="modal-footer modal-footer--buttons">
            <button-base
                class="button-base--white"
                @click="$emit('close')"
                :shortcut="{ text: 'Esc', enabled: true }"
                text="Cancel"
            />
            <button-base
                @click="lessonActions"
                :text="`${modalData.modalActionText} lesson`"
                :shortcut="buttonCreateShortcut"
                :disabled="isLoading"
                :isLoading="isLoading"
            />
        </div>
    </div>
</template>

<script>
    import InputBase from "@/components/common/InputBase/InputBase";
    import SelectBase from "@/components/common/SelectBase/SelectBase";
    import DatePickerBase from '../DatePickerBase/DatePickerBase.vue';
    import ButtonBase from '@/components/common/ButtonBase/ButtonBase';
    import { CreatePaymentInfo, Payment } from "@/services/proto/students_pb";
    import errorProcessing from "@/services/errorProcessing";
    import { getPaymentInfoKey, getDateFromTimestamp } from "@/services/common";
    import validation from '@/services/validation';
    import { mapMutations, mapState } from 'vuex';
    import { addLesson, editLesson, editPayment } from '@/services/apiStudents';
    import { v4 as uuidv4 } from 'uuid';

    export default {
        name: "ModalCreateLesson",
        components: {
            InputBase,
            SelectBase,
            DatePickerBase,
            ButtonBase,
        },
        inject: ['$dayjs'],
        data() {
            return {
                inputs: [
                    {
                        name: 'Student',
                        error: '',
                        value: '',
                        validations: ['required'],
                        fieldBdName: '',
                        selectOptions: {
                            options: [],
                            value: {},
                            searchable: true,
                            placeholder: 'Choose student...',
                            label: 'label'
                        },
                    },
                    {
                        name: 'Location',
                        placeholder: 'Trafalghar square, 24',
                        type: 'text',
                        reset: true,
                        error: '',
                        value: '',
                        validations: [],
                        fieldBdName: ['location'],
                    },
                    {
                        name: 'Start date',
                        error: '',
                        value: '',
                        validations: ['required', 'startDate'],
                        fieldBdName: ['startdate'],
                        associatedField: {
                            fieldName: 'End date',
                            valueName: 'startDateValue',
                            valueСonversion: (value) => {
                                return value;
                            }
                        },
                        confirmText: 'Confirm',
                        datePickerOptions: {
                            placeholder: 'DD.MM.YYYY HH:mm',
                            monthNameFormat: 'long',
                            format: 'dd.MM.yyyy HH:mm',
                        }
                    },
                    {
                        name: 'End date',
                        error: '',
                        value: '',
                        startDateValue: '',
                        confirm: true,
                        validations: ['required', 'endDate'],
                        fieldBdName: ['enddate'],
                        confirmText: 'Confirm',
                        datePickerOptions: {
                            placeholder: 'DD.MM.YYYY HH:mm',
                            monthNameFormat: 'long',
                            format: 'dd.MM.yyyy HH:mm',
                        }
                    },
                    {
                        name: 'Remind me before',
                        placeholder: '',
                        reset: true,
                        error: '',
                        value: '15',
                        validations: ['required'],
                        fieldBdName: ['reminderduration'],
                        selectOptions: {
                            options: [
                                {
                                    label: '5 min',
                                    value: '5',
                                },
                                {
                                    label: '10 min',
                                    value: '10',
                                },
                                {
                                    label: '15 min',
                                    value: '15',
                                },
                                {
                                    label: '30 min',
                                    value: '30',
                                },
                                {
                                    label: '1 hour',
                                    value: '60',
                                },
                            ],
                            value: {
                                label: '15 min',
                                value: '15',
                            },
                            label: 'label',
                            searchable: false,
                        },
                    },
                    {
                        name: 'Lesson cost',
                        placeholder: 'Please enter lesson cost',
                        type: 'text',
                        prefix: '£',
                        reset: true,
                        error: '',
                        value: '',
                        validations: ['required', 'cost'],
                        fieldBdName: ['payment', 'cost'],
                    },
                    {
                        name: 'Payment valute',
                        error: '',
                        value: 'UK_POUNDS',
                        validations: ['required'],
                        fieldBdName: ['payment', 'valute'],
                        associatedField: {
                            fieldName: 'Lesson cost',
                            valueName: 'prefix',
                            valueСonversion: (value) => {
                                const valutes = {
                                    'US_DOLLAR': '$',
                                    'EURO': '€',
                                    'UK_POUNDS': '£'
                                }

                                return valutes[value];
                            }
                        },
                        selectOptions: {
                            options: [
                                {
                                    label: 'US Dollar',
                                    value: 'US_DOLLAR',
                                },
                                {
                                    label: 'Euro',
                                    value: 'EURO',
                                },
                                {
                                    label: 'UK Pounds',
                                    value: 'UK_POUNDS',
                                },
                            ],
                            value: {
                                label: 'UK Pounds',
                                value: 'UK_POUNDS',
                            },
                            label: 'label',
                            searchable: false,
                        },
                    },
                    {
                        name: 'Payment notification preference',
                        nameVisibility: true,
                        value: 'MANUAL',
                        error: '',
                        validations: [],
                        fieldBdName: ['payment', 'notificaitonpreference'],
                        selectOptions: {
                            options: [
                                {
                                    label: 'Manual',
                                    value: 'MANUAL'
                                },
                                // {
                                //     label: 'Email',
                                //     value: 'EMAIL',
                                // },
                                // {
                                //     label: 'SMS',
                                //     value: 'SMS',
                                // },
                            ],
                            value: {
                                label: 'Manual',
                                value: 'MANUAL'
                            },
                            label: 'label',
                            searchable: false,
                        },
                    },
                ],
                buttonCreateShortcut: {
                    enabled: true,
                    key: 'Enter',
                    text: '⏎',
                    callback: this.lessonActions,
                    shiftKey: false,
                    altKey: false,
                    ctrlKey: false,
                    arguments: []
                },
                isLoading: false,
            }
        },
        mounted() {
            if (this.modalData?.modalType.indexOf('Edit') !== -1 || this.modalData?.modalType.indexOf('Student') !== -1) {
                const studentName = this.currentStudent
                    ? `${this.currentStudent?.base?.firstname || ''} ${this.currentStudent?.base?.lastname || ''}`
                    : `${this.currentLesson?.student?.firstname || ''} ${this.currentLesson?.student?.lastname || ''}`;

                this.inputs[0].type = 'text';
                this.inputs[0].disabled = true;
                this.inputs[0].value = studentName;
            } else {
                if (this.students.length !== 0) {
                    this.inputs[0].selectOptions.options = this.students.map(student => {
                        return {
                            label: `${student.firstname} ${student.lastname}`,
                            value: student.id,
                        }
                    })
                } else {
                    this.inputs[0].selectOptions.disabled = true;
                }
            }

            if (this.modalData?.modalType.indexOf('Edit') !== -1) {
                const dataName = 'currentLesson';

                for (let i = 1; i < this.inputs.length; i++) {
                    const field = this.inputs[i];
                    const fieldValue = this.getFieldDbValueByName(field.fieldBdName, dataName);

                    if (field.hasOwnProperty('selectOptions')) {
                        if (field.fieldBdName[0] === 'payment') {
                            const statusName = getPaymentInfoKey(field.fieldBdName[1], +fieldValue);
                            const selectValue = field.selectOptions.options.find(obj => obj.value === statusName);

                            field.selectOptions.value = { ...selectValue };
                            field.value = selectValue.value;
                        } else {
                            const selectValue = field.selectOptions.options.find(obj => +obj.value === fieldValue);
                            field.selectOptions.value = { ...selectValue };
                            field.value = selectValue.value;
                        }
                    } else if (field.hasOwnProperty('datePickerOptions')) {
                        const date = getDateFromTimestamp(fieldValue);
                        field.value = date;
                    } else {
                        field.value = field.fieldBdName.includes('cost')
                            ? Number(fieldValue) / 100
                            : fieldValue
                    }

                    if (field.associatedField) {
                        const associatedField = this.inputs.find(item => item.name === field.associatedField.fieldName);
                        associatedField[field.associatedField.valueName] = field.associatedField.valueСonversion(field.value);
                    }
                }
            }
        },
        computed: {
            ...mapState('Students', ['currentStudent', 'students', 'currentLesson']),
            ...mapState(['modalData']),
        },
        methods: {
            ...mapMutations('Students', ['addLessonToState', 'editLessonInState', 'editLessonPaymentInState', 'defaultMutation']),
            getFieldData(params) {
                const field = this.inputs.find(item => item.name === params.name);

                field.value = params.value;
                if (field.selectOptions) {
                    field.selectOptions.value.value = params.value;
                    field.selectOptions.value.label = params.label;
                }
                if (field.error) field.error = '';
                if (field.associatedField) {
                    const associatedField = this.inputs.find(item => item.name === field.associatedField.fieldName);
                    associatedField[field.associatedField.valueName] = field.associatedField.valueСonversion(field.value);
                }
            },
            getFieldDbValueByName(path, dataName) {
                const copyPath = JSON.parse(JSON.stringify(path));
                const data = JSON.parse(JSON.stringify(this[dataName]));
                let result = null;

                while (copyPath.length !== 0) {
                    result = result ? result[copyPath.shift()] : data[copyPath.shift()]
                }

                return result;
            },
            fieldsValidation() {
                let isValid = true;

                this.inputs.forEach(field => {
                    for (let i = 0; i < field.validations.length; i++) {
                        const validate = validation()[field.validations[i]](field);
                        if (typeof validate === 'string') {
                            field.error = validate;
                            isValid = false;
                            break;
                        }
                    }
                });

                return isValid;
            },
            async lessonActions() {
                if (this.fieldsValidation()) {
                    const uuid = uuidv4();
                    const location = this.inputs[1].value;
                    const startDate = this.inputs[2].value;
                    const endDate = this.inputs[3].value;
                    const remindDuration = this.inputs[4].value;
                    const cost = this.inputs[5].value;
                    const paymentValute = this.inputs[6].value;
                    const paymentNotification = this.inputs[7].value;
                    const paymentInfo = new CreatePaymentInfo();
                    const paymentNotificationTypes = Payment.NotificationPreference;
                    const paymentValutes = Payment.PaymentValute;
                    const type = this.modalData.modalType.indexOf('Student') !== -1 ? 'Students' : 'Schedule';
                    let studentId = 0;

                    if (this.modalData.modalType === 'AddFromSchedule') {
                        studentId = this.inputs[0].value;
                    } else {
                        studentId = this.currentStudent?.base.id || this.currentLesson.student.id;
                    }
                    paymentInfo.setCost(Number(cost) * 100);
                    paymentInfo.setValute(paymentValutes[paymentValute]);
                    paymentInfo.setNotificationpreference(paymentNotificationTypes[paymentNotification]);
                    this.isLoading = true;

                    try {
                        let response = null;

                        if (this.modalData.modalType.indexOf('EditLessonInfo') !== -1) {
                            const lessonId = this.currentLesson.id;

                            response = await editLesson(lessonId, location, startDate, endDate, remindDuration);
                            this.editLessonInState({ lesson: response.toObject(), type });

                        } else if (this.modalData.modalType.indexOf('EditPayment') !== -1) {
                            const paymentId = this.currentLesson.payment.id;

                            response = await editPayment(paymentId, Number(cost * 100), paymentValutes[paymentValute], paymentNotificationTypes[paymentNotification]);
                            this.editLessonPaymentInState({ payment: response.toObject(), type });
                        } else {
                            let result = null;

                            response = await addLesson(studentId, uuid, location, startDate, endDate, remindDuration, paymentInfo);
                            result = {
                                lesson: {
                                    ...response.toObject().lesson,
                                    payment: {
                                        ...response.toObject().payment
                                    }
                                },
                                type
                            }

                            if (this.modalData.modalType === 'AddFromSchedule') {
                                const student = this.students.find(obj => obj.id === studentId);
                                result.lesson.student = { ...student };
                            }

                            this.addLessonToState(result);
                        }

                        this.defaultMutation({
                            property: 'needUpdStudents',
                            value: true,
                        });
                        this.$gtag.event('create_lesson', {
                            'event_category': 'lessons',
                            'event_label': 'create new lesson',
                        });
                        this.$emit('close');
                    } catch (err) {
                        errorProcessing(err)();
                    } finally {
                        this.isLoading = false;
                    }
                }
            }
        }
    }
</script>

<template>
    <div class="page-inner">
        <header class="page-header" v-if="!$route.query.nativeApp">
            <div class="page-header__bar">
                <div class="page-header__bar-container default-container">
                    <router-link class="page-header__bar-logo" to="/">
                        <span>Petroka</span>
                        <beta-block />
                    </router-link>
                    <!-- <search-base class="page-search" placeholder="Search everything..." /> -->
                    <div class="page-header__bar-controls">
                        <!-- <button class="page-header__bar-control" to="/settings">
                            <svg-icon :width="2" :height="2" name="settings"></svg-icon>
                        </button> -->
                        <button class="page-header__bar-control page-header__bar-control--profile"
                                to="/profile"
                                @click="setShowTooltip"
                        >
                            <svg-icon :width="2" :height="2" name="profile"></svg-icon>
                            <tooltip-base class="tooltip-base--mt-10"
                                        :position="tooltipPosition"
                                        :show="showTooltip"
                                        parentSelector=".page-header__bar-control--profile"
                                        animationName="translate"
                                        @unShowedTooltip="showTooltip = false"
                            >
                                <div class="tooltip-base__container">
                                    <p class="tooltip-base__text">{{ user.email }}</p>
                                </div>
                                <div class="tooltip-base__container tooltip-base__container--plr-none">
                                    <button class="tooltip-base__button tooltip-base__button--text" @click="exit">Sign out</button>
                                </div>
                            </tooltip-base>
                        </button>
                    </div>
                </div>
            </div>
            <nav class="page-navigation" v-if="showNavigation">
                <div class="page-navigation__container default-container">
                    <div class="page-navigation__wrapper">
                        <ul class="page-navigation__list">
                            <li class="page-navigation__list-item">
                                <router-link class="page-navigation__list-action" to="/students">
                                    Students
                                </router-link>
                            </li>
                            <li class="page-navigation__list-item">
                                <router-link class="page-navigation__list-action" to="/schedule">
                                    Schedule
                                </router-link>
                            </li>
                            <li class="page-navigation__list-item">
                                <router-link class="page-navigation__list-action" to="/payment">
                                    Payments
                                </router-link>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </header>
        <main class="page-main"
              :class="{
                'default-container': isSetDefaultContainer,
                'page-main--courses': $route.name === 'Courses',
              }"
        >
            <router-view />
        </main>
        <modal v-slot="scope">
            <component :is="currentModal" @close="scope.close()"></component>
        </modal>
    </div>
</template>

<script>
    import Modal from "@/components/common/Modal/Modal";
    import ModalCreateLesson from "@/components/common/ModalCreateLesson/ModalCreateLesson";
    import ModalCreateStudent from "@/components/common/ModalCreateStudent/ModalCreateStudent";
    import ModalInfoSharing from "@/components/common/ModalInfoSharing/ModalInfoSharing";
    import ModalAlert from '../Modal/ModalAlert.vue';
    import SearchBase from "@/components/common/SearchBase/SearchBase";
    import SvgIcon from "@/components/common/SvgIcon/SvgIcon";
    import TooltipBase from '../TooltipBase/TooltipBase.vue';
    import { mapState } from "vuex";
    import { deleteCookie, getCookie } from "@/services/common";
    import { logout } from "@/services/apiAuth";
    import BetaBlock from '../BetaBlock/BetaBlock.vue';

    export default {
        name: "MainLayout",
        components: {
            SvgIcon,
            Modal,
            ModalCreateStudent,
            ModalCreateLesson,
            ModalInfoSharing,
            SearchBase,
            TooltipBase,
            BetaBlock,
            ModalAlert,
        },
        data() {
            return {
                tooltipPosition: {
                    bottom: 'unset',
                    left: 'unset',
                    top: 0,
                    right: 0
                },
                showTooltip: false,
            }
        },
        computed: {
            ...mapState(['currentModal', 'user']),
            isSetDefaultContainer() {
                return this.$route.name !== 'Courses'
            },
            showNavigation() {
                return this.$route.name !== 'Courses' || this.$route.name !== 'CookieSettings';
            }
        },
        methods: {
            setShowTooltip(event) {
                const button = event.target.closest('.page-header__bar-control');

                this.tooltipPosition.top = `${button.getBoundingClientRect().height / 10}rem`;
                this.showTooltip = true
            },
            async exit() {
                const session = getCookie('session');
                await logout(session);

                this.$store.dispatch('clearStore');
                deleteCookie('session');
                this.$gtag.event('account_exit', {
                    'event_category': 'authorization',
                    'event_label': 'user logged out',
                });
                await this.$router.push({ name: 'Login' });
            },
        }
    }
</script>

<template>
    <label class="date-picker" :class="{ 'date-picker--error': error }">
        <div class="date-picker__name">{{ name }}</div>
        <datepicker
            ref="picker"
            v-bind="options"
            v-model="date"
            position="left"
            :disabled="disabled"
        >
            <template #input>
                <input
                    type="text"
                    class="date-picker__input"
                    :value="displayDate"
                    :disabled="disabled"
                    @keypress.enter="getDateFromInput"
                    @blur="getDateFromInput"
                    :placeholder="options.placeholder"
                >
            </template>
            <template #clear-icon="{ clear }">
                <svg-icon class="date-picker__icon" :width="1.8" :height="1.4" name="delete" @click="clear" />
            </template>
            <template #calendar-icon>
                <svg-icon class="date-picker__icon" :width="1.4" :height="1.6" name="calendar" />
            </template>
            <template #action-select>
                <button-base @click="selectDate" :text="confirmText" />
            </template>
        </datepicker>
        <span class="date-picker__error" v-if="error">{{ error }}</span>
    </label>
</template>

<script>
    import Datepicker from '@vuepic/vue-datepicker';
    import '@vuepic/vue-datepicker/dist/main.css'
    import SvgIcon from '../SvgIcon/SvgIcon.vue';
    import InputBase from '../InputBase/InputBase.vue';
    import ButtonBase from '../ButtonBase/ButtonBase.vue';

    export default {
        name: 'DataPickerBase',
        inject: ['$dayjs'],
        components: {
            Datepicker,
            InputBase,
            SvgIcon,
                ButtonBase
        },
        props: {
            options: {
                type: Object,
                default: () => {}
            },
            confirmText: {
                type: String,
                default: 'Select'
            },
            name: {
                type: String,
                default: ''
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            value: {
                type: [Date, String],
                require: true,
            },
            error: {
                type: String,
                default: ''
            }
        },
        computed: {
            displayDate() {
                return this.value ? this.$dayjs(this.value).format(this.options.format) : '';
            },
            date: {
                get() {
                    return this.value;
                },
                set(date) {
                    this.$emit('input', {
                        name: this.name,
                        value: date,
                    });
                }
            }
        },
        methods: {
            altPosition() {
                return {
                    top: '100%',
                    left: '0',
                }
            },
            selectDate() {
                this.$refs.picker.selectDate();
            },
            getDateFromInput(event) {
                const dateTimeRegExp = new RegExp(/\d{2}.\d{2}.\d{4} \d{2}:\d{2}/, 'g');

                if (dateTimeRegExp.test(event.target.value)) {
                    const date = this.$dayjs(event.target.value, 'DD.MM.YYYY HH:mm').toDate();
                    this.modelValue = date;
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .date-picker {
        position: relative;

        &__name {
            @include body;
            margin-bottom: 1rem;
            color: $black;
        }

        &__error {
            position: absolute;
            top: calc(100% + 0.4rem);
            left: 0;
            @include caption-1;
            padding-left: 0.8rem;
            color: $destructive-light;
        }

        &__input {
            width: 100%;
            padding: .8rem 3rem .8rem 1rem;
            border: .1rem solid $shape-highlight-light;
            box-shadow: $box-shadow-controls-white;
            border-radius: $border-radius;
            @include body;
            color: $black;
            background-color: transparent;
            transition: box-shadow 0.2s ease-in-out;

            &:not(:disabled):hover {
                box-shadow: $box-shadow-controls-white-hover;
            }

            &:disabled {
                background-color: $shape-highlight-light;
                cursor: not-allowed;
            }
        }

        &--error:deep(.dp__input:not(:disabled)) {
            outline: 0.15rem solid $destructive-light;
            box-shadow: none;
        }
    }
</style>


<template>
    <div class="search">
        <svg-icon :width="2" :height="2" name="lens"></svg-icon>
        <input :placeholder="placeholder" type="text" class="search__input">
    </div>
</template>

<script>
    import SvgIcon from "@/components/common/SvgIcon/SvgIcon";
    export default {
        name: "SearchBase",
        props: {
            placeholder: {
                type: String,
                default: 'Search'
            }
        },
        components: {
            SvgIcon
        }
    }
</script>
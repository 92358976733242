<template>
    <div class="modal-container">
        <div class="modal-header">
            <h3 class="modal-title modal-title--big">{{ modalData.modalType }} student</h3>
        </div>
        <div class="modal-body">
            <div class="modal-group">
                <h4 class="modal-title">General</h4>
                <input-avatar @upload="getFieldData" class="modal-group__avatar" :options="inputs[0]" />
                <input-base @update:modelValue="getFieldData" class="modal__input" :options="inputs[1]" />
                <input-base @update:modelValue="getFieldData" class="modal__input" :options="inputs[2]" />
            </div>
            <div class="modal-group">
                <h4 class="modal-title">Contact info</h4>
                <input-phone-base @update:modelValue="getFieldData" :params="inputs[7]" />
                <input-base @update:modelValue="getFieldData" class="modal__input" :options="inputs[3]" />
                <input-base @update:modelValue="getFieldData" class="modal__input" :options="inputs[4]" />
                <input-base @update:modelValue="getFieldData" class="modal__input" :options="inputs[5]" />
                <input-base @update:modelValue="getFieldData" class="modal__input" :options="inputs[6]" />
            </div>
        </div>
        <div class="modal-footer modal-footer--buttons">
            <button-base
                class="button-base--white"
                @click="$emit('close')"
                :shortcut="{ text: 'Esc', enabled: true }"
                text="Cancel"
            />
            <button-base
                @click="studentActions"
                :disabled="isLoading"
                :text="`${modalData.modalType} student`"
                :shortcut="buttonCreateShortcut"
                :isLoading="isLoading"
            />
        </div>
    </div>
</template>

<script>
    import InputAvatar from "@/components/common/InputAvatar/InputAvatar";
    import InputBase from "@/components/common/InputBase/InputBase";
    import InputPhoneBase from "@/components/common/InputPhoneBase/InputPhoneBase";
    import ButtonBase from '@/components/common/ButtonBase/ButtonBase';
    import errorProcessing from "@/services/errorProcessing";
    import validation from '@/services/validation';
    import { addStudent, editStudent } from "@/services/apiStudents";
    import { ContactInfo } from "@/services/proto/students_pb";
    import { v4 as uuidv4 } from 'uuid';
    import { mapMutations, mapState } from 'vuex';

    export default {
        name: "ModalCreateStudent",
        components: {
            InputPhoneBase,
            InputBase,
            InputAvatar,
            ButtonBase
        },
        data() {
            return {
                inputs: [
                    {
                        name: 'Avatar',
                        error: '',
                        value: '',
                        validations: [],
                        fieldBdName: 'photourl',
                    },
                    {
                        name: 'First name',
                        placeholder: 'Please enter student name',
                        type: 'text',
                        reset: true,
                        error: '',
                        value: '',
                        validations: ['required'],
                        fieldBdName: 'firstname',
                    },
                    {
                        name: 'Last name',
                        placeholder: 'Please enter student last name',
                        type: 'text',
                        reset: true,
                        error: '',
                        value: '',
                        validations: [],
                        fieldBdName: 'lastname',
                    },
                    {
                        name: 'Email',
                        placeholder: 'Please enter student email',
                        type: 'text',
                        reset: true,
                        error: '',
                        value: '',
                        validations: ['email'],
                        fieldBdName: 2,
                    },
                    {
                        name: 'Instagram',
                        placeholder: 'Please enter student instagram',
                        type: 'text',
                        prefix: '@',
                        reset: true,
                        error: '',
                        value: '',
                        validations: [],
                        fieldBdName: 3,
                    },
                    {
                        name: 'Facebook',
                        placeholder: 'Please enter student facebook',
                        type: 'text',
                        prefix: '@',
                        reset: true,
                        error: '',
                        value: '',
                        validations: [],
                        fieldBdName: 4,
                    },
                    {
                        name: 'Telegram',
                        placeholder: 'Please enter student telegram',
                        prefix: '@',
                        type: 'text',
                        reset: true,
                        error: '',
                        value: '',
                        validations: [],
                        fieldBdName: 5,
                    },
                    {
                        name: 'Phone',
                        nameVisibility: true,
                        value: '',
                        error: '',
                        validations: ['phone'],
                        fieldBdName: 1,
                    },
                ],
                buttonCreateShortcut: {
                    enabled: true,
                    key: 'Enter',
                    text: '⏎',
                    callback: this.studentActions,
                    shiftKey: false,
                    altKey: false,
                    ctrlKey: false,
                    arguments: []
                },
                isLoading: false,
            }
        },
        mounted() {
            if (this.modalData?.modalType === 'Edit') {
                const contactList = this.inputs.slice(3);
                const baseFields = this.inputs.slice(0, 3);

                baseFields.forEach(field => {
                    field.value = this.currentStudent.base[field.fieldBdName];
                });

                contactList.forEach(field => {
                    const contactField = this.currentStudent.contactlistList.find(obj => obj.contacttype === field.fieldBdName);
                    if (contactField) field.value = contactField.value;
                });
                this.inputs = [...baseFields, ...contactList];
            }
        },
        computed: {
            ...mapState(['modalData']),
            ...mapState('Students', ['currentStudent']),
        },
        methods: {
            ...mapMutations('Students', ['addStudentToState', 'setCurrentStudent', 'editStudentInState']),
            getFieldData(params) {
                const field = this.inputs.find(item => item.name === params.name);

                field.value = params.value;
                if (field.selectOptions) {
                    field.selectOptions.value.value = params.value;
                    field.selectOptions.value.label = params.label;
                }
                if (field.error) field.error = '';
            },
            fieldsValidation() {
                let isValid = true;

                this.inputs.forEach(field => {
                    for (let i = 0; i < field.validations.length; i++) {
                        const validate = validation()[field.validations[i]](field);
                        if (typeof validate === 'string') {
                            field.error = validate;
                            isValid = false;
                            break;
                        }
                    }
                });

                return isValid;
            },
            async studentActions() {
                if (this.fieldsValidation()) {
                    const firstName = this.inputs[1].value;
                    const lastName = this.inputs[2].value;
                    const avatar = this.inputs[0].value;
                    const uuid = uuidv4();
                    const contacts = [];

                    this.inputs.slice(3).forEach(input => {
                        const contact = new ContactInfo();
                        contact.setContacttype(ContactInfo.ContactType[input.name.toUpperCase()]);
                        contact.setValue(input.value);
                        contacts.push(contact);
                    });
                    this.isLoading = true;

                    try {
                        let response = null;

                        if (this.modalData?.modalType === 'Add') {
                            response = await addStudent(uuid, firstName, lastName, avatar, contacts);
                            this.addStudentToState({
                                ...response.toObject().profile.base,
                                lesson: {
                                    startDate: null,
                                    endDate: null,
                                },
                                paymentStatus: null
                            });
                        } else {
                            response = await editStudent(this.currentStudent.base.id, firstName, lastName, avatar, contacts);
                            this.setCurrentStudent(response.toObject());
                            this.editStudentInState(response.toObject().base);
                        }

                        this.$gtag.event('create_student', {
                            'event_category': 'students',
                            'event_label': 'create new student',
                        });
                        this.$emit('close');
                    } catch (err) {
                        console.log(err);
                        errorProcessing(err)();
                    } finally {
                        this.isLoading = false;
                    }
                }

            }
        }
    }
</script>

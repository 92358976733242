import { SchedulePromiseClient } from "./proto/students_grpc_web_pb";
import { CalendarPromiseClient } from "./proto/calendar_grpc_web_pb";
import { GetScheduleRequest } from './proto/students_pb';
import { getTimestampDate, getCookie } from "./common";
import GoogleEmpty from 'google-protobuf/google/protobuf/empty_pb.js';

const emptyProtobuf = new GoogleEmpty.Empty;
const scheduleInstancePromise = new SchedulePromiseClient(process.env.API_URL, null, null);
const calendarInstancePromise = new CalendarPromiseClient(process.env.API_URL, null, null);

const getSchedule = function (startDate, endDate) {
    const credentials = { session: getCookie('session') };
    const request = new GetScheduleRequest();

    request.setStartdate(getTimestampDate(startDate));
    request.setEnddate(getTimestampDate(endDate));

    return scheduleInstancePromise.getSchedule(request, credentials);
}

const getCalendarInfo = function () {
    const credentials = { session: getCookie('session') };
    return calendarInstancePromise.getCalendarInfo(emptyProtobuf, credentials);
}

const enableSharingCalendar = function () {
    const credentials = { session: getCookie('session') };
    return calendarInstancePromise.enableCalendar(emptyProtobuf, credentials);
}

export { getSchedule, getCalendarInfo, enableSharingCalendar };

<template>
    <notifications>
        <template v-slot:body="props">
            <div class="notification">
                <div class="notification__body">
                    <div class="title">
                        {{ props.item.title }}
                    </div>
                    <div class="text" v-html="props.item.text"></div>
                    <button class="close" @click="props.close">
                        <svg-icon name="delete-auth" :width="1.7" :height="1.7"></svg-icon>
                    </button>
                </div>
            </div>
        </template>
    </notifications>
</template>

<script>
    import SvgIcon from "@/components/common/SvgIcon/SvgIcon";

    export default {
        name: "Notification",
        components: {
            SvgIcon
        }
    }
</script>

<style lang="scss">
    @import "notification.scss";
</style>

<template>
    <div class="clipboard-base">
        <div class="clipnboard-base__content">
            <p class="clipboard-base__link">{{ textUrl }}</p>
            <p class="clipboard-base__descripton" v-if="description">{{ description }}</p>
        </div>
        <button-base
            class="clipboard-base__button button-base--white"
            :icon="icon"
            @click="handleCopy"
            :disabled="showTooltip"
            ref="copyButton"
        >
            <template #tooltip>
                <tooltip-base
                    :position="tooltipPosition"
                    :show="showTooltip"
                    parentSelector=".clipboard-base__button"
                    :timer="3000"
                    @unShowedTooltip="closeTooltip"
                    class="tooltip-base--mb-10"
                >
                    <div class="tooltip-base__container">
                        <p class="tooltip-base__text">Copied</p>
                    </div>
                </tooltip-base>
            </template>
        </button-base>
    </div>
</template>

<script>
    import SvgIcon from '../SvgIcon/SvgIcon';
    import ButtonBase from '../ButtonBase/ButtonBase';
    import TooltipBase from '../TooltipBase/TooltipBase.vue';
    import Clipboard from '../../../mixins/clipboard.js';

    export default {
        name: 'ClipboardBase',
        mixins: [Clipboard],
        data() {
            return {
                tooltipPosition: {
                    bottom: '100%',
                    left: 'unset',
                    top: 'unset',
                    right: 0
                }
            }
        },
        components: {
            SvgIcon,
            ButtonBase,
            TooltipBase
        },
    }
</script>

<style lang="scss" scoped>
    .clipboard-base {
        width: 100%;
        display: flex;
        padding: 1.2rem;
        align-items: center;
        justify-content: space-between;
        background-color: $secondary-content-background-light;
        border-radius: $border-radius;
        gap: 0 1.2rem;

        &__link {
            max-width: 34rem;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            margin: 0 0 .8rem;
            color: $black;
            font-weight: 400;
            font-size: 1.4rem;
            line-height: 1.6rem;
        }

        &__descripton {
            font-size: 1.2rem;
            line-height: 1.4rem;
            letter-spacing: 0.12px;
            margin: 0;
            color: $secondary-content;
        }

        &__button {
            position: relative;
        }

        &__button svg {
            fill: $secondary-content;
        }

        &--lesson-invoice {
            background-color: transparent;
            padding: 0;
            border-radius: unset;
        }

        &--lesson-invoice &__link {
            margin-bottom: 0;
        }

        &--lesson-invoice &__button {
            box-shadow: unset;
            border: none;
            padding: 0;
        }
    }
</style>

<template>
    <transition :name="animationName" appear>
        <div
            class="tooltip-base"
            :style="{
                top: position.top,
                bottom: position.bottom,
                left: position.left,
                right: position.right,
            }"
            v-show="show"
        >
            <slot></slot>
        </div>
    </transition>
</template>

<script>
    export default {
        name: 'TooltipBase',
        props: {
            position: {
                type: Object,
                default: () => {}
            },
            timer: {
                type: Number,
                default: 0
            },
            show: {
                type: Boolean,
                default: false
            },
            parentSelector: {
                type: String,
                default: ''
            },
            animationName: {
                type: String,
                default: 'fade'
            },
            event: {
                type: String,
                default: 'click'
            }
        },
        beforeUnmount() {
            if (this.event) document.removeEventListener(this.event, this.closeTooltip);
        },
        methods: {
            closeTooltip(event) {
                const parent = event.target.closest(this.parentSelector);
                if (!parent) this.$emit('unShowedTooltip');
            }
        },
        watch: {
            show: {
                handler() {
                    if (this.timer) {
                        const _this = this;
                        setTimeout(() => {
                            _this.$emit('unShowedTooltip');
                        }, this.timer);
                    }
                    if (this.event) document.addEventListener(this.event, this.closeTooltip);
                },
                deep: false,
            }
        }
    }
</script>

<style lang="scss">
    .tooltip-base {
        position: absolute;
        border-radius: $border-radius;
        box-shadow: $box-shadow-dropdown;
        background-color: $white;
        cursor: initial;

        &--mt-10 {
            margin-top: 1rem;
        }

        &--mb-10 {
            margin-bottom: 1rem;
        }

        &__container {
            width: 100%;
            padding: .8rem 1.2rem;

            &--plr-none {
                padding-right: 0;
                padding-left: 0;
            }

            &:not(:last-child) {
                border-bottom: .1rem solid $shape-highlight-light;
            }
        }

        &__text {
            font-weight: 400;
            font-size: 1.4rem;
            line-height: 1.6rem;
            margin: 0;
            text-align: left;
            color: $secondary-content;
        }

        &__button {
            display: inline-block;
            width: 100%;
            padding: .4rem 1rem;
            cursor: pointer;

            &--text {
                border: none;
                background-color: transparent;
                color: $primary-accent-light;
                font-weight: 500;
                font-size: 1.5rem;
                line-height: 1.8rem;
                letter-spacing: 0.5px;
                text-align: left;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    .fade-leave-active,
    .fade-enter-active {
        transition: all 0.3s ease-out;
    }

    .fade-enter-from,
    .fade-leave-to {
        opacity: 0;
        transform: translateY(2rem);
    }

    .translate-enter-active,
    .translate-leave-active {
        transition: all .3s;
        transition-timing-function: cubic-bezier(.53,2,.36,.85);
    }

    .translate-enter-from,
    .translate-leave-to {
        opacity: 0;
    }

    .translate-enter-from {
        transform: translateY(-1rem);
    }

    .translate-leave-to {
        transform: translateY(1rem);
    }
</style>
